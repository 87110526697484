<!-- <template>
  <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <div class="card mt-5">

      <genericData
        ref="genericData1"
        :title="'COMPANY_ID'"
        :fields="fieldscompany"
        :initialValues="getInitialValuescompany"
        @submit="handleSubmit"
      />
      <hr />
      <genericData
        ref="genericData2"
        :title="'Généralité'"
        :fields="fields"
        :initialValues="getInitialValues"
        @submit="handleSubmit"
      />
      <hr />
      <genericData
       ref="genericData3"
        :title="'information'"
        :fields="fieldsfact"
        :initialValues="getInitialValuesfactur"
        @submit="handleSubmit"
      />
      <hr />
      <genericData
        ref="genericData4"
        :title="''"
        :fields="fieldsclient"
        :initialValues="getInitialValuesclient"
        @submit="handleSubmit"
      />
      <genericData
        ref="genericData5"
        :title="''"
        :fields="fieldsuser"
        :initialValues="getInitialValuesuser"
        @submit="handleSubmit"
      />
      <hr />
      <TablData
    />
<hr />
      <div class="mx-5">
        <b>{{ $t("REMARQUE") }}</b
        >/<b>{{ $t("COND_REG") }}</b>
      </div>
      <vue-editor
        :placeholder="$t('AJUTER_DESCRIPTION')"
        class="mx-5 mt-3 vue-editor"
        :editor-toolbar="customToolbar"
      />
      <div class="d-flex justify-content-end m-5 submit">
        <b-button @click="submitAllForms" 
          variant="success"
          class="d-flex justify-content-center align-items-center"
        >
          {{ $t("GENERATE") }} {{ $t("Facture") }}
       
        </b-button>
      </div>
    </div>
   
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import genericData from "@/components/facture/genericData.vue";
import TablData from "../../components/facture/tablData.vue";
export default {
  components: {
    genericData,
    TablData
  },
  data() {
    return {
      customToolbar: [
        ["bold", "italic", "underline", "strike"],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ font: [] }],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }],
        [{ indent: "-1" }, { indent: "+1" }],
        ["blockquote", "code-block"],
        ["link", "image", "video"],
        ["clean"],
      ],
      state: {
        dateVisite: "",
        dateStart: "",
        dateEnd: "2025-01-15",
        selectedEntreprise: null,
        selectedClient: null,
        selectedUser: null,
        selectedOptions: null,
        selectedOptionsFact: null,
        valeurTotal: null,
        datetraveauEnd: "",
        datetraveauStart: "",
        selectedOptionsAssurance: null,
        selectedOptionsPaiement: null,
      },
      fields: [
        {
          key: "dateVisite",
          label: "DATE_VISITE_TECHNIQUE",
          type: "date",
          required: true,
        },
        {
          key: "dateStart",
          label: "DATE_START_DEVIS",
          type: "date",
          required: true,
        },
        {
          key: "dateEnd",
          label: "DATE_END_DEVIS",
          type: "date",
          required: true,
        },
        {
          key: "datetraveauStart",
          label: "Debut travaux'",
          type: "date",
          required: true,
        },
        {
          key: "datetraveauEnd",
          label: "Fin travaux'",
          type: "date",
          required: true,
        },
    
      ],
      fieldsclient: [
        {
          key: "selectedClient",
          label: "CLIENT",
          type: "multiSelect",
          required: false,
          multiple: false,
          getStat: this.getStatClient,
        },
      ],
      fieldsuser: [
        {
          key: "selecteduser",
          label: "USER",
          type: "multiSelect",
          required: false,
          multiple: false,
          getStat: this.getStatUser,
        },
      ],
      fieldscompany: [
        {
          key: "selectedEntreprise",
          label: "COMPANY_ID",
          type: "multiSelect",
          required: false,
          multiple: false,
          getStat: this.getAllentreprises,
        },
      ],
      fieldsfact: [
        {
          key: "selectedOptionsFact",
          label: "Facture",
          type: "multiSelect",
          required: false,
          multiple: false,
          getStat: this.getStatFact,
        },
        {
          key: "valeurTotal",
          label: "valeurTotal",
          type: "number",
          required: false,
        },
        {
          key: "selectedOptionsAssurance",
          label: "ASSURANCE",
          type: "multiSelect",
          required: false,
          multiple: false,
          getStat: this.getStatAssurance,
        },
        {
          key: "selectedOptionsPaiement",
          label: "MOD_PAYMENT",
          type: "multiSelect",
          required: false,
          multiple: true,
          getStat: this.getStatPaiement,
        },
      ],
    };
  },
  created() {
  this.intisial();
    this.all_clients({
      page: 1,
      per_page: 1000000,
    });
    this.all_users({
      page: 1,
      per_page: 1000000,
    });
  },
  computed: {
    ...mapGetters([
      "getTabs",
      "getfactureMonoFiche",
      "getAllentreprises",
      "getAllclients",
      "getAllUsers",
    ]),
  },
  methods: {
    ...mapActions([
      "entreprise",
      "all_entreprises",
 lForms() {
      this.$refs.genericData1.submitForm();
       this.$refs.genericData2.submitForm();
       this.$refs.genericData3.submitForm();
       this.$refs.genericData4.submitForm();
      this.$refs.genericData5.submitForm();
    },
    getInitialValues() {
  const today = new Date().toISOString().split("T")[0];
  const defaultValues = {
    dateVisite: today,
    dateStart: today,
    dateEnd: today,
    selectedOptions: [],
  };

  return { ...defaultValues, ...this.getfactureMonoFiche };
}
,

    getInitialValuescompany() {
    
      return { ...this.getfactureMonoFiche,selectedEntreprise:[] };

    },
    getInitialValuesuser() {
      return {
        ...this.getfactureMonoFiche, selecteduser:[],
      };
    },
    getInitialValuesclient() {
      return {...this.getfactureMonoFiche,
        selectedClient:[],
      };
    },

    getInitialValuesfactur() {
      const factur= {
        valeurTotal: this.getfactureMonoFiche.valeurTotal || 0,
        selectedOptionsFact: this.getfactureMonoFiche.selectedOptionsFact || [],
        selectedOptionsAssurance: this.getfactureMonoFiche.selectedOptionsAssurance || [],
        selectedOptionsPaiement: this.getfactureMonoFiche.selectedOptionsPaiement || [],
      };
      return { ...factur, ...this.getfactureMonoFiche };
  
    },
    getStat() {
      return [
        { value: "option1", text: "Option 1" },
        { value: "option2", text: "Option 2" },
        { value: "option3", text: "Option 3" },
        { value: "option4", text: "Option 4" },
      ];
    },

    getStatFact() {
      return [
        { value: "option1", text: "Option 1" },
        { value: "option2", text: "Option 2" },
      ];
    },
    getStatAssurance() {
      return [
        { value: "option1", text: "Option 1" },
        { value: "option2", text: "Option 2" },
      ];
    },
    getStatUser() {
    return this.getAllUsers.map(user => ({
      value: user.id,  
      text: user.nom  
    }));
  },
    getStatClient() {
    return this.getAllclients.map(client => ({
      value: client.id,  
      text: client.nom  
    }));
  },
    getStatPaiement() {
      return [
        {
          value: "Organisme_de_financement",
          text: this.$t("Organisme_de_financement"),
        },
        {
          value: "cheque_virement_espece",
          text: this.$t("Cheque_virement_espece"),
        },
        {
          value: "Certificat_Economie_energie",
          text: this.$t("Certificat_Economie_energie"),
        },
        {
          value: "Ma_prime_renove",
          text: this.$t("Ma_prime_renove"),
        },
        {
          value: "Autre",
          text: this.$t("Autre"),
        },
      ];
    },
 
  },
};
</script> -->















<template>
   <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <div class="card mt-5">
        {{ getAllentreprises }}
        <div class="row my-2">
            <div class="col-lg-6 col-md-6 col-sm-12">
              <b-form-group :label="$t('COMPANY_ID')">
                <multiselect
                  :searchable="true"
                  :close-on-select="true"
                  :multiple="false"
                  :options="getAllentreprises"
                  label="lib"
                  track-by="id"
                  v-model="selectedEntreprise"
                >
                  <template slot="noResult">
                    {{ $t("NO_DATA_FOUND") }}
                  </template>
                </multiselect>
              </b-form-group>
            </div>
          </div>

      <genericData @update-input="updateInput1"></genericData>
    <clientData @update-input="updateInput3"></clientData>
      <TablData @update-input="updateInput2"></TablData>
   
           
      <div>
              <b>{{ $t("REMARQUE") }}</b
              >/<b>{{ $t("COND_REG") }}</b>
            </div>
      <vue-editor
                   
                    v-model="description"
                    :placeholder="$t('AJUTER_DESCRIPTION')"
                    class="mt-4 vue-editor"
                    :editor-toolbar="customToolbar"
                  />
      <ul>
        {{ selectedUser }}
        {{ selectedMoyensPaiement }}
        {{ description }}
        {{ selectedClient }}
        {{ selectedEntreprise }}
        <li v-for="(item, index) in inputs" :key="index">{{ item }}</li>
      </ul>
    </div>
    </div>
  </template>
  

  
  <script>
import genericData from "@/components/facture/genericData.vue";
import TablData from "../../components/facture/tablData.vue";
import { mapGetters, mapActions } from "vuex";
import clientData from "../../components/facture/clientData.vue";

  export default {
    components: {
        genericData,
        TablData,
        clientData
    },
    data() {
      return {
    
        description:"",
        customToolbar: [
        ["bold", "italic", "underline", "strike"], 
        [{ header: [1, 2, 3, 4, 5, 6, false] }], 
        [{ font: [] }], 
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }],
        [{ indent: "-1" }, { indent: "+1" }],
        ["blockquote", "code-block"],
        ["link", "image", "video"],
        ["clean"],
      ],
        selectedClient:null,
        selectedEntreprise:null,
        inputs: [],
        timeout: null // Variable pour gérer le debounce
      };
    },
  
    computed: {
    ...mapGetters([
      "getTabs",
      "getfactureMonoFiche",
      "getAllentreprises",
      "getAllclients",
      "getAllUsers",
    ]),
  },
    methods: {
        ...mapActions([
      "entreprise",
      "all_entreprises",
      "all_clients",
      "all_users",
      "setFactureData",
      "setFactureMonoFiche"
    ]),
 
      updateInput1(value) {
        this.debounceUpdate(value);
      },
      updateInput3(value) {
        this.debounceUpdate(value);
      },
      updateInput2(value) {
        this.debounceUpdate(value);
      },
     
      debounceUpdate(value) {
        clearTimeout(this.timeout); 
        this.timeout = setTimeout(() => {
          if (value && !this.inputs.includes(value)) {
            this.inputs.push(value); 
          }
        }, 1000);
      }
    }
  };
  </script>
  
  
  
  
  
<style scoped>
.card {
  border-radius: 5px;
  margin: 0 15px;
  background-color: #fff;
  box-shadow: 1px 1px 24px #00000019;
}
.inner-container .content {
  margin: 0 15px;
  background-color: #fff;
  box-shadow: 1px 1px 24px #00000019;
  border-radius: 5px;
  padding: 14px;
  min-height: 81vh;
}
</style>
