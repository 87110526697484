<template>
    <div class="inner-container " :class="{ 'min-container': !getTabs }">
  <div class="content mt-5">
    <div class="">
     
        <img src="@/assets/soon.jpg" class=" img_soon" />
    <h1> <b class="colorTEXT">Bientôt disponible</b></h1>
    <h6>Cette section est actuellement en cours de développement et sera bientôt disponible.</h6>
    <h6>Nous travaillons activement pour vous offrir une expérience enrichie avec des fonctionnalités innovantes.</h6>
    <h6 class="mb-5"> Restez à l'écoute pour découvrir cette nouvelle partie, qui ajoutera encore plus de valeur à votre utilisation.</h6>
    <small class="d-flex justify-content-center colorTEXT">TEAM BATIGO vous remercie !</small>
</div>
</div>
</div>
</template>
<script>
    import { mapGetters } from "vuex";
    export default{

        computed: {
    ...mapGetters([

      "getTabs"])}
    }
</script>
<style lang="scss" scoped>
    .inner-container {
      .page-header button {
        margin-bottom: 0 !important;
        color: #fff;
        width: 0% !important;
        height: 43px;
      }
    
      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center; /* Centrer le texte également */
        height: 100vh; /* Assurez-vous que l'intérieur utilise toute la hauteur de la fenêtre */
      }
    
      .img_soon {
        width: 30%; /* Ajuste la largeur à 70% */
        height: auto; /* Maintient les proportions de l'image */
      }
      .colorTEXT{
        color: #28367A;
      }
    }
    </style>    
    