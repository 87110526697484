<template>
  <div>
    <div>
      <div class="row my-4 mx-1 justify-content-center">
        <v-container v-if="progress" type="image" class="loding">
          <v-skeleton-loader type="image"></v-skeleton-loader>
        </v-container>
        <div v-else class="col-lg-8 col-sm-12 col-md-10 borderPointier">
          <div class="background-white">
            <div class="title">
              <div class="position">{{ $t("conditions_technique") }}</div>
            </div>
          </div>
          <form @submit.prevent="raccordement_reseau_chaleurAdd" class="my-3">
            <b-form-group label="Raccordé au réseau de chaleur">
              <b-form-radio-group v-model="raccordement_reseau_chaleurData.deja_raccorder" :options="raccordeOptions" name="raccorde" />
            </b-form-group>
        <div v-if="raccordement_reseau_chaleurData.deja_raccorder===true" class="mx-3">
            <b-form-group label="Raccordement ">
              <b-form-radio-group v-model="raccordement_reseau_chaleurData.existe_cetificat_economie" :options="certificatOptions" name="certificat" />
            </b-form-group>
          </div>
       
        
        
            <b-form-group v-if="getdevisMonoFiche?.data?.devis?.logement?.type==='Maison individuelle'" label="Surface habitable (m²)">
              <b-form-input v-model="raccordement_reseau_chaleurData.surface_habitable"
              @paste="(event) => onPaste(event, 'raccordement_reseau_chaleurData.surface_habitable')"
              @keydown="(event) => onKeyDownFloat(event)"
              min="0"
               type="number" placeholder="Surface en m²" />
            </b-form-group>
            <b-form-group v-if="getdevisMonoFiche?.data?.devis?.logement?.type==='Collectif'" label="nbr_appartements">
              <b-form-input v-model="raccordement_reseau_chaleurData.nbr_appartements"
              @paste="(event) => onPaste(event, 'raccordement_reseau_chaleurData.nbr_appartements')"
                        @keydown="(event) => onKeyDown(event)"
                        min="0"
                         type="number" placeholder="nbr_appartements" />
            </b-form-group>
            <b-form-group v-if="getdevisMonoFiche?.data?.devis?.logement?.type==='Appartement'" label="nbr_appartements">
              <b-form-input v-model="raccordement_reseau_chaleurData.nbr_appartements"
                @paste="(event) => onPaste(event, 'raccordement_reseau_chaleurData.nbr_appartements')"
                        @keydown="(event) => onKeyDown(event)"
                        min="0"
              type="number" placeholder="nbr_appartements" />
            </b-form-group>
            <b-form-group label="Nom du réseau">
              <b-form-input v-model="raccordement_reseau_chaleurData.nom_reseau" type="text" placeholder="Nom du réseau" />
            </b-form-group>
          
          </form>
          <div v-if="show" class="d-flex justify-content-end mt-4">
            <b-button v-if="this.visibleTabsLength=1" @click="goToPrevStep" class="button-pre-style m-3">
        {{ $t("PREVIOUS") }}
      </b-button>
      <b-button
        type="submit"
        @click="goToStep"
        class="button-suivant-style m-3"
      >
        <template v-if="isLoading">
          <div class="loading ml-2">
            <div class="spinner-border spinner-border-sm" role="status"></div>
          </div>
        </template>
    
        <template >
          {{ buttonText }}
        </template>
      </b-button>
    </div>
        </div>
      </div>
    
    </div>
  
    <div v-if="!show" class="d-flex justify-content-end mt-4">
      <b-button @click="goToPrevStep" class="button-pre-style m-3">
        {{$t("PREVIOUS")}}
      </b-button>
      <b-button
        type="submit"
        @click="goToNextStep"
        class="button-suivant-style m-3"
      >
      {{$t("NEXT")}}
      <div class="loading ml-2" v-if="isLoading">
        <div class="spinner-border spinner-border-sm" role="status"></div>
      </div>
        <div class="loading ml-2" v-if="isLoadingButton">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
      </b-button>
    </div>
    <Snackbar ref="snackbar" class="mt-5" />
  </div>
</template>

<script>
import { mapActions, mapGetters,mapState } from "vuex";
import domain from "@/environment";
import axios from "axios";
import Snackbar from '@/components/ui/errorSnackbar.vue';

import { handlePaste, preventExceedingLength, preventExceedingLengthFloat } from '@/utils/inputUtils'; 
export default {
  components: {
    Snackbar,
  },
  props: {
    isLoadingButton: {
      type: Boolean,
      default: false,
    },
    visibleTabsLength: {
      type: Number,
      required: true,
    },
    progress: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data() {
    return {
      isCompleted: false,
      errorMessage:'',
      isLoading:false,
      raccordement_reseau_chaleurData: null,
      raccordement_reseau_chaleur: false,
      raccordeOptions: [
        { text: "Oui", value: true },
        { text: "Non", value: false },
      ],
      certificatOptions: [
        { text: "Avec certificats énergie", value: true },
        { text: "Sans certificats énergie", value: false },
      ],
  
      logementOptions: [
        { text: "Maison individuelle", value: "maison_individuelle" },
        { text: "Logement collectif", value: "logement_collectif" },
      ],
   
    };
  },
  created() {
  if (this.getdevisMonoFiche && this.getdevisMonoFiche.data) {
    this.raccordement_reseau_chaleurData = this.initializeraccordement_reseau_chaleur();
  
  }
},
  computed: {
  ...mapState({
    token: (state) => state.token,
  }),
  ...mapGetters(["getdevisMonoFiche"]),
  buttonText() {
      return this.visibleTabsLength <= 1 ? this.$t("NEXT") : this.$t("CONFIRMER");
    }, 
},

  watch: {
    getdevisMonoFiche: {
      handler(newDevis) {
        if (newDevis && newDevis.data) {
          this.raccordement_reseau_chaleurData = this.initializeraccordement_reseau_chaleur();
          this.raccordement_reseau_chaleur = newDevis.data?.devis.raccordement_batiment_residentiel_reseau_chaleur !== null;  
          if (this.raccordement_reseau_chaleur) {
            this.isCompleted = true;
          }
        }
      },
      deep: true,
      immediate: true,
     
  }
  },
  methods: {
    ...mapActions(["setDevisMonoFiche"]),
    onKeyDown(event) {
      preventExceedingLength(event);
    },
    onKeyDownFloat(event) {
      preventExceedingLengthFloat(event);
    },
    onPaste(event, fieldName) {
      handlePaste.call(this, event, fieldName);
    },
 async postRaccordement(payload) {
     
        try {
          const response = await axios.post(
            domain + `/raccordement_reseau_chaleur`,
            payload,
            {
              headers: {
                Authorization: `Bearer ` + this.token,
              },
            }
          );
        return response.data.data
        } catch (error) {
          if (error.response && error.response.data && error.response.data.message) {
      this.errorMessage = error.response.data.message;
    } else if (error.response && error.response.data && error.response.data.errors) {
      this.errorMessage = error.response.data.errors.join(', ');
    } else {
      this.errorMessage = "Vérifiez vos données.";
    }

    this.$refs.snackbar.showSnackbar(this.errorMessage);

    return null;
        }
    },
    
    async putRaccordement(payload) {
     
     try {
       const response = await axios.put(
         domain + `/raccordement_reseau_chaleur/${payload.id}`,
         payload,
         {
           headers: {
             Authorization: `Bearer ` + this.token,
           },
         }
       );
return response.data.data
     } catch (error) {
   
       throw new Error("Failed to put isolation combles toiture.");
     }
 },
    initializeraccordement_reseau_chaleur() {
      const raccordement_reseau_chaleur = this.getdevisMonoFiche?.data?.devis.raccordement_batiment_residentiel_reseau_chaleur || {};
     
      return {
        id: raccordement_reseau_chaleur.id,
        nbr_appartements: raccordement_reseau_chaleur.nbr_appartements || "",
        deja_raccorder: raccordement_reseau_chaleur.deja_raccorder || true,
        nom_reseau:raccordement_reseau_chaleur.nom_reseau||"",
        surface_habitable: raccordement_reseau_chaleur.surface_habitable || "",
        existe_cetificat_economie:raccordement_reseau_chaleur.existe_cetificat_economie ||false,
      };
    },
    async isolationAdd() {

       const response = await this.postRaccordement(this.raccordement_reseau_chaleurData);
       if(response.id!==null){
      this.getdevisMonoFiche.data.devis.raccordement_batiment_residentiel_reseau_chaleur_id = response.id;
      this.getdevisMonoFiche.data.devis.raccordement_batiment_residentiel_reseau_chaleur = {
        id: this.getdevisMonoFiche.data.devis.raccordement_batiment_residentiel_reseau_chaleur_id,
        surface_habitable: this.raccordement_reseau_chaleurData.surface_habitable,
        nom_reseau: this.raccordement_reseau_chaleurData.nom_reseau,
        nbr_appartements: this.raccordement_reseau_chaleurData.nbr_appartements,
        deja_raccorder: this.raccordement_reseau_chaleurData.deja_raccorder,
        existe_cetificat_economie:this.raccordement_reseau_chaleurData.existe_cetificat_economie,
      };
     this.setDevisMonoFiche(this.getdevisMonoFiche);
      return response;
    }else{
         
         this.isLoading=false;
 
         return
       }
    },

    async raccordement_reseau_chaleurAdd() {
      if (this.raccordement_reseau_chaleur) {

        const chauffe = {
          ...this.raccordement_reseau_chaleurData,
          id: this.getdevisMonoFiche.data.devis.raccordement_batiment_residentiel_reseau_chaleur.id,
        };
        
        const reponse = await this.putRaccordement(chauffe);
        this.getdevisMonoFiche.data.devis.raccordement_batiment_residentiel_reseau_chaleur = {
          id: this.getdevisMonoFiche.data.devis.raccordement_batiment_residentiel_reseau_chaleur.id,
          surface_habitable: this.raccordement_reseau_chaleurData.surface_habitable,
          nbr_appartements: this.raccordement_reseau_chaleurData.nbr_appartements,
          nom_reseau:this.raccordement_reseau_chaleurData.nom_reseau,
          deja_raccorder: this.raccordement_reseau_chaleurData.deja_raccorder,
          existe_cetificat_economie: this.raccordement_reseau_chaleurData.existe_cetificat_economie,
        };

        this.setDevisMonoFiche(this.getdevisMonoFiche);
        return reponse;
      }
      await this.isolationAdd();
     
    },
    async goToStep() {
      this.isLoading=true;
      
      await this.raccordement_reseau_chaleurAdd();
      if(this.errorMessage!==''){
     
     this.isLoading=false;
     this.$refs.snackbar.showSnackbar("Tous_obligatoires");
     this.isCompleted = false;
     return
   }
      this.isLoading=false;
      this.isCompleted = true;
      if(this.visibleTabsLength<=1){
        this.$emit("change",true);
      }else {
        this.$emit("change",false);
      }
    },
    async goToNextStep() {
      this.isLoading=true;
      
      await this.raccordement_reseau_chaleurAdd();
      if(this.errorMessage!==''){
     
     this.isLoading=false;
     this.$refs.snackbar.showSnackbar("Tous_obligatoires");
 
     return
   }
      this.isLoading=false;
      this.$emit("changeStep", 6, true);
    },
    goToPrevStep() {
      if(this.visibleTabsLength<=1){
        this.$emit("changePrev",true);
      }else {
        this.$emit("changePrev",false);
      }
      this.$emit("changeStep", 4);
    },
  },
};
</script>
<style scoped>
.custom-placeholder::placeholder {
  font-size: 13px;
}
.form-input {
  font-size: 13px;
  height: 41.1px !important;
}
.borderPointier {
  border: 2px dashed #24316d;
  position: relative;
}
.input-style {
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid #eaebec;
  padding: 0.5rem 0.75rem;
  background-color: #fff;
}

.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}
.title {
  color: #24316d;
  position: relative;
  z-index: 2;
  margin: 0;
}

.position {
  background-color: white;
  display: inline-block;
  padding: 0.05rem 2rem;
}
.custom-input {
  box-shadow: none;
}
.small-spinner .spinner-border {
  width: 1rem;
  height: 1rem;
}
</style>
