import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import "vuetify/dist/vuetify.min.css";
import Vue2Editor from "vue2-editor";

// Vue tel input
import VueTelInput from "vue-tel-input";

Vue.use(VueTelInput);

// Libraries components
import "./imports/bootstrap";
import "./imports/font-awesome";

// Application global style
import custom from "./assets/custom.scss";
import table from "./assets/table.scss";

// Application global routes
// import ROUTES from "./assets/routes.js";

// vue-multiselect component
import "vue-multiselect/dist/vue-multiselect.min.css";
import Multiselect from "vue-multiselect";

// Attacher les constantes de routes à l'application
// App.config.globalProperties.$ROUTES = ROUTES;

Vue.component("multiselect", Multiselect);

// vue-i18n
import VueI18n from "vue-i18n";
import i18n from "./i18n";
Vue.use(VueI18n);

// axios
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (
      err.response.data.status_code == 401 &&
      err.response.data.message === "Unauthenticated." &&
      store.state.token
    ) {
      store.dispatch("logout");
    } else {
      store.state.error = err.response.data.message;
      // store.state.showError = true
      return Promise.reject(err);
    }
  }
);

// VueMce
import VueMce from "vue-mce";

import vuetify from "./plugins/vuetify";
Vue.use(VueMce);

// ApexCharts
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

Vue.config.productionTip = false;
Vue.use(Vue2Editor);

Vue.use(custom, table);
new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
