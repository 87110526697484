<template>
    <b-modal id="entrepriseModal" ref="entrepriseModal" @hide="onHide">
      <template #modal-header="{ close }">
        <h5>{{ $t("organisme") }}</h5>
        <b-button size="sm" @click="close()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.028"
            height="17.028"
            viewBox="0 0 17.028 17.028"
          >
            <path
              id="Icon_material-close"
              data-name="Icon material-close"
              d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
              transform="translate(-7.5 -7.5)"
              fill="#393939"
            />
          </svg>
        </b-button>
      </template>
  
      <form @submit.prevent="submitForm">
        <div class="center">
          <b-form-group :label="$t('TYPE')">
            <b-form-select
              v-model="filtre_type"
              :options="optionsType"
            ></b-form-select>
          </b-form-group>
          <b-form-group :label="$t('Class')">
            <b-form-select
              v-model="filtre_class"
              :options="optionsCouleur"
            ></b-form-select>
          </b-form-group>
        </div>
      </form>
  
      <template #modal-footer>
        <div class="actionModel">
          <b-button variant="success" @click="applyChanges">
            <div class="block-spinner">
              {{ $t("Appliquer") }}
            </div>
          </b-button>
        </div>
      </template>
    </b-modal>
  </template>
  
  <script>
  export default {
    props: {
      optionsType: {
        type: Array,
        required: true,
      },
      optionsCouleur: {
        type: Array,
        required: true,
      },
      filtreType: {
        type: String,
        default: '',
      },
      filtreClass: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        filtre_type: this.filtreType,
        filtre_class:this.filtreClass,
      };
    },
    methods: {
      applyChanges() {
    
        this.$emit('apply', this.filtre_type,this.filtre_class);
        this.$refs.entrepriseModal.hide();
      },
      submitForm() {
    
        this.applyChanges();
      },
      onHide() {
        this.$emit('close');
      }
    }
  };
  </script>
  