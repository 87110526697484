<template>
  <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <div class="page-header">
      <h1>{{ $t("ENTREPRISES") }}</h1>
      <b-button
        size="sm"
        variant="success"
        class="button-succes-style ml-2 float-right mb-2"
        @click="openModal('entrepriseModal')" 
        v-b-modal.entrepriseModal
        ><font-awesome-icon icon="plus-circle" /> {{ $t("NEW") }}</b-button
      >
      <entrepriseModal/>
   </div>

    <div class="content">
      <div class="content-header">
        <searchBar
          :searchText="$t('ENTREPRISE_SEARCH')"
          :searchFunc="all_entreprises"
          @returnValue="updateSearch($event)"
        ></searchBar>
      </div>
      <div class="filtre">
        <p class="table-count">
          {{ getTotalRowentreprise }} {{ $t("COMPANY_ID") }}
        </p>
      </div>
      <div class="table-rapport-style w-100">
        <table>
          <thead>
            <tr>
              <th style="border-radius: 5px 0px 0px 0px">
                {{ $t("LOGO") }}
              </th>
              <th>
                {{ $t("SOCIETY") }}
              </th>
              <th>
                {{ $t("POSTAL_CODE") }}
              </th>
              <th>
                {{ $t("CITY") }}
              </th>
              <th>
                {{ $t("PHONE") }}
              </th>
              <th>{{ $t("DATE _INSC") }}</th>
              <th style="border-radius: 0px 5px 0px 0px">
                {{ $t("UTILISATEURS") }}
              </th>
              <th class="action_head"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              role="row"
              class="ligneNormale"
              v-for="(data, i) in getAllentreprises"
              :key="i"
              @click.prevent.stop="handleEnt(data)"
            >
              <td aria-colindex="1" role="cell">
                <div class="logotab" v-if="data.logo">
                  <img :src="`${downloadUrl}${data.logo}`" class="photo" />
                </div>
                <div class="Entrephoto" v-else>
                  <img v-if="data.logo" :src="`${downloadUrl}${data.logo}`" />
                  <span>{{ data.lib.slice(0, 1).toUpperCase() }}</span>
                </div>
              </td>
              <td aria-colindex="1" role="cell">
                {{ data.lib }}
              </td>
              <td aria-colindex="2" role="cell">
                {{ data.cp }}
              </td>
              <td aria-colindex="3" role="cell">
                {{ data.ville }}
              </td>
              <td aria-colindex="4" role="cell">
                {{ data.phone ? data.phone.formated_phone : "-" }}
              </td>
              <td aria-colindex="5" role="cell">
                {{ moment(data.created_at).format("DD-MM-YYYY hh:mm:ss") }}
              </td>
              <td aria-colindex="6" role="cell">
                {{ data.count_users }}
              </td>
              <td aria-colindex="7" role="cell" class="action_col">
                <b-button
                  v-if="isSuperAdmin"
                  class="button-succes-style"
                  size="sm"
                  variant="danger"
                  @click.prevent.stop="handleDelete(data)"
                >
                  <font-awesome-icon icon="trash" />
                </b-button>
                <b-button
                  class="button-succes-style"
                  size="sm"
                  variant="success"
                  @click.prevent.stop="handleUpdate(data)"
                >
                  <font-awesome-icon icon="pencil-alt" />
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <b-modal
        id="Modelent"
        ref="Modelent"
        size="xl"
        scrollable
        hide-footer
        centered
      >
        <template #modal-header="{ close }">
          <h5>{{ $t("DETAIL_ENTRE") }}</h5>
          <b-button size="sm" @click="close()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.028"
              height="17.028"
              viewBox="0 0 17.028 17.028"
            >
              <path
                id="Icon_material-close"
                data-name="Icon material-close"
                d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                transform="translate(-7.5 -7.5)"
                fill="#393939"
              />
            </svg>
          </b-button>
        </template>
        <div v-if="entDetail" class="blocpart">
          <div class="partleft">
            <div class="left">
              <div class="logoName">
                <div class="image" v-if="!entDetail.logo">
                  <div class="profil" v-if="entDetail.lib">
                    <span>{{ entDetail.lib.slice(0, 1).toUpperCase() }}</span>
                  </div>
                </div>
                <div v-else>
                  <div class="image" v-if="entDetail.lib">
                    <img
                      class="logo"
                      :src="downloadUrl + entDetail.logo"
                      alt="logo"
                    />
                  </div>
                </div>
                <div class="nameEntreprise">
                  <p class="NomEntrprise">{{ entDetail.lib }}</p>
                  <p>{{ $t("ENTREPRISE") }}</p>
                </div>
              </div>

              <b-form-file
                v-if="!entDetail.logo"
                v-model="entDetail.logo"
                class="file_input"
                style="display: none"
                accept="image/*"
              ></b-form-file>

              <b-form-file
                v-else
                v-model="entDetail.file"
                class="file_input"
                style="display: none"
                accept="image/*"
              ></b-form-file>
            </div>
            <div class="rigthpartbutton">
              <div class="block" @click="getClient(entDetail.id)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.189"
                  height="20.227"
                  viewBox="0 0 17.189 20.227"
                  class="icons"
                >
                  <g
                    id="Icon_feather-user-plus"
                    data-name="Icon feather-user-plus"
                    transform="translate(-0.5 -3.5)"
                  >
                    <path
                      id="Tracé_2334"
                      data-name="Tracé 2334"
                      d="M16.689,28.576V26.55a4.05,4.05,0,0,0-4.05-4.05H5.55A4.05,4.05,0,0,0,1.5,26.55v2.025"
                      transform="translate(0 -5.849)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                    <path
                      id="Tracé_2335"
                      data-name="Tracé 2335"
                      d="M14.851,8.55A4.05,4.05,0,1,1,10.8,4.5,4.05,4.05,0,0,1,14.851,8.55Z"
                      transform="translate(-1.706)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </g>
                </svg>
                {{ $t("CUSTOMER") }}
              </div>

              <div class="block" @click="getUser(entDetail.id)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25.865"
                  height="21.454"
                  viewBox="0 0 25.865 21.454"
                  class="icons"
                >
                  <g
                    id="Icon_feather-users"
                    data-name="Icon feather-users"
                    transform="translate(0.75 0.769)"
                  >
                    <path
                      id="Tracé_2297"
                      data-name="Tracé 2297"
                      d="M1.5,29.145V26.93A4.43,4.43,0,0,1,5.93,22.5h8.86a4.43,4.43,0,0,1,4.43,4.43v2.215"
                      transform="translate(5.145 -9.21)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Tracé_2298"
                      data-name="Tracé 2298"
                      d="M7.5,8.93A4.43,4.43,0,1,0,11.93,4.5,4.43,4.43,0,0,0,7.5,8.93Z"
                      transform="translate(3.575 -4.5)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Tracé_2299"
                      data-name="Tracé 2299"
                      d="M30,29.2V26.981A4.43,4.43,0,0,1,33.323,22.7"
                      transform="translate(-30 -9.261)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Tracé_2300"
                      data-name="Tracé 2300"
                      d="M27.331,4.7a4.43,4.43,0,0,0,0,8.583"
                      transform="translate(-19.579 -4.551)"
                      fill="none"
                      stroke="#fff"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </g>
                </svg>
                {{ $t("USER") }}
              </div>
            </div>
          </div>
          <div class="blockchmp">
            <b-row>
              <b-col md="6">
                <b-form-group
                  :label="$t('ACCOUNT_OWNER')"
                  label-for="lib"
                  class="input-lab"
                >
                  <b-form-input
                    id="lib"
                    v-model="entDetail.titulaire_compte"
                    required
                    readonly
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('COMPANY_EMAIL')"
                  class="input-lab"
                  label-for="email"
                >
                  <b-form-input
                    id="email"
                    v-model="entDetail.email"
                    required
                    readonly
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  :label="$t('COUNTRY')"
                  class="input-lab"
                  label-for="pays"
                >
                  <b-form-input
                    v-model="entDetail.pays.name"
                    readonly
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  :label="$t('CITY')"
                  class="input-lab"
                  label-for="email"
                >
                  <b-form-input
                    v-model="entDetail.ville"
                    required
                    readonly
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  :label="$t('STREET')"
                  class="input-lab"
                  label-for="name"
                >
                  <b-form-input
                    v-model="entDetail.rue"
                    readonly
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  :label="$t('PHONE_NUMBER')"
                  class="input-lab"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="entDetail.tel"
                    readonly
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  :label="$t('POSTAL_CODE')"
                  class="input-lab"
                  label-for="name"
                >
                  <b-form-input
                    v-model="entDetail.cp"
                    readonly
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  :label="$t('SIRET')"
                  class="input-lab"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="entDetail.siret"
                    readonly
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="double">
          <b-button
            variant="danger"
            class="mr-4"
            @click.prevent.stop="handleDelete(entDetail)"
          >
            {{ $t("DELETE") }}
          </b-button>

          <b-button
            variant="success"
            @click.prevent.stop="handleUpdate(entDetail)"
          >
            {{ $t("EDIT") }}
          </b-button>
        </div>
      </b-modal>

      <b-modal
        id="ModelConfigentreprise"
        ref="ModelConfigentreprise"
        @hide="v$.$reset()"
        scrollable
      >
        <template #modal-header>
          <h5>{{ $t("EDIT") }} {{ $t("COMPANY_ID") }}</h5>
          <b-button size="sm" @click="resetModal()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.028"
              height="17.028"
              viewBox="0 0 17.028 17.028"
            >
              <path
                id="Icon_material-close"
                data-name="Icon material-close"
                d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                transform="translate(-7.5 -7.5)"
                fill="#393939"
              />
            </svg>
          </b-button>
        </template>

        <form @submit.prevent="entrepriseModalNew()" v-if="entrepriseToConfig">
          <div class="center">
            <div class="logoName">
              <div class="profil" v-if="entrepriseToConfig.logo">
                <div class="newlog">
                  <img v-if="previewImage" :src="previewImage" class="imgnew" />
                  <img
                    :src="downloadUrl + entrepriseToConfig.logo"
                    class="imgnew"
                    v-else
                  />
                </div>
              </div>
            </div>
            <b-form-group :label="$t('LOGO_ENT')">
              <b-form-file
                v-model="entrepriseToConfig.logo"
                class="file_input"
                accept="image/*"
                @change="chargeImg"
                placeholder=""
                drop-placeholder=""
              >
              </b-form-file>
            </b-form-group>
            <b-form-group :label="$t('COMPANY_NAME')">
              <b-form-input
                id="name"
                v-model="v$.entrepriseToConfig.lib.$model"
                :state="validateUpdateState('lib')"
                oninput="this.value = this.value.toUpperCase()"
                aria-describedby="lib-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.entrepriseToConfig.lib.$errors"
                id="lib-feedback"
              ></error-handle>
              <div v-if="erreurlist.lib" class="error-message">
                <ul v-if="Array.isArray(erreurlist.lib)">
                  <span v-for="(e, index) in erreurlist.lib" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.lib }}</span>
              </div>
            </b-form-group>

            <b-form-group :label="$t('STREET')">
              <b-form-input
                id="rue"
                v-model="v$.entrepriseToConfig.rue.$model"
                oninput="this.value = this.value.toUpperCase()"
                :state="validateUpdateState('rue')"
                aria-describedby="rue-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.entrepriseToConfig.rue.$errors"
                id="rue-feedback"
              ></error-handle>
              <div v-if="erreurlist.rue" class="error-message">
                <ul v-if="Array.isArray(erreurlist.rue)">
                  <span v-for="(e, index) in erreurlist.rue" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.rue }}</span>
              </div>
            </b-form-group>
            <b-form-group :label="$t('POSTAL_CODE')">
              <b-form-input
                id="cp"
                v-model="v$.entrepriseToConfig.cp.$model"
                :state="validateUpdateState('cp')"
                aria-describedby="cp-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.entrepriseToConfig.cp.$errors"
                id="cp-feedback"
              ></error-handle>
              <div v-if="erreurlist.cp" class="error-message">
                <ul v-if="Array.isArray(erreurlist.cp)">
                  <span v-for="(e, index) in erreurlist.cp" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.cp }}</span>
              </div>
            </b-form-group>

            <b-form-group :label="$t('COUNTRY')">
              <multiselect
                v-model="entrepriseToConfig.pays"
                :searchable="true"
                :close-on-select="true"
                :multiple="false"
                :options="getAllcountries"
                label="name"
                track-by="id"
              >
                <template slot="noResult"> {{ $t("NO_DATA_FOUND") }} </template>
              </multiselect>
              <div v-if="erreurlist.pays" class="error-message">
                <ul v-if="Array.isArray(erreurlist.pays)">
                  <span v-for="(e, index) in erreurlist.pays" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.pays }}</span>
              </div>
              <div
                v-if="v$.entrepriseToConfig.pays.$error"
                class="error-message"
              >
                {{ $t("REQUIRED_FIELD") }}
              </div>
            </b-form-group>
            <b-form-group :label="$t('CITY')">
              <b-form-input
                id="ville"
                v-model="v$.entrepriseToConfig.ville.$model"
                oninput="this.value = this.value.toUpperCase()"
                :state="validateUpdateState('ville')"
                aria-describedby="ville-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.entrepriseToConfig.ville.$errors"
                id="ville-feedback"
              ></error-handle>
              <div v-if="erreurlist.ville" class="error-message">
                <ul v-if="Array.isArray(erreurlist.ville)">
                  <span v-for="(e, index) in erreurlist.ville" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.ville }}</span>
              </div>
            </b-form-group>
           
            <b-form-group :label="$t('PHONE')">
              <template>
                <phone
                  :tel="entrepriseToConfig.phone"
                  @phoneNumber="formatNumberE($event)"
                ></phone>
              </template>
              <div v-if="erreurlist.tel" class="error-message">
                <ul v-if="Array.isArray(erreurlist.tel)">
                  <span v-for="(e, index) in erreurlist.tel" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.tel }}</span>
              </div>
              <div
                v-if="v$.entrepriseToConfig.phone.$error"
                class="error-message"
              >
                {{ $t("REQUIRED_FIELD") }}
              </div>
              <div
                v-if="entrepriseToConfig.phone && phoneValid == false"
                class="error-message"
              >
                {{ $t("ERROR_PHONE") }}
              </div>
            </b-form-group>
            <b-form-group :label="$t('ASSURANCE')">
              <b-form-input
                id="nom_assurance"
                v-model="v$.entrepriseToConfig.nom_assurance.$model"
                oninput="this.value = this.value.toUpperCase()"
                :state="validateUpdateState('nom_assurance')"
                aria-describedby="nom_assurance-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.entrepriseToConfig.nom_assurance.$errors"
                id="nom_assurance-feedback"
              ></error-handle>
              <div v-if="erreurlist.nom_assurance" class="error-message">
                <ul v-if="Array.isArray(erreurlist.nom_assurance)">
                  <span
                    v-for="(e, index) in erreurlist.nom_assurance"
                    :key="index"
                  >
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.nom_assurance }}</span>
              </div>
            </b-form-group>
            <b-form-group :label="$t('NUM_ASS')">
              <b-form-input
                id="num_assurance"
                v-model="v$.entrepriseToConfig.num_assurance.$model"
                oninput="this.value = this.value.toUpperCase()"
                :state="validateUpdateState('num_assurance')"
                aria-describedby="num_assurance-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.entrepriseToConfig.num_assurance.$errors"
                id="num_assurance-feedback"
              ></error-handle>
            </b-form-group>

            <b-form-group :label="$t('SIREN_SIRET') + '*'">
              <b-form-input
                id="siret"
                v-model="v$.entrepriseToConfig.siret.$model"
                :state="validateUpdateState('siret')"
                aria-describedby="siret-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.entrepriseToConfig.siret.$errors"
                id="siret-feedback"
              ></error-handle>
              <div v-if="erreurlist.siret" class="error-message">
                <ul v-if="Array.isArray(erreurlist.siret)">
                  <span v-for="(e, index) in erreurlist.siret" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.siret }}</span>
              </div>
            </b-form-group>
            <b-form-group :label="$t('NUM_TVA') + '*'">
              <b-form-input
                id="num_tva"
                 
                v-model="v$.entrepriseToConfig.num_tva.$model"
                :state="validateUpdateState('num_tva')"
                aria-describedby="num_tva-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.entrepriseToConfig.num_tva.$errors"
                id="num_tva-feedback"
              ></error-handle>
              <div v-if="erreurlist.num_tva" class="error-message">
                <ul v-if="Array.isArray(erreurlist.num_tva)">
                  <span v-for="(e, index) in erreurlist.num_tva" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.num_tva }}</span>
              </div>
            </b-form-group>
            <b-form-group :label="$t('RCS')">
              <b-form-input
                class="mt-2"
                v-model="v$.entrepriseToConfig.rcs.$model"
                :state="validateUpdateState('rcs')"
                aria-describedby="rcs-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.entrepriseToConfig.rcs.$errors"
                id="rcs-feedback"
              ></error-handle>
              <div v-if="erreurlist.rcs" class="error-message">
                <ul v-if="Array.isArray(erreurlist.rcs)">
                  <span v-for="(e, index) in erreurlist.rcs" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.rcs }}</span>
              </div>
              <div
                v-if="v$.entrepriseToConfig.rcs.$error"
                class="error-message"
              >
                {{ $t("REQUIRED_FIELD") }}
              </div>
            </b-form-group>

            <h5 class="titresociete">{{ $t("COMPANY_BANK") }}</h5>

            <b-form-group :label="$t('ACCOUNT_OWNER')">
              <b-form-input
                id="name"
                v-model="v$.entrepriseToConfig.titulaire_compte.$model"
                :state="validateUpdateState('titulaire_compte')"
                aria-describedby="titulaire_compte-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.entrepriseToConfig.titulaire_compte.$errors"
                id="titulaire_compte-feedback"
              ></error-handle>
              <div v-if="erreurlist.titulaire_compte" class="error-message">
                <ul v-if="Array.isArray(erreurlist.titulaire_compte)">
                  <span
                    v-for="(e, index) in erreurlist.titulaire_compte"
                    :key="index"
                  >
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.titulaire_compte }}</span>
              </div>
            </b-form-group>

            <b-form-group label="SWIFT_BIC*" label-for="SWIFT_BIC">
              <b-form-input
                id="SWIFT_BIC"
                v-model="v$.entrepriseToConfig.SWIFT_BIC.$model"
                minlength="8"
                maxlength="11"
                oninput="this.value = this.value.toUpperCase()"
                :state="validateUpdateState('SWIFT_BIC')"
                aria-describedby="SWIFT_BIC-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.entrepriseToConfig.SWIFT_BIC.$errors"
                id="SWIFT_BIC-feedback"
              ></error-handle>
              <div v-if="erreurlist.SWIFT_BIC" class="error-message">
                <ul v-if="Array.isArray(erreurlist.SWIFT_BIC)">
                  <span v-for="(e, index) in erreurlist.SWIFT_BIC" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.SWIFT_BIC }}</span>
              </div>
            </b-form-group>
            <b-form-group label="IBAN*" label-for="IBAN">
              <b-form-input
                id="IBAN"
                v-model="v$.entrepriseToConfig.IBAN.$model"
                :state="validateUpdateState('IBAN')"
                aria-describedby="IBAN-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.entrepriseToConfig.IBAN.$errors"
                id="IBAN-feedback"
              ></error-handle>
              <div v-if="erreurlist.IBAN" class="error-message">
                <ul v-if="Array.isArray(erreurlist.IBAN)">
                  <span v-for="(e, index) in erreurlist.IBAN" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.IBAN }}</span>
              </div>
            </b-form-group>
          </div>
        </form>
        <template #modal-footer>
          <b-alert variant="warning" show v-if="errorU">
            {{ errorU }}
          </b-alert>
          <b-alert variant="warning" show v-if="getentrepriseErrorUpdate">
            {{ getentrepriseErrorUpdate }}
          </b-alert>
          <div class="double">
            <b-button variant="danger" @click="resetModal()">
              <div class="block-spinner">
                {{ $t("CANCEL") }}
              </div>
            </b-button>
            <b-button
              variant="success"
              @click="entrepriseModalNew"
              class="succes-btn-modal btn-success"
            >
              <div class="block-spinner">
                {{ $t("CONFIRM") }}
                <div v-if="getentrepriseLoading" class="loading ml-2">
                  <div class="spinner-border" role="status"></div>
                </div>
              </div>
            </b-button>
          </div>
        </template>
      </b-modal>

      <deleteModalVue
        :loader="getentrepriseLoading"
        :elemToDelete="entrepriseToDelete"
        :elemDelete="delete_entreprise"
        @updateList="setup(false)"
      ></deleteModalVue>

      <b-pagination
        v-model="page"
        :total-rows="getTotalRowentreprise"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        last-number
        first-number
        align="center"
        size="sm"
        @change="pagination"
        class=""
      ></b-pagination>
    </div>

    <div class="text-center center-spin">
      <b-spinner
        v-if="getentrepriseLoading"
        variant="primary"
        label="Spinning"
        class="big-spin"
      ></b-spinner>
    </div>
    <CommentMarche></CommentMarche>
  </div>
</template>

<script>
import entrepriseModal from "@/components/Ajoutmodel/entreprise/entrepriseModal.vue";
import { mapActions, mapGetters } from "vuex";
import { domains } from "@/environment";
import moment from "moment";
import { useVuelidate } from "@vuelidate/core";
import deleteModalVue from "../components/ui/deleteModal.vue";
import searchBar from "../components/ui/searchBar.vue";
import {
  required,
  maxLength,
  minLength,
  alphaNum,
  numeric,
  email,
} from "@vuelidate/validators";
import errorHandle from "../components/ui/errorHandle.vue";
import phone from "../components/ui/phone.vue";
import CommentMarche from "../components/commentMarche.vue";

export default {
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  components: {
    searchBar,
    deleteModalVue,
    errorHandle,
    phone,
    CommentMarche,
    entrepriseModal
  },
  data() {
    return {
      search: "",
      page: 1,
      perPage: 10,
      entrepriseToConfig: {
        pays: { iso_code: null },
        phone: {
          phone_number: null,
          phone_iso_code: null,
        },
      },
      entrepriseToDelete: null,
      entDetail: null,
      newentreprise: {
        logo: null,
        lib: null,
        email: null,
        rue: null,
        cp: null,
        pays: { iso_code: null },
        ville: null,
        phone: {
          phone_number: null,
          phone_iso_code: null,
        },
        SWIFT_BIC: null,
        IBAN: null,
        siret: null,
        num_tva: null,
        titulaire_compte: null,
        rcs: null,
        nom_assurance: null,
        num_assurance: null,
      },
      erreurlist: {
        lib: null,
        email: null,
        rue: null,
        ville: null,
        pays: null,
        cp: null,
        tel: null,
        rcs: null,
        nom_assurance: null,
        num_assurance: null,
        SWIFT_BIC: null,
        IBAN: null,
        siret: null,
        num_tva: null,
        titulaire_compte: null,
        numero_rge: null,
      },
      previewNewImage: null,
      previewImage: null,
      downloadUrl: domains.download,
      options: [
        { value: "type.particulier", text: "particulier" },
        { value: "type.professionnel", text: "professionnel" },
      ],
      errorD: null,
      errorS: null,
      errorU: null,
      phoneValid: null,
      resultValid: null,
    };
  },
  validations() {
    return {
      newentreprise: {
        lib: { required, maxLength: maxLength(100) },
        nom_assurance: { maxLength: maxLength(100) },
        num_assurance: { maxLength: maxLength(40) },
        email: { required, email, maxLength: maxLength(50) },
        rue: { required, maxLength: maxLength(45) },
        cp: {
          required,
          numeric,
          minLength: minLength(4),
          maxLength: maxLength(5),
        },
        pays: { required },
        ville: { required, maxLength: maxLength(40) },
        phone: {
          phone_number: { required },
        },
        titulaire_compte: { maxLength: maxLength(40) },
        SWIFT_BIC: {
          required,
          minLength: minLength(8),
          maxLength: maxLength(11),
          alphaNum,
        },
        IBAN: { required, maxLength: maxLength(34), alphaNum },
        num_tva: { required, maxLength: maxLength(30), alphaNum },
        siret: {
          required,
          minLength: minLength(9),
          maxLength: maxLength(14),
          alphaNum,
        },
        rcs: { required, maxLength: maxLength(50) },
      },
      entrepriseToConfig: {
        lib: { required, maxLength: maxLength(100) },
        nom_assurance: { maxLength: maxLength(100) },
        num_assurance: { maxLength: maxLength(40) },
        rue: { required, maxLength: maxLength(45) },
        cp: {
          required,
          numeric,
          minLength: minLength(4),
          maxLength: maxLength(5),
        },
        pays: { required },
        ville: { required, maxLength: maxLength(40) },
        phone: {
          phone_number: { required },
        },
        titulaire_compte: { maxLength: maxLength(40) },
        SWIFT_BIC: {
          required,
          maxLength: minLength(8),
          minLength: maxLength(11),
          alphaNum,
        },
        IBAN: { required, maxLength: maxLength(34), alphaNum },
        num_tva: { required, maxLength: maxLength(30), alphaNum },
        siret: {
          required,
          minLength: minLength(9),
          maxLength: maxLength(14),
          alphaNum,
        },
        rcs: { required, maxLength: maxLength(50) },
      },

      $validationGroups: {
        updateGroup: ["entrepriseToConfig"],
      },
    };
  },
  methods: {
    ...mapActions([
      "edit_entreprise",
      "all_entreprises",
      "delete_entreprise",
      "store_entreprise",
      "active_email_entreprise",
      "resend_active_email",
      "all_countries",
      "pays",
    ]),
    openModal(type) {
      
      if (type === "entrepriseModal") {
        this.$root.$emit("bv::show::modal", "entrepriseModal");
      }
    
    },
    validateState(name) {
      const { $dirty, $error } = this.v$.newentreprise[name];
      return $dirty ? !$error : null;
    },

    validateUpdateState(name) {
      const { $dirty, $error } = this.v$.entrepriseToConfig[name];
      return $dirty ? !$error : null;
    },

    formatNumber(event) {
      this.newentreprise.phone = {
        phone_number: event.number,
        phone_iso_code: event.country.iso2,
      };
      this.resultValid = event.isValid;
    },

    formatNumberE(event) {
      console.log('event',event)
      this.entrepriseToConfig.phone = {
        phone_number: event.number,
        phone_iso_code: event.country.iso2,
      };
      this.phoneValid = event.isValid;
    },

    chargeNewImg(e) {
      const file = e.target.files[0];
      this.previewNewImage = URL.createObjectURL(file);
    },
    chargeImg(e) {
      const file = e.target.files[0];
      this.entrepriseToConfig.logo = file;
      this.previewImage = URL.createObjectURL(file);
    },

    updateSearch(s) {
      this.page = 1;
      this.search = s;
    },

    moment: function (date) {
      return moment(new Date(date)).locale(this.getSelectedLang.iso_code);
    },

    resetModal() {
      this.newentreprise = {
        lib: null,
        email: null,
        rue: null,
        cp: null,
        pays: { iso_code: null },
        ville: null,
        tel: null,
        phone: {
          phone_number: null,
          phone_iso_code: null,
        },
        SWIFT_BIC: null,
        IBAN: null,
        siret: null,
        num_tva: null,
        titulaire_compte: null,
      };
      this.erreurlist = {
        lib: null,
        email: null,
        rue: null,
        ville: null,
        pays: null,
        cp: null,
        tel: null,
        rcs: null,
        nom_assurance: null,
        num_assurance: null,
        SWIFT_BIC: null,
        IBAN: null,
        siret: null,
        num_tva: null,
        titulaire_compte: null,
        numero_rge: null,
      };
      this.errorS = null;
      this.errorU = null;
      this.$refs["ModelConfigentreprise"].hide();
      this.$refs["entrepriseModal"].hide();
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },

    handleEnt(CAT) {
      this.entDetail = CAT;
      this.$refs["Modelent"].show();
    },

    handleUpdate(CAT) {
      this.entrepriseToConfig = CAT;
      this.errorU = null;
      this.$refs["ModelConfigentreprise"].show();
    },
    handleDelete(CAT) {
      this.entrepriseToDelete = { ...CAT };
      this.$root.$emit("bv::show::modal", "ModelDelete");
    },
    async addCLI() {
      await this.v$.$validate();
      const isFormCorrect = await this.v$.newentreprise.$error;
      if (isFormCorrect) return;
      if (this.resultValid == false) return;
      this.erreurlist = {
        lib: null,
        email: null,
        rue: null,
        ville: null,
        pays: null,
        cp: null,
        tel: null,
        rcs: null,
        nom_assurance: null,
        num_assurance: null,
        SWIFT_BIC: null,
        IBAN: null,
        siret: null,
        num_tva: null,
        titulaire_compte: null,
        numero_rge: null,
      };
      this.errorS = null;
      var bodyFormData = new FormData();
      if (this.newentreprise.logo) {
        bodyFormData.append("logo", this.newentreprise.logo);
      }
      bodyFormData.append("lib", this.newentreprise.lib);
      bodyFormData.append("email", this.newentreprise.email);
      bodyFormData.append("rue", this.newentreprise.rue);
      bodyFormData.append("cp", this.newentreprise.cp);
      bodyFormData.append("pays", this.newentreprise.pays.iso_code);
      bodyFormData.append("ville", this.newentreprise.ville);

      // Format numero tel
      var num = this.newentreprise.phone.phone_number.indexOf(" ");
      this.newentreprise.phone = {
        phone_number: this.newentreprise.phone.phone_number
          .slice(num + 1)
          .split(" ")
          .join(""),
        phone_iso_code: this.newentreprise.phone.phone_iso_code,
      };

      bodyFormData.append("tel", this.newentreprise.phone.phone_number);
      bodyFormData.append(
        "phone_number",
        this.newentreprise.phone.phone_number
      );
      bodyFormData.append(
        "phone_iso_code",
        this.newentreprise.phone.phone_iso_code
      );
      bodyFormData.append("SWIFT_BIC", this.newentreprise.SWIFT_BIC);
      bodyFormData.append("IBAN", this.newentreprise.IBAN);
      bodyFormData.append("siret", this.newentreprise.siret);
      bodyFormData.append("num_tva", this.newentreprise.num_tva);
      bodyFormData.append("rcs", this.newentreprise.rcs);
      bodyFormData.append("nom_assurance", this.newentreprise.nom_assurance);
      bodyFormData.append("num_assurance", this.newentreprise.num_assurance);
      bodyFormData.append(
        "titulaire_compte",
        this.newentreprise.titulaire_compte
      );
      await this.store_entreprise(bodyFormData)
        .then(() => {
          this.errorS = null;
          this.hideModal("entrepriseModal");
          this.resetModal();
          this.setup(true);
        })
        .catch((err) => {
          if (this.isObject(err)) {
            for (const [key, value] of Object.entries(err)) {
              if (!this.erreurlist[key]) {
                this.erreurlist[key] = value;
              }
            }
          } else {
            this.errorS = err;
          }
        });
    },
    isObject(obj) {
      return Object.prototype.toString.call(obj) === "[object Object]";
    },
    async entrepriseModalNew() {
      const isFormCorrect = await this.v$.$validationGroups.updateGroup.$error;
      if (isFormCorrect) return;
      if (this.phoneValid == false) return;
      this.erreurlist = {
        lib: null,
        email: null,
        rue: null,
        ville: null,
        pays: null,
        cp: null,
        tel: null,
        rcs: null,
        nom_assurance: null,
        num_assurance: null,
        SWIFT_BIC: null,
        IBAN: null,
        siret: null,
        num_tva: null,
        titulaire_compte: null,
        numero_rge: null,
      };
      this.errorU = null;
      var bodyFormData = new FormData();
      bodyFormData.append("id", this.entrepriseToConfig.id);
      bodyFormData.append("lib", this.entrepriseToConfig.lib);
      bodyFormData.append("rue", this.entrepriseToConfig.rue);
      bodyFormData.append("cp", this.entrepriseToConfig.cp);
      bodyFormData.append("ville", this.entrepriseToConfig.ville);

      // Format numero tel
      if (this.entrepriseToConfig.phone) {
        var numE = this.entrepriseToConfig.phone.phone_number.indexOf(" ");
        this.entrepriseToConfig.phone = {
          phone_number: this.entrepriseToConfig.phone.phone_number
            .slice(numE + 1)
            .split(" ")
            .join(""),
          phone_iso_code: this.entrepriseToConfig.phone.phone_iso_code,
        };
      }
      bodyFormData.append("tel", this.entrepriseToConfig.phone.phone_number);
      bodyFormData.append(
        "phone_number",
        this.entrepriseToConfig.phone.phone_number
      );
      bodyFormData.append(
        "phone_iso_code",
        this.entrepriseToConfig.phone.phone_iso_code
      );
      bodyFormData.append("SWIFT_BIC", this.entrepriseToConfig.SWIFT_BIC);
      bodyFormData.append("IBAN", this.entrepriseToConfig.IBAN);
      bodyFormData.append("siret", this.entrepriseToConfig.siret);
      bodyFormData.append("num_tva", this.entrepriseToConfig.num_tva);
      bodyFormData.append("rcs", this.entrepriseToConfig.rcs);
      bodyFormData.append(
        "nom_assurance",
        this.entrepriseToConfig.nom_assurance
      );
      bodyFormData.append(
        "num_assurance",
        this.entrepriseToConfig.num_assurance
      );
      bodyFormData.append(
        "titulaire_compte",
        this.entrepriseToConfig.titulaire_compte
      );
      if (this.entrepriseToConfig.logo)
        bodyFormData.append("logo", this.entrepriseToConfig.logo);

      await this.edit_entreprise(bodyFormData)
        .then(() => {
          this.hideModal("ModelConfigentreprise");
          this.hideModal("entrepriseModal");
          this.errorU = null;
          this.setup();
        })
        .catch((err) => {
          if (this.isObject(err)) {
            for (const [key, value] of Object.entries(err)) {
              if (!this.erreurlist[key]) {
                this.erreurlist[key] = value;
              }
            }
          } else {
            this.errorU = err;
          }
        });
    },

    getUser(i) {
      this.$router.push({ path: "users", query: { entreprise: i } });
    },

    getClient(i) {
      this.$router.push({ path: "client", query: { entreprise: i } });
    },

    confirm_email() {
      this.active_email_entreprise({
        email_u: this.entDetail.email,
        name: this.entDetail.lib,
      });
    },

    resend_email() {
      this.resend_active_email({
        email_u: this.entDetail.email,
      });
    },

    pagination(paginate) {
      this.page = paginate;
      this.all_entreprises({
        search: this.search,
        page: this.page,
        per_page: this.perPage,
      });
    },

    setup(refresh = false) {
      if (refresh) this.page = 1;
      this.search = null;
      if (this.isSuperAdmin)
        this.all_entreprises({
          page: this.page,
          per_page: this.perPage,
        });
    },
  },

  mounted() {
    this.setup(true);

    this.all_countries({ page: this.page, perPage: 1000 });
  },

  computed: {
    ...mapGetters([
      "getentrepriseLoading",
      "getTotalRowentreprise",
      "getentrepriseErrorUpdate",
      "getTabs",
      "getAllentreprises",
      "getOnlineUser",
      "getPays",
      "getdictionnaire",
      "getSelectedLang",
      "getAllcountries",
    ]),
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },
    email_activate() {
      if (this.entDetail.email_activated_at) return true;
      else return false;
    },
    translations() {
      var trans = {
        countrySelectorLabel: "Choisir un pays",
        phoneNumberLabel: "Numéro de téléphone",
      };
      this.getdictionnaire.forEach((element) => {
        if (element.key == "countrySelectorLabel")
          trans.countrySelectorLabel = element.lib;
        if (element.key == "phoneNumberLabel")
          trans.phoneNumberLabel = element.lib;
      });

      return trans;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  .content-header {
    .affichage {
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 2px solid #00000019;

      .font_icon {
        cursor: pointer;
        margin: 0 4px;
        border: 2px solid #000;
        padding: 2px 8px;
        border-radius: 50%;

        .icon_down,
        .icon_up {
          @media only screen and (max-width: 700px) {
            font-size: 14px;
          }
          font-size: 16px;
          font-weight: 500;
        }

        .icon_down {
          color: #28367a;
        }

        .icon_up {
          color: #e4261b;
        }
      }

      .up {
        border-color: #e4261b;
      }

      .down {
        border-color: #28367a;
      }
    }
  }

  & p {
    font-size: 12px;
    font-weight: 500;
    padding: 3px 0;
    margin: 0;
  }
}

.w-100 {
  background-color: #f8f8f8;
  padding: 4px;
  overflow-x: auto;
}

body {
  background: #fafdff;
}

.filtre {
  display: flex;
  align-items: center;

  .table-count,
  .productTrie {
    margin: 0 15px;
  }

  .productTrie {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 11px;
    overflow: hidden;
    padding: 4px 8px;
    border-radius: 32px;
    background-color: #34c38f;
    color: #fff;

    .icons {
      cursor: pointer;
      width: 18px;
      height: 18px;
      margin-left: 4px;
    }
  }
}

#Modelent {
  .blocpart {
    padding: 16px 16px 16px 8px;
    justify-content: space-between;
    @media only screen and (max-width: 900px) {
      flex-direction: column;
    }
    .blockchmp {
      background: #fff;
      width: 100%;
      padding: 16px 8px;
      border-radius: 6px;
      box-shadow: 0 2px 6px #00000019;

      .row {
        margin-left: 0;
        margin-right: 0;
      }

      .col-md-6 {
        padding: 6px 10px;
      }

      input {
        height: 42px;
        background-color: #fff !important;
        border-color: #b5b5b5;
        font-size: 14px;
        color: #131523;
      }

      .input-lab {
        font-size: 14px;
        color: #515151;
        font-weight: 600;
        margin-bottom: 4px;
      }
    }
    .partleft {
      @media only screen and (max-width: 900px) {
        width: 100%;
      }

      display: flex;
      flex-direction: column;
      width: 25%;
      padding: 8px;

      .left {
        @media only screen and (max-width: 900px) {
          width: 100%;
        }
        padding: 8px;
        margin-bottom: 14px;
        display: flex;
        align-items: center;
        justify-content: center;

        label {
          font-size: 14px;
          color: #131523;
          margin-bottom: 0;
        }
        .logoName {
          display: flex;
          align-items: center;
          width: 100%;
          .nameEntreprise {
            p {
              margin: 0;
              color: #515151;
              font-weight: 400;
            }
            .NomEntrprise {
              font-weight: 500;
              font-size: 24px;
              color: #131523;
              line-height: 20px;
            }
          }
        }
        .file_input {
          display: flex;
        }
        .image {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 91px;
          height: 91px;
          margin-right: 8px;
          .logo {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: #e9ecef;
          }
          .profil {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 91px;
            height: 91px;
            border-radius: 50%;
            background-color: #131523;
            color: #fff;
            font-size: 32px;
            font-weight: 600;
            padding: 8px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
        }
      }

      .rigthpartbutton {
        .block {
          cursor: pointer;
          background-color: #28367a;
          color: #fff;
          padding: 16px;
          display: flex;
          align-items: center;
          font-size: 14px;
          border-radius: 5px;
          margin-bottom: 12px;

          .icons {
            margin-right: 14px;
          }
          .text {
            white-space: nowrap;
          }
        }
      }
    }

    display: flex;
  }
}
.logotab {
  width: 48px;
  height: 48px;
  border-radius: 50px;
  margin: 0 auto;
  .photo {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
.Entrephoto {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #28367a;
  margin: 0 auto;
  color: #fff;
  font-size: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.email {
  width: 95%;
  margin: 0 auto;
  padding: 15px;
  font-size: 14px;
  background-color: #f5f6f8;
  color: #e4261b;
  border: 1px solid #ced4da;

  button {
    margin-top: 4px;
    float: right;
  }
}
.newlog {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 91px;
  height: 91px;

  .imgnew {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #e9ecef;
  }
}
.titresociete {
  color: #334081;
  width: 100%;
  margin-top: 4px;
}
</style>
