<template>
     <b-modal
        scrollable
        id="entrepriseModal"
        ref="entrepriseModal"
        @hide="v$.$reset()"
      >
        <template #modal-header>
          <h5>{{ $t("CREATE") }} {{ $t("COMPANY_ID") }}</h5>
          <b-button size="sm" @click="resetModal()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.028"
              height="17.028"
              viewBox="0 0 17.028 17.028"
            >
              <path
                id="Icon_material-close"
                data-name="Icon material-close"
                d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                transform="translate(-7.5 -7.5)"
                fill="#393939"
              />
            </svg>
          </b-button>
        </template>

        <form @submit.prevent="addCLI">
          <div class="center">
            <div class="logoName">
              <div class="profil" v-if="newentreprise.logo">
                <div class="newlog">
                  <img :src="previewNewImage" alt="logo" class="imgnew" />
                </div>
              </div>
            </div>
            <b-form-group :label="$t('LOGO_ENT')">
              <b-form-file
                v-model="newentreprise.logo"
                class="file_input"
                accept="image/*"
                @change="chargeNewImg"
                placeholder=""
                drop-placeholder=""
              ></b-form-file>
            </b-form-group>

            <b-form-group :label="$t('SOCIETY') + '*'">
              <b-form-input
                id="name"
                v-model="v$.newentreprise.lib.$model"
                :state="validateState('lib')"
                oninput="this.value = this.value.toUpperCase()"
                aria-describedby="lib-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.newentreprise.lib.$errors"
                id="lib-feedback"
              ></error-handle>
              <div v-if="erreurlist.lib" class="error-message">
                <ul v-if="Array.isArray(erreurlist.lib)">
                  <span v-for="(e, index) in erreurlist.lib" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.lib }}</span>
              </div>
            </b-form-group>
            <b-form-group :label="$t('COMPANY_EMAIL') + '*'">
              <b-form-input
                id="email"
                v-model="v$.newentreprise.email.$model"
                type="email"
                :state="validateState('email')"
                aria-describedby="email-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.newentreprise.email.$errors"
                id="email-feedback"
              ></error-handle>
              <div v-if="erreurlist.email" class="error-message">
                <ul v-if="Array.isArray(erreurlist.email)">
                  <span v-for="(e, index) in erreurlist.email" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.email }}</span>
              </div>
            </b-form-group>
            <b-form-group :label="$t('STREET') + '*'">
              <b-form-input
                id="rue"
                v-model="v$.newentreprise.rue.$model"
                oninput="this.value = this.value.toUpperCase()"
                :state="validateState('rue')"
                aria-describedby="rue-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.newentreprise.rue.$errors"
                id="rue-feedback"
              ></error-handle>
              <div v-if="erreurlist.rue" class="error-message">
                <ul v-if="Array.isArray(erreurlist.rue)">
                  <span v-for="(e, index) in erreurlist.rue" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.rue }}</span>
              </div>
            </b-form-group>
            <b-form-group :label="$t('POSTAL_CODE') + '*'" label-for="cp">
              <b-form-input
                id="cp"
                v-model="v$.newentreprise.cp.$model"
                :state="validateState('cp')"
                aria-describedby="cp-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.newentreprise.cp.$errors"
                id="cp-feedback"
              ></error-handle>
              <div v-if="erreurlist.cp" class="error-message">
                <ul v-if="Array.isArray(erreurlist.cp)">
                  <span v-for="(e, index) in erreurlist.cp" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.cp }}</span>
              </div>
            </b-form-group>

            <b-form-group :label="$t('COUNTRY') + '*'">
              <multiselect
                v-model="newentreprise.pays"
                :searchable="true"
                :close-on-select="true"
                :multiple="false"
                :options="getAllcountries"
                label="name"
                track-by="id"
              >
                <template slot="noResult"> {{ $t("NO_DATA_FOUND") }} </template>
              </multiselect>
              <div v-if="erreurlist.pays" class="error-message">
                <ul v-if="Array.isArray(erreurlist.pays)">
                  <span v-for="(e, index) in erreurlist.pays" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.pays }}</span>
              </div>
              <div v-if="v$.newentreprise.pays.$error" class="error-message">
                {{ $t("REQUIRED_FIELD") }}
              </div>
            </b-form-group>
            <b-form-group :label="$t('CITY') + '*'">
              <b-form-input
                id="ville"
                v-model="v$.newentreprise.ville.$model"
                oninput="this.value = this.value.toUpperCase()"
                :state="validateState('ville')"
                aria-describedby="ville-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.newentreprise.ville.$errors"
                id="ville-feedback"
              ></error-handle>
              <div v-if="erreurlist.ville" class="error-message">
                <ul v-if="Array.isArray(erreurlist.ville)">
                  <span v-for="(e, index) in erreurlist.ville" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.ville }}</span>
              </div>
            </b-form-group>
            <b-form-group :label="$t('PHONE') + '*'">
              <template>
                <phone @phoneNumber="formatNumber($event)"></phone>
              </template>

              <div v-if="erreurlist.tel" class="error-message">
                <ul v-if="Array.isArray(erreurlist.tel)">
                  <span v-for="(e, index) in erreurlist.tel" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.tel }}</span>
              </div>
              <div
                v-if="newentreprise.phone && resultValid == false"
                class="error-message"
              >
                {{ $t("ERROR_PHONE") }}
              </div>
            </b-form-group>

            <b-form-group :label="$t('ASSURANCE')">
              <b-form-input
                class="mt-2"
                v-model="v$.newentreprise.nom_assurance.$model"
                :state="validateState('nom_assurance')"
                aria-describedby="nom_assurance-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.newentreprise.nom_assurance.$errors"
                id="nom_assurance-feedback"
              ></error-handle>
              <div v-if="erreurlist.nom_assurance" class="error-message">
                <ul v-if="Array.isArray(erreurlist.nom_assurance)">
                  <span
                    v-for="(e, index) in erreurlist.nom_assurance"
                    :key="index"
                  >
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.nom_assurance }}</span>
              </div>
            </b-form-group>

            <b-form-group :label="$t('NUM_ASS')">
              <b-form-input
                class="mt-2"
                v-model="v$.newentreprise.num_assurance.$model"
                :state="validateState('num_assurance')"
                aria-describedby="num_assurance-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.newentreprise.num_assurance.$errors"
                id="num_assurance-feedback"
              ></error-handle>
            </b-form-group>

            <b-form-group :label="$t('SIREN_SIRET') + '*'">
              <b-form-input
                id="siret"
                v-model="v$.newentreprise.siret.$model"
                :state="validateState('siret')"
                aria-describedby="siret-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.newentreprise.siret.$errors"
                id="siret-feedback"
              ></error-handle>
              <div v-if="erreurlist.siret" class="error-message">
                <ul v-if="Array.isArray(erreurlist.siret)">
                  <span v-for="(e, index) in erreurlist.siret" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.siret }}</span>
              </div>
            </b-form-group>

            <b-form-group :label="$t('NUM_TVA') + '*'">
              <b-form-input
                id="num_tva"
                    
                v-model="v$.newentreprise.num_tva.$model"
                :state="validateState('num_tva')"
                aria-describedby="num_tva-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.newentreprise.num_tva.$errors"
                id="num_tva-feedback"
              ></error-handle>
              <div v-if="erreurlist.num_tva" class="error-message">
                <ul v-if="Array.isArray(erreurlist.num_tva)">
                  <span v-for="(e, index) in erreurlist.num_tva" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.num_tva }}</span>
              </div>
            </b-form-group>

            <b-form-group :label="$t('RCS') + '*'">
              <b-form-input
                class="mt-2"
                v-model="v$.newentreprise.rcs.$model"
                :state="validateState('rcs')"
                aria-describedby="rcs-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.newentreprise.rcs.$errors"
                id="rcs-feedback"
              ></error-handle>
              <div v-if="erreurlist.rcs" class="error-message">
                <ul v-if="Array.isArray(erreurlist.rcs)">
                  <span v-for="(e, index) in erreurlist.rcs" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.rcs }}</span>
              </div>
            </b-form-group>

            <h5 class="titresociete">{{ $t("COMPANY_BANK") }}</h5>

            <b-form-group :label="$t('ACCOUNT_OWNER')">
              <b-form-input
                id="name"
                v-model="v$.newentreprise.titulaire_compte.$model"
                :state="validateState('titulaire_compte')"
                aria-describedby="titulaire_compte-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.newentreprise.titulaire_compte.$errors"
                id="titulaire_compte-feedback"
              ></error-handle>
              <div v-if="erreurlist.titulaire_compte" class="error-message">
                <ul v-if="Array.isArray(erreurlist.titulaire_compte)">
                  <span
                    v-for="(e, index) in erreurlist.titulaire_compte"
                    :key="index"
                  >
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.titulaire_compte }}</span>
              </div>
            </b-form-group>

            <b-form-group label="SWIFT_BIC*" label-for="SWIFT_BIC">
              <b-form-input
                id="SWIFT_BIC"
                v-model="v$.newentreprise.SWIFT_BIC.$model"
                minlength="8"
                maxlength="11"
                oninput="this.value = this.value.toUpperCase()"
                :state="validateState('SWIFT_BIC')"
                aria-describedby="SWIFT_BIC-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.newentreprise.SWIFT_BIC.$errors"
                id="SWIFT_BIC-feedback"
              ></error-handle>
              <div v-if="erreurlist.SWIFT_BIC" class="error-message">
                <ul v-if="Array.isArray(erreurlist.SWIFT_BIC)">
                  <span v-for="(e, index) in erreurlist.SWIFT_BIC" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.SWIFT_BIC }}</span>
              </div>
            </b-form-group>

            <b-form-group label="IBAN*" label-for="IBAN">
              <b-form-input
                id="IBAN"
                v-model="v$.newentreprise.IBAN.$model"
                :state="validateState('IBAN')"
                aria-describedby="IBAN-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.newentreprise.IBAN.$errors"
                id="IBAN-feedback"
              ></error-handle>
              <div v-if="erreurlist.IBAN" class="error-message">
                <ul v-if="Array.isArray(erreurlist.IBAN)">
                  <span v-for="(e, index) in erreurlist.IBAN" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.IBAN }}</span>
              </div>
            </b-form-group>
          </div>
        </form>
        <template #modal-footer>
          <b-alert variant="warning" show v-if="errorS">
            {{ errorS }}
          </b-alert>
          <div class="double">
            <b-button variant="danger" @click="resetModal()">
              <div class="block-spinner">
                {{ $t("CANCEL") }}
              </div>
            </b-button>
            <b-button
              variant="success"
              @click="addCLI"
              class="succes-btn-modal btn-success"
            >
              <div class="block-spinner">
                {{ $t("ADD") }}
                <div v-if="getentrepriseLoading" class="loading ml-2">
                  <div class="spinner-border" role="status"></div>
                </div>
              </div>
            </b-button>
          </div>
        </template>
      </b-modal>
</template>
<script>
import errorHandle from "@/components/ui/errorHandle.vue";
import phone from "@/components/ui/phone.vue";

import { mapActions, mapGetters } from "vuex";
import { domains } from "@/environment";
import moment from "moment";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  maxLength,
  minLength,
  alphaNum,
  numeric,
  email,
} from "@vuelidate/validators";

export default {
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  components: {
    errorHandle,
    phone,
  },
  data() {
    return {
      search: "",
      page: 1,
      perPage: 10,
      entrepriseToConfig: {
        pays: { iso_code: null },
        phone: {
          phone_number: null,
          phone_iso_code: null,
        },
      },
      entrepriseToDelete: null,
      entDetail: null,
      newentreprise: {
        logo: null,
        lib: null,
        email: null,
        rue: null,
        cp: null,
        pays: { iso_code: null },
        ville: null,
        phone: {
          phone_number: null,
          phone_iso_code: null,
        },
        SWIFT_BIC: null,
        IBAN: null,
        siret: null,
        num_tva: null,
        titulaire_compte: null,
        rcs: null,
        nom_assurance: null,
        num_assurance: null,
      },
      erreurlist: {
        lib: null,
        email: null,
        rue: null,
        ville: null,
        pays: null,
        cp: null,
        tel: null,
        rcs: null,
        nom_assurance: null,
        num_assurance: null,
        SWIFT_BIC: null,
        IBAN: null,
        siret: null,
        num_tva: null,
        titulaire_compte: null,
        numero_rge: null,
      },
      previewNewImage: null,
      previewImage: null,
      downloadUrl: domains.download,
      options: [
        { value: "type.particulier", text: "particulier" },
        { value: "type.professionnel", text: "professionnel" },
      ],
      errorD: null,
      errorS: null,
      errorU: null,
      phoneValid: null,
      resultValid: null,
    };
  },
  validations() {
    return {
      newentreprise: {
        lib: { required, maxLength: maxLength(100) },
        nom_assurance: { maxLength: maxLength(100) },
        num_assurance: { maxLength: maxLength(40) },
        email: { required, email, maxLength: maxLength(50) },
        rue: { required, maxLength: maxLength(45) },
        cp: {
          required,
          numeric,
          minLength: minLength(4),
          maxLength: maxLength(5),
        },
        pays: { required },
        ville: { required, maxLength: maxLength(40) },
        phone: {
          phone_number: { required },
        },
        titulaire_compte: { maxLength: maxLength(40) },
        SWIFT_BIC: {
          required,
          minLength: minLength(8),
          maxLength: maxLength(11),
          alphaNum,
        },
        IBAN: { required, maxLength: maxLength(34), alphaNum },
        num_tva: { required, maxLength: maxLength(30), alphaNum },
        siret: {
          required,
          minLength: minLength(9),
          maxLength: maxLength(14),
          alphaNum,
        },
        rcs: { required, maxLength: maxLength(50) },
      },
      entrepriseToConfig: {
        lib: { required, maxLength: maxLength(100) },
        nom_assurance: { maxLength: maxLength(100) },
        num_assurance: { maxLength: maxLength(40) },
        rue: { required, maxLength: maxLength(45) },
        cp: {
          required,
          numeric,
          minLength: minLength(4),
          maxLength: maxLength(5),
        },
        pays: { required },
        ville: { required, maxLength: maxLength(40) },
        phone: {
          phone_number: { required },
        },
        titulaire_compte: { maxLength: maxLength(40) },
        SWIFT_BIC: {
          required,
          maxLength: minLength(8),
          minLength: maxLength(11),
          alphaNum,
        },
        IBAN: { required, maxLength: maxLength(34), alphaNum },
        num_tva: { required, maxLength: maxLength(30), alphaNum },
        siret: {
          required,
          minLength: minLength(9),
          maxLength: maxLength(14),
          alphaNum,
        },
        rcs: { required, maxLength: maxLength(50) },
      },

      $validationGroups: {
        updateGroup: ["entrepriseToConfig"],
      },
    };
  },
  methods: {
    ...mapActions([
      "all_entreprises",
      "store_entreprise",
      "all_countries",
      "pays",
    ]),

    validateState(name) {
      const { $dirty, $error } = this.v$.newentreprise[name];
      return $dirty ? !$error : null;
    },

    validateUpdateState(name) {
      const { $dirty, $error } = this.v$.entrepriseToConfig[name];
      return $dirty ? !$error : null;
    },

    formatNumber(event) {
      this.newentreprise.phone = {
        phone_number: event.number,
        phone_iso_code: event.country.iso2,
      };
      this.resultValid = event.isValid;
    },

    formatNumberE(event) {
      this.entrepriseToConfig.phone = {
        phone_number: event.number,
        phone_iso_code: event.country.iso2,
      };
      this.phoneValid = event.isValid;
    },

    chargeNewImg(e) {
      const file = e.target.files[0];
      this.previewNewImage = URL.createObjectURL(file);
    },
  

    moment: function (date) {
      return moment(new Date(date)).locale(this.getSelectedLang.iso_code);
    },

    resetModal() {
      this.newentreprise = {
        lib: null,
        email: null,
        rue: null,
        cp: null,
        pays: { iso_code: null },
        ville: null,
        tel: null,
        phone: {
          phone_number: null,
          phone_iso_code: null,
        },
        SWIFT_BIC: null,
        IBAN: null,
        siret: null,
        num_tva: null,
        titulaire_compte: null,
      };
      this.erreurlist = {
        lib: null,
        email: null,
        rue: null,
        ville: null,
        pays: null,
        cp: null,
        tel: null,
        rcs: null,
        nom_assurance: null,
        num_assurance: null,
        SWIFT_BIC: null,
        IBAN: null,
        siret: null,
        num_tva: null,
        titulaire_compte: null,
        numero_rge: null,
      };
      this.errorS = null;
      this.errorU = null;
      this.$refs["ModelConfigentreprise"].hide();
      this.$refs["entrepriseModal"].hide();
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },

  
    async addCLI() {
      await this.v$.$validate();
      const isFormCorrect = await this.v$.newentreprise.$error;
      if (isFormCorrect) return;
      if (this.resultValid == false) return;
      this.erreurlist = {
        lib: null,
        email: null,
        rue: null,
        ville: null,
        pays: null,
        cp: null,
        tel: null,
        rcs: null,
        nom_assurance: null,
        num_assurance: null,
        SWIFT_BIC: null,
        IBAN: null,
        siret: null,
        num_tva: null,
        titulaire_compte: null,
        numero_rge: null,
      };
      this.errorS = null;
      var bodyFormData = new FormData();
      if (this.newentreprise.logo) {
        bodyFormData.append("logo", this.newentreprise.logo);
      }
      bodyFormData.append("lib", this.newentreprise.lib);
      bodyFormData.append("email", this.newentreprise.email);
      bodyFormData.append("rue", this.newentreprise.rue);
      bodyFormData.append("cp", this.newentreprise.cp);
      bodyFormData.append("pays", this.newentreprise.pays.iso_code);
      bodyFormData.append("ville", this.newentreprise.ville);

      // Format numero tel
      var num = this.newentreprise.phone.phone_number.indexOf(" ");
      this.newentreprise.phone = {
        phone_number: this.newentreprise.phone.phone_number
          .slice(num + 1)
          .split(" ")
          .join(""),
        phone_iso_code: this.newentreprise.phone.phone_iso_code,
      };

      bodyFormData.append("tel", this.newentreprise.phone.phone_number);
      bodyFormData.append(
        "phone_number",
        this.newentreprise.phone.phone_number
      );
      bodyFormData.append(
        "phone_iso_code",
        this.newentreprise.phone.phone_iso_code
      );
      bodyFormData.append("SWIFT_BIC", this.newentreprise.SWIFT_BIC);
      bodyFormData.append("IBAN", this.newentreprise.IBAN);
      bodyFormData.append("siret", this.newentreprise.siret);
      bodyFormData.append("num_tva", this.newentreprise.num_tva);
      bodyFormData.append("rcs", this.newentreprise.rcs);
      bodyFormData.append("nom_assurance", this.newentreprise.nom_assurance);
      bodyFormData.append("num_assurance", this.newentreprise.num_assurance);
      bodyFormData.append(
        "titulaire_compte",
        this.newentreprise.titulaire_compte
      );
      await this.store_entreprise(bodyFormData)
        .then(() => {
          this.errorS = null;
          this.hideModal("entrepriseModal");
          this.resetModal();
          this.setup(true);
        })
        .catch((err) => {
          if (this.isObject(err)) {
            for (const [key, value] of Object.entries(err)) {
              if (!this.erreurlist[key]) {
                this.erreurlist[key] = value;
              }
            }
          } else {
            this.errorS = err;
          }
        });
    },
    isObject(obj) {
      return Object.prototype.toString.call(obj) === "[object Object]";
    },
 

    setup(refresh = false) {
      if (refresh) this.page = 1;
      this.search = null;
      if (this.isSuperAdmin)
        this.all_entreprises({
          page: this.page,
          per_page: this.perPage,
        });
    },
  },
  mounted() {
    this.setup(true);
    this.all_countries({ page: this.page, perPage: 1000 });
  },

  computed: {
    ...mapGetters([
      "getentrepriseLoading",
      "getOnlineUser",
      "getSelectedLang",
      "getAllcountries",
    ]),
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },



  },
};
</script>
<style lang="scss" scoped>
.content {
  .content-header {
    .affichage {
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 2px solid #00000019;

      .font_icon {
        cursor: pointer;
        margin: 0 4px;
        border: 2px solid #000;
        padding: 2px 8px;
        border-radius: 50%;

        .icon_down,
        .icon_up {
          @media only screen and (max-width: 700px) {
            font-size: 14px;
          }
          font-size: 16px;
          font-weight: 500;
        }

        .icon_down {
          color: #28367a;
        }

        .icon_up {
          color: #e4261b;
        }
      }

      .up {
        border-color: #e4261b;
      }

      .down {
        border-color: #28367a;
      }
    }
  }

  & p {
    font-size: 12px;
    font-weight: 500;
    padding: 3px 0;
    margin: 0;
  }
}

.w-100 {
  background-color: #f8f8f8;
  padding: 4px;
  overflow-x: auto;
}

body {
  background: #fafdff;
}

.filtre {
  display: flex;
  align-items: center;

  .table-count,
  .productTrie {
    margin: 0 15px;
  }

  .productTrie {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 11px;
    overflow: hidden;
    padding: 4px 8px;
    border-radius: 32px;
    background-color: #34c38f;
    color: #fff;

    .icons {
      cursor: pointer;
      width: 18px;
      height: 18px;
      margin-left: 4px;
    }
  }
}

.logotab {
  width: 48px;
  height: 48px;
  border-radius: 50px;
  margin: 0 auto;
  .photo {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
.Entrephoto {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #28367a;
  margin: 0 auto;
  color: #fff;
  font-size: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.newlog {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 91px;
  height: 91px;

  .imgnew {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #e9ecef;
  }
}
.titresociete {
  color: #334081;
  width: 100%;
  margin-top: 4px;
}
</style>