<template>
  <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <div class="page-header">
      <h1>
        <span v-if="query && getentreprise">{{ getentreprise.lib }} /</span>
        {{ $t("USER") }}
      </h1>
      <b-button
        v-if="isSuperAdmin || isentrepriseAdmin || isAssistant"
        size="sm"
        variant="success"
        class="button-succes-style ml-2 float-right mb-2"
        @click="handleStore"
        ><font-awesome-icon icon="plus-circle" /> {{ $t("NEW") }}
      </b-button>
      <div class="total" v-else>{{ getTotalRowuser }} {{ $t("USER") }}</div>
    </div>

    <storeModalVue @updateList="setup(true)"></storeModalVue>

    <div class="content">
      <div class="content-header">
        <searchBar
          :searchText="searchBarText"
          :searchFunc="all_users"
          :filtre="{
            entreprise_id: getentreprise.id,
            with_all_users: 1,
          }"
          :showFiltre="true"
          @returnValue="updateSearch($event)"
        ></searchBar>
        <!-- ------------------------------------------------------------------------------modale-entreprise------------------------------------------------------------------------------------------>
        <b-modal id="entrepriseModal" ref="entrepriseModal">
          <template #modal-header="{ close }">
            <h5>{{ $t("USER") }}</h5>
            <b-button size="sm" @click="close()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.028"
                height="17.028"
                viewBox="0 0 17.028 17.028"
              >
                <path
                  id="Icon_material-close"
                  data-name="Icon material-close"
                  d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                  transform="translate(-7.5 -7.5)"
                  fill="#393939"
                />
              </svg>
            </b-button>
          </template>

          <form @submit.prevent="selectFiltre()">
            <div class="center">
              <b-form-group :label="$t('ROLE')">
                <b-form-select
                  v-model="currentrole"
                  :options="optionsuper"
                  id="type"
                ></b-form-select>
              </b-form-group>
            </div>
          </form>
          <template #modal-footer>
            <div class="actionModel">
              <b-button variant="success" @click="selectFiltre">
                <div class="block-spinner">
                  {{ $t("CONFIRM") }}
                  <div v-if="getOnlineUserLoading" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </div>
              </b-button>
            </div>
          </template>
        </b-modal>
        <!--------------------------------------------------------------------------------------------------modale-entreprise------------------------------------------------------------------------------------------>
      </div>
      <div class="filtre">
        <p class="table-count" v-if="!isCommercialBatigo">
          {{ getTotalRowuser }} {{ $t("USER") }}
        </p>

        <div class="productTrie" v-if="currententreprise.lib != null">
          {{ currententreprise.lib }}
          <font-awesome-icon
            icon="times-circle"
            class="icons"
            @click="selectentreprise()"
          />
        </div>

        <div class="productTrie" v-if="currentrole">
          {{ currentrole }}
          <font-awesome-icon
            icon="times-circle"
            class="icons"
            @click="resetRole()"
          />
        </div>
      </div>
      <div class="table-rapport-style w-100">
        <table>
          <thead>
            <tr>
              <th style="border-radius: 0px 5px 0px 0px">
                {{ $t("NAME") }}
              </th>
              <th>
                {{ $t("EMAIL") }}
              </th>
              <th>
                {{ $t("SOCIETY") }}
              </th>
              <th>
                {{ $t("ROLE") }}
              </th>
              <th>
                {{ $t("PHONE") }}
              </th>
              <th>
                {{ $t("LAST_LOGIN") }}
              </th>
              <th style="border-radius: 0px 5px 0px 0px">
                {{ $t("ACTIVE") }}
              </th>
              <th class="action_head"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              role="row"
              class="ligneNormale"
              v-for="(data, i) in computedGetAllUsers"
              :key="i"
              @click.prevent.stop="handleUser(data)"
            >
              <td aria-colindex="1" role="cell">
                {{ data.prenom }} {{ data.nom }}
              </td>
              <td aria-colindex="3" role="cell">
                {{ data.email_u }}
              </td>
              <td aria-colindex="3" role="cell" v-if="data.entreprise">
                {{ data.entreprise.lib }}
              </td>
              <td aria-colindex="3" role="cell" v-else></td>
              <td aria-colindex="4" role="cell">
                <span>{{ data.role.slice(+5) }}</span>
              </td>
              <td aria-colindex="5" role="cell">
                {{ data.phone ? data.phone.formated_phone : "-" }}
              </td>
              <td aria-colindex="5" role="cell" v-if="data.last_login">
                {{ moment(data.last_login).format("DD-MM-YYYY hh:mm:ss") }}
              </td>
              <td v-else>-</td>
              <td aria-colindex="6" role="cell" v-if="data.active">
                <font-awesome-icon
                  icon="fa-solid fa-check"
                  style="color: #3dd598; font-size: 18px"
                />
              </td>
              <td aria-colindex="6" role="cell" v-else>
                <font-awesome-icon
                  icon="times"
                  style="color: #e4261b; font-size: 18px"
                />
              </td>

              <td aria-colindex="9" role="cell" class="action_col">
                <b-button
                  class="button-succes-style"
                  size="sm"
                  variant="success"
                  @click.prevent.stop="handleUpdate(data)"
                >
                  <font-awesome-icon icon="pencil-alt" />
                </b-button>
                <b-button
                  v-if="data.role != 'user.entreprise-admin' || isSuperAdmin"
                  class="button-succes-style"
                  size="sm"
                  variant="danger"
                  @click.prevent.stop="handleDelete(data)"
                >
                  <font-awesome-icon icon="trash" />
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <b-modal id="Modeluser" ref="Modeluser" scrollable>
        <template #modal-header="{ close }">
          <h5>{{ $t("USER") }}</h5>
          <b-button size="sm" @click="close()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.028"
              height="17.028"
              viewBox="0 0 17.028 17.028"
            >
              <path
                id="Icon_material-close"
                data-name="Icon material-close"
                d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                transform="translate(-7.5 -7.5)"
                fill="#393939"
              />
            </svg>
          </b-button>
        </template>

        <div class="detailClient">
          <div class="clientTop">
            <div class="image">
              <div class="profil">
                <span v-if="userDetail.nom">{{ userDetail.nom[0] }}</span>
                <span v-if="userDetail.prenom">{{ userDetail.prenom[0] }}</span>
              </div>
            </div>
            <div class="right">
              <div>
                <h4>{{ $t("SURNAME") }}</h4>
                <p>{{ userDetail.prenom }}</p>
              </div>
              <div>
                <h4>{{ $t("NAME") }}</h4>
                <p>{{ userDetail.nom }}</p>
              </div>
              <div>
                <h4>{{ $t("ROLE") }}</h4>
                <p v-if="userDetail.role">{{ userDetail.role.slice(5) }}</p>
              </div>
            </div>
          </div>
          <div class="clientMid">
            <div>
              <h4>{{ $t("EMAIL") }}</h4>
              <p>{{ userDetail.email_u }}</p>
            </div>
            <div>
              <h4>{{ $t("PHONE") }}</h4>
              <p>
                {{ userDetail.phone ? userDetail.phone.formated_phone : "-" }}
              </p>
            </div>
            <div>
              <h4>{{ $t("SOCIETY") }}</h4>
              <p v-if="userDetail.entreprise">
                {{ userDetail.entreprise.lib }}
              </p>
            </div>
          </div>
        </div>

        <template #modal-footer>
          <div class="double">
            <b-button
              v-if="
                isentrepriseAdmin &&
                userToConfig.role != 'user.entreprise-admin'
              "
              variant="danger"
              @click.prevent.stop="handleDelete(userDetail)"
            >
              <div class="block-spinner">
                {{ $t("DELETE") }}
              </div>
            </b-button>

            <b-button
              variant="success"
              @click.prevent.stop="handleUpdate(userDetail)"
            >
              <div class="block-spinner">
                {{ $t("EDIT") }}
              </div>
            </b-button>
          </div>
        </template>
      </b-modal>

      <b-modal
        id="ModelConfiguser"
        ref="ModelConfiguser"
        scrollable
        @hide="resetmodel()"
      >
        <template #modal-header>
          <h5>{{ $t("EDIT") }}</h5>
          <b-button size="sm" @click="resetmodel()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.028"
              height="17.028"
              viewBox="0 0 17.028 17.028"
            >
              <path
                id="Icon_material-close"
                data-name="Icon material-close"
                d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                transform="translate(-7.5 -7.5)"
                fill="#393939"
              />
            </svg>
          </b-button>
        </template>

        <form @submit.prevent="userModalNew" v-if="userToConfig">
          <div class="center">
            <b-form-group :label="$t('SURNAME')">
              <b-form-input
                v-model="v$.userToConfig.prenom.$model"
                :state="validateState('prenom')"
                oninput="this.value = this.value.toUpperCase()"
                aria-describedby="prenom-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.userToConfig.prenom.$errors"
                id="prenom-feedback"
              ></error-handle>
              <div v-if="erreurlist.prenom" class="error-message">
                <ul v-if="Array.isArray(erreurlist.prenom)">
                  <span v-for="(e, index) in erreurlist.prenom" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.prenom }}</span>
              </div>
            </b-form-group>

            <b-form-group :label="$t('NAME')">
              <b-form-input
                v-model="v$.userToConfig.nom.$model"
                :state="validateState('nom')"
                oninput="this.value = this.value.toUpperCase()"
                aria-describedby="nom-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.userToConfig.nom.$errors"
                id="nom-feedback"
              ></error-handle>
              <div v-if="erreurlist.nom" class="error-message">
                <ul v-if="Array.isArray(erreurlist.nom)">
                  <span v-for="(e, index) in erreurlist.nom" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.nom }}</span>
              </div>
            </b-form-group>

            <b-form-group :label="$t('PHONE')">
              <template>
                <phone
                  :tel="userToConfig.phone"
                  @phoneNumber="formatNumber($event)"
                ></phone>
              </template>
              <div v-if="v$.userToConfig.phone.$error" class="error-message">
                {{ $t("REQUIRED_FIELD") }}
              </div>
              <div
                v-if="userToConfig.phone && resultValid == false"
                class="error-message"
              >
                {{ $t("ERROR_PHONE") }}
              </div>
            </b-form-group>
            <b-form-group
              :label="$t('ROLE')"
              v-if="
                entrepriseAdminNumber > 1 ||
                (entrepriseAdminNumber < 2 &&
                  userToConfig.role != 'user.entreprise-admin')
              "
            >
              <b-form-select
                v-model="userToConfig.role"
                :options="optionsuper"
                id="type"
                @change="verif"
              ></b-form-select>
              <div v-if="erreurlist.role" class="error-message">
                <ul v-if="Array.isArray(erreurlist.role)">
                  <span v-for="(e, index) in erreurlist.role" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.role }}</span>
              </div>
            </b-form-group>

            <b-form-group :label="$t('PASSWORD')">
              <b-form-input
                autocomplete="off"
                v-model="v$.userToConfig.password.$model"
                type="password"
                minlength="5"
                :state="validateState('password')"
                aria-describedby="password-feedback"
                @input="formatPass"
              ></b-form-input>
              <error-handle
                :list="v$.userToConfig.password.$errors"
                id="password-feedback"
              ></error-handle>
              <div v-if="erreurlist.password" class="error-message">
                <ul v-if="Array.isArray(erreurlist.password)">
                  <span v-for="(e, index) in erreurlist.password" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.password }}</span>
              </div>
            </b-form-group>
            <b-form-group :label="$t('PASSWORD_CONFIRM')">
              <b-form-input
                id="password_confirmation"
                autocomplete="off"
                v-model="userToConfig.password_confirmation"
                type="password"
                :state="validateState('password_confirmation')"
                aria-describedby="password_confirmation-feedback"
                @input="formatPass"
              ></b-form-input>
              <error-handle
                :list="v$.userToConfig.password_confirmation.$errors"
                id="password_confirmation-feedback"
              ></error-handle>
              <div
                v-if="erreurlist.password_confirmation"
                class="error-message"
              >
                <ul v-if="Array.isArray(erreurlist.password_confirmation)">
                  <span
                    v-for="(e, index) in erreurlist.password_confirmation"
                    :key="index"
                  >
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.password_confirmation }}</span>
              </div>
            </b-form-group>
            <b-form-checkbox
              style="z-index: 0"
              id="checkbox-1"
              v-model="userToConfig.active"
              name="checkbox-1"
              value="1"
              unchecked-value="0"
              v-if="
                isSuperAdmin ||
                (isentrepriseAdmin && getOnlineUser.id != userToConfig.id)
              "
            >
              {{ $t("ACTIVE") }}
            </b-form-checkbox>
          </div>
        </form>
        <template #modal-footer>
          <b-alert variant="warning" show v-if="errorU">
            {{ errorU }}
          </b-alert>
          <div class="double">
            <b-button variant="danger" @click="resetmodel()">
              <div class="block-spinner">
                {{ $t("CANCEL") }}
              </div>
            </b-button>
            <b-button variant="success" @click="userModalNew">
              <div class="block-spinner">
                {{ $t("EDIT") }}
                <div v-if="getOnlineUserLoading" class="loading ml-2">
                  <div class="spinner-border" role="status"></div>
                </div>
              </div>
            </b-button>
          </div>
        </template>
      </b-modal>

      <deleteModalVue
        :loader="getOnlineUserLoading"
        :elemToDelete="userToDelete"
        :elemDelete="delete_user"
        @updateList="setup(false)"
      ></deleteModalVue>
      <b-pagination
        v-if="search == ''"
        v-model="page"
        :total-rows="getTotalRowuser"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        last-number
        first-number
        align="center"
        size="sm"
        @change="pagination"
        class=""
      ></b-pagination>
      <b-pagination
        v-else
        v-model="page"
        :total-rows="getTotalRowuser"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        last-number
        first-number
        align="center"
        size="sm"
        @change="pagination"
        class=""
      ></b-pagination>
    </div>

    <div class="text-center center-spin">
      <b-spinner
        v-if="getOnlineUserLoading"
        variant="primary"
        label="Spinning"
        class="big-spin"
      ></b-spinner>
    </div>
    <CommentMarche to="/how-it-work/utilisateur"></CommentMarche>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import moment from "moment";
import { useVuelidate } from "@vuelidate/core";
import deleteModalVue from "../components/ui/deleteModal.vue";
import storeModalVue from "../components/Ajoutmodel/usermodale.vue";
import searchBar from "../components/ui/searchBar.vue";
import {
  required,
  minLength,
  maxLength,
  email,
  requiredIf,
  sameAs,
} from "@vuelidate/validators";
import errorHandle from "../components/ui/errorHandle.vue";
import phone from "../components/ui/phone.vue";
import CommentMarche from "../components/commentMarche.vue";

export default {
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  components: {
    searchBar,
    deleteModalVue,
    storeModalVue,
    errorHandle,
    phone,
    CommentMarche,
  },
  data() {
    return {
      search: "",
      searchE: "",
      BlockE: false,
      BlockR: false,
      userToConfig: {
        password: "",
        phone: {
          phone_number: null,
          phone_iso_code: null,
        },
      },
      userToDelete: null,
      userDetail: {
        nom: null,
        prenom: null,
        phone: null,
        email_u: null,
        role: null,
      },
      erreurlist: {
        nom: null,
        prenom: null,
        password: null,
        password_confirmation: null,
        tel_u: null,
        role: null,
      },
      page: 1,
      perPage: 8,
      options: [
        { value: "user.assistant", text: "Assistant" },
        { value: "user.technicien", text: "Technicien" },
        { value: "user.comptable", text: "Comptable" },
        { value: "user.commercial-entreprise", text: "Commercial" },
      ],
      optionsuper: [
        { value: "user.entreprise-admin", text: "Entreprise Admin" },
        { value: "user.assistant", text: "Assistant" },
        { value: "user.technicien", text: "Technicien" },
        { value: "user.comptable", text: "Comptable" },
        { value: "user.commercial-entreprise", text: "Commercial" },
      ],
      entrepriseAdminNumber: 0,
      currententreprise: {
        id: null,
      },
      currentrole: "",
      errorD: null,
      errorU: null,
      resultValid: null,
      vueTel: {
        preferredCountries: ["FR", "TN"],
        placeholder: "Enter your phone",
        mode: "auto",
        inputOptions: {
          showDialCode: true,
        },
        disabledFormatting: false,
        validCharactersOnly: true,
        wrapperClasses: "country-phone-input",
      },
    };
  },
  validations() {
    return {
      userToConfig: {
        nom: { required, maxLength: maxLength(100) },
        prenom: { required, maxLength: maxLength(100) },
        email_u: { required, email, maxLength: maxLength(50) },
        phone: {
          phone_number: { required },
        },
        password: { minLength: minLength(6) },
        password_confirmation: {
          sameAs: sameAs(this.userToConfig.password),
          requiredIf: requiredIf(this.userToConfig.password),
        },
      },
    };
  },
  methods: {
    ...mapActions([
      "delete_user",
      "all_users",
      "updateUsers",
      "all_entreprises",
      "all_clients",
      "pays",
      "visibilite",
      "entreprise",
      "entrepriseAdmin",
    ]),

    validateState(name) {
      const { $dirty, $error } = this.v$.userToConfig[name];
      return $dirty ? !$error : null;
    },

    formatPass() {
      if (
        (this.userToConfig.password == "" &&
          !this.userToConfig.password_confirmation) ||
        (this.userToConfig.password_confirmation == "" &&
          !this.userToConfig.password)
      ) {
        this.userToConfig.password = null;
        this.userToConfig.password_confirmation = null;
      }
    },

    updateSearch(s) {
      this.page = 1;
      this.search = s;
    },

    moment: function (date) {
      return moment(new Date(date)).locale(this.getSelectedLang.iso_code);
    },
    resetmodel() {
      this.errorU = null;
      this.hideModal("ModelConfiguser");
      this.userToConfig = {
        password: "",
        phone: {
          phone_number: null,
          phone_iso_code: null,
        },
      };

      this.$nextTick(() => {
        this.v$.$reset();
      });
      this.erreurlist = {
        nom: null,
        prenom: null,
        password: null,
        password_confirmation: null,
        tel_u: {
          phone_number: null,
          phone_iso_code: null,
        },
        ville: null,
        adresse: null,
        cp: null,
        role: null,
        entreprise_id: null,
      };
    },
    isObject(obj) {
      return Object.prototype.toString.call(obj) === "[object Object]";
    },
    async searchuser() {
      await this.all_users({
        search: this.search,
        page: this.page,
        per_page: this.perPage,
        entreprise_id: this.getentreprise ? this.getentreprise.id : "",
        with_all_users: 1,
      });
    },

    verif(role) {
      if (role == "user.entreprise-admin" && this.entrepriseAdminNumber < 2)
        this.entrepriseAdminNumber++;
    },
    hideModal(ref) {
      this.$refs[ref].hide();
    },
    handleUpdate(CAT) {
      this.userToConfig = { ...CAT };
      this.$refs["ModelConfiguser"].show();
    },
    handleUser(CAT) {
      this.userDetail = { ...CAT };
      this.$refs["Modeluser"].show();
    },

    handleDelete(CAT) {
      this.userToDelete = { ...CAT };
      this.$root.$emit("bv::show::modal", "ModelDelete");
    },

    async userModalNew() {
      if (this.userToConfig.password == "")
        this.userToConfig.password_confirmation = "";
      const isFormCorrect = await this.v$.userToConfig.$error;
      if (isFormCorrect) return;
      if (this.resultValid == false) {
        return;
      }
      this.erreurlist = {
        nom: null,
        prenom: null,
        password: null,
        password_confirmation: null,
        tel_u: null,
        role: null,
      };
      this.errorU = null;
      var data = {
        id: this.userToConfig.id,
        nom: this.userToConfig.nom,
        prenom: this.userToConfig.prenom,
        active: this.userToConfig.active,
      };
      var num = this.userToConfig.phone.phone_number.indexOf(" ");
      data.phone = {
        phone_number: this.userToConfig.phone.phone_number
          .slice(num + 1)
          .split(" ")
          .join(""),
        phone_iso_code: this.userToConfig.phone.phone_iso_code,
      };
      if (this.userToConfig.id != this.getOnlineUser.id) {
        data.role = this.userToConfig.role;
      }
      if (this.userToConfig.password) {
        data.password = this.userToConfig.password;
      }
      if (this.userToConfig.password_confirmation) {
        data.password_confirmation = this.userToConfig.password_confirmation;
      }
      await this.updateUsers(data)
        .then(() => {
          if (this.userToConfig.role == "user.commercial-batigo")
            this.visibiliteCommercial(this.userToConfig);

          this.all_users({
            page: this.page,
            per_page: this.perPage,
            entreprise_id: this.$route.query.entreprise,
            with_all_users: 1,
          });
          this.hideModal("ModelConfiguser");
          this.hideModal("Modeluser");
          this.errorU = null;
        })
        .catch((err) => {
          if (this.isObject(err)) {
            for (const [key, value] of Object.entries(err)) {
              if (!this.erreurlist[key]) {
                this.erreurlist[key] = value;
              }
            }
          } else {
            this.errorU = err;
          }
        });
    },

    visibiliteCommercial(user) {
      var entreprise_id = [];
      user.visibility.forEach((element) => {
        entreprise_id.push(element.id);
      });
      this.visibilite({
        user_id: user.id,
        entreprise_id: entreprise_id,
      });
    },

    formatNumber(event) {
      this.userToConfig.phone = {
        phone_number: event.number,
        phone_iso_code: event.country.iso2,
      };
      this.resultValid = event.isValid;
    },

    handleStore() {
      this.$root.$emit("bv::show::modal", "userModal");
    },

    pagination(paginate) {
      if (this.search === "") {
        this.page = paginate;
        this.all_users({
          page: this.page,
          per_page: this.perPage,
          entreprise_id: this.$route.query.entreprise,
          with_all_users: 1,
        });
      } else {
        this.page = paginate;
        this.searchuser();
      }
    },

    selectFiltre() {
      if (this.currententreprise.lib || this.currentrole) {
        if (this.currentrole == "") {
          this.currentrole = null;
        }
        this.all_users({
          page: this.page,
          per_page: this.perPage,
          entreprise_id: this.$route.query.entreprise,
          role: this.currentrole,
          with_all_users: 1,
        });
      } else {
        this.all_users({
          page: this.page,
          per_page: this.perPage,
          entreprise_id: this.$route.query.entreprise,
          with_all_users: 1,
        });
      }

      this.hideModal("entrepriseModal");
    },
    resetRole() {
      this.currentrole = null;
      this.selectFiltre();
    },

    selectentreprise(data) {
      if (data) {
        this.currententreprise = data;
        this.searchE = data.lib;
      } else {
        this.searchE = null;
        this.currententreprise = {
          lib: null,
        };
      }
      this.BlockE = false;
    },

    setup(refresh = false) {
      if (refresh) this.page = 1;
      var data = {};
      if (this.isentrepriseAdmin || this.isAssistant)
        data = this.getOnlineUser.entreprise.id;
      else data = this.$route.query.entreprise;

      this.entrepriseAdmin(data).then((res) => {
        this.entrepriseAdminNumber = res;
      });
      this.$refs["Modeluser"].hide();
      this.search = null;
      if (this.$route.query.entreprise) {
        this.entreprise({ id: this.$route.query.entreprise });
        this.all_users({
          page: this.page,
          per_page: this.perPage,
          entreprise_id: this.$route.query.entreprise,
          with_all_users: 1,
        });
      } else {
        this.all_users({
          page: this.page,
          per_page: this.perPage,
          with_all_users: 1,
        });
      }
    },
  },

  mounted() {
    this.setup(true);

    if (this.isSuperAdmin) {
      this.all_entreprises({
        page: this.page,
        per_page: this.perPage,
      });
    }
    var lang;

    switch (this.getSelectedLang.iso_code) {
      case "fr":
        lang = "fr";
        break;
      case "prt":
        lang = "pt";
        break;
      case "IT":
        lang = "it";
        break;
      case "ar":
        lang = "fa";
        break;
      case "Gr":
        lang = "de";
        break;
      default:
        lang = "en";
    }
    this.pays(lang);
  },

  computed: {
    ...mapGetters([
      "getOnlineUserLoading",
      "getTotalRowuser",
      "getTabs",
      "getAllUsers",
      "getOnlineUser",
      "getAllentreprises",
      "getPays",
      "getSelectedLang",
      "getdictionnaire",
      "getentreprise",
    ]),
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },
    isAssistant() {
      return this.getOnlineUser.role == "user.assistant";
    },
    isCommercialBatigo() {
      return this.getOnlineUser.role == "user.commercial-batigo";
    },

    query() {
      return this.$route.query.entreprise;
    },

    computedGetAllUsers() {
      var visible = [];
      var result = [];
      if (this.getAllUsers) {
        this.getAllUsers.forEach((usr) => {
          if (usr.visibility && this.isSuperAdmin) {
            usr.visibility.forEach((vis) => {
              this.getAllentreprises.forEach((ent) => {
                if (vis === ent.id) {
                  visible.push(ent);
                }
              });
              usr.visibility = visible;
            });
          }
          result.push(usr);
          visible = [];
        });
      }
      return result;
    },
    searchBarText() {
      return `${this.$t("USER_SEARCH")} ( ${this.$t("NAME")}, ${this.$t(
        "SURNAME"
      )}, ${this.$t("EMAIL")} )`;
    },
  },
};
</script>

<style lang="scss" scoped>
.input-group .icon-balance {
  top: 8px;
}

.content {
  & p {
    font-size: 12px;
    font-weight: 500;
    padding: 3px 0;
    margin: 0;
  }
}

.w-100 {
  background-color: #f8f8f8;
  padding: 4px;
  overflow-x: auto;
}

body {
  background: #fafdff;
}

.actionModel {
  position: relative;
  padding: 15px 0 0;

  .messageError {
    position: absolute;
    top: 0;
    color: #e4261b;
    margin: 0;
    font-size: 12px;
  }
  & .block-spinner {
    display: flex;
    justify-content: center;
  }

  & .spinner-border {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.detailClient {
  background-color: #f5f6f8;
  border-radius: 5px;

  .clientTop {
    display: flex;
    justify-content: space-between;

    .profil {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 175px;
      height: 175px;
      border-radius: 50%;
      background-color: #28367a;
      color: #fff;
      font-size: 42px;
      text-transform: uppercase;
    }

    .image {
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 1px 1px 24px #00000019;
      background-color: #fff;
      border-radius: 5px;
      text-align: center;
      position: relative;
      width: 55%;

      .star {
        position: absolute;
        top: 4px;
        right: 4px;
        font-size: 24px;
        color: #ffc400;
      }
    }

    .right {
      padding: 8px;
      box-shadow: 1px 1px 24px #00000019;
      background-color: #fff;
      border-radius: 5px;
      width: 42%;
    }

    & .prodimg {
      @media only screen and (max-width: 900px) {
        max-width: 100px;
        max-height: 100px;
      }
      max-width: 185px;
      max-height: 231px;
    }
  }

  .clientMid {
    box-shadow: 1px 1px 24px #00000019;
    margin-top: 15px;
    border-radius: 5px;
    padding: 8px;
    background-color: #fff;
  }

  & h4 {
    color: #393939;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 4px;
  }

  & p {
    border: 1px solid #707070;
    border-radius: 5px;
    color: #393939;
    font-size: 14px;
    padding: 8px;
  }

  .desc {
    min-height: 120px;
    margin: 0;
  }
}

.body {
  padding: 15px;
  box-shadow: 1px 1px 24px #00000019;
  max-height: 60vh;
  overflow: auto;

  ul {
    list-style: none;
    & li {
      padding: 8px 0;
      cursor: pointer;
      background-color: #f8f8f8;
      margin-bottom: 4px;
      color: #000;
      border: none;
      box-shadow: none;
      transition: all 0.2s ease;
      text-align: center;

      &:hover {
        background-color: #28367a;
        color: #fff;
      }
    }
  }
}

.filtre {
  display: flex;
  align-items: center;

  .table-count,
  .productTrie {
    margin-right: 15px;
  }

  .productTrie {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    overflow: hidden;
    padding: 4px 8px;
    border-radius: 32px;
    background-color: #34c38f;
    color: #fff;

    .icons {
      cursor: pointer;
      width: 18px;
      height: 18px;
      margin-left: 4px;
    }
  }
}
</style>
