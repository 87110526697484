<template>
  <b-modal id="sousTraitantModal" ref="sousTraitantModal" @hide="close">
    <template #modal-header>
      <h5>{{ $t("NEW") }} {{ $t("SOUS-TRAITANT") }}</h5>
      <b-button size="sm" @click="resetModal()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17.028"
          height="17.028"
          viewBox="0 0 17.028 17.028"
        >
          <path
            id="Icon_material-close"
            data-name="Icon material-close"
            d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
            transform="translate(-7.5 -7.5)"
            fill="#393939"
          />
        </svg>
      </b-button>
    </template>

    <form @submit.prevent="addPRE">
      <div class="center">
        <b-form-group :label="$t('NAME') + '*'" label-for="name">
          <b-form-input
            id="name"
            v-model="v$.newSousTraitant.name.$model"
            :state="validateState('name')"
            oninput="this.value = this.value.toUpperCase()"
            aria-describedby="name-feedback"
          ></b-form-input>
          <error-handle
            :list="v$.newSousTraitant.name.$errors"
            id="name-feedback"
          ></error-handle>
          <div v-if="erreurlist.name" class="error-message">
            <ul v-if="Array.isArray(erreurlist.name)">
              <span v-for="(e, index) in erreurlist.name" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.name }}</span>
          </div>
        </b-form-group>

        <b-form-group :label="$t('SIRET') + '*'" label-for="num_siret">
          <b-form-input
            id="num_siret"
            v-model="v$.newSousTraitant.num_siret.$model"
            :state="validateState('num_siret')"
            oninput="this.value = this.value.toUpperCase()"
            aria-describedby="num_siret-feedback"
          ></b-form-input>
          <error-handle
            :list="v$.newSousTraitant.num_siret.$errors"
            id="num_siret-feedback"
          ></error-handle>
          <div v-if="erreurlist.num_siret" class="error-message">
            <ul v-if="Array.isArray(erreurlist.num_siret)">
              <span v-for="(e, index) in erreurlist.num_siret" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.num_siret }}</span>
          </div>
        </b-form-group>
      </div>
    </form>
    <template #modal-footer>
      <b-alert variant="warning" show v-if="errorS">
        {{ errorS }}
      </b-alert>
      <div class="double">
        <b-button variant="danger" @click="resetModal()">
          <div class="block-spinner">
            {{ $t("CANCEL") }}
          </div>
        </b-button>
        <b-button variant="success" @click="addPRE">
          <div class="block-spinner">
            {{ $t("SAVE") }}
            <div v-if="getLoadingsousTraitant" class="loading ml-2">
              <div class="spinner-border" role="status"></div>
            </div>
          </div>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { domains } from "@/environment";
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  minLength,
  maxLength,
  alphaNum,
} from "@vuelidate/validators";
import errorHandle from "../ui/errorHandle.vue";

export default {
  components: {
    errorHandle,
  },
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  data() {
    return {
      erreurlist: {
        name: null,
        num_siret: null,
        // num_rge: null,
      },
      newSousTraitant: {
        name: null,
        num_siret: null,
        // num_rge: null,
      },
      galleryUrl: domains.gallery,
      page: 1,
      per_page: 1000,
      errorS: false,
      box: "",
    };
  },
  validations() {
    return {
      newSousTraitant: {
        name: { required, maxLength: maxLength(100) },
        num_siret: {
          required,
          minLength: minLength(9),
          maxLength: maxLength(14),
          alphaNum,
        },
        // num_rge: { required, maxLength: maxLength(100) },
      },
    };
  },
  methods: {
    ...mapActions(["store_sousTraitant", "all_entreprises"]),

    validateState(name) {
      const { $dirty, $error } = this.v$.newSousTraitant[name];
      return $dirty ? !$error : null;
    },

    customLabel({ title }) {
      return `${title}`;
    },
    async addPRE() {
      const isFormCorrect = await this.v$.$validate();
      
      if (!isFormCorrect) return;
      this.errorS = null;
      this.erreurlist = {
        name: null,
        num_siret: null,
        // num_rge: null,
      };
      const data = {
        name: this.newSousTraitant.name,
        num_siret: this.newSousTraitant.num_siret,
        // num_rge: this.newSousTraitant.num_rge,
      };
      await this.store_sousTraitant(data)
        .then(() => {
          this.resetModal();
          this.$emit("updateList");
        })
        .catch((err) => {
          if (this.isObject(err)) {
            for (const [key, value] of Object.entries(err)) {
              if (!this.erreurlist[key]) {
                this.erreurlist[key] = value;
              }
            }
          } else {
            this.errorS = err;
          }
        });
    },
    isObject(obj) {
      return Object.prototype.toString.call(obj) === "[object Object]";
    },
    resetModal() {
      this.errorS = null;
      this.$refs["sousTraitantModal"].hide();
      this.newSousTraitant = {
        name: null,
        num_siret: null,
        // num_rge: null,
      };
      this.$nextTick(() => {
        this.v$.$reset();
      });
      this.erreurlist = {
        name: null,
        num_siret: null,
        // num_rge: null,
      };
    },

    close(bv) {
      if (this.$refs["sousTraitantModal"].isShow) {
        if (bv.trigger == "backdrop") {
          bv.preventDefault();
          this.showMsgBox();
        }
      }
    },

    showMsgBox() {
      this.box = "";
      this.$bvModal
        .msgBoxConfirm(`${this.$t("CLOSE")}`, {
          title: `${this.$t("CONFIRM")}`,
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          cancelVariant: "danger",
          okTitle: `${this.$t("YES")}`,
          cancelTitle: `${this.$t("NO")}`,
          footerClass: "p-2",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) {
            this.resetModal();
          }
        });
    },
  },
  mounted() {
    if (this.isSuperAdmin)
      if (!this.getAllentreprises) {
        this.all_entreprises({
          page: this.page,
          per_page: this.perPage,
          search: this.search,
        });
      }
  },

  computed: {
    ...mapGetters([
      "getAllentreprises",
      "getOnlineUser",
      "getLoadingsousTraitant",
    ]),
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    computedAllentreprises() {
      var list = [
        {
          lib: "TOUTES LES ENTREPRISES",
          id: 1,
        },
      ];
      if (this.getAllentreprises)
        this.getAllentreprises.forEach((element) => {
          if (element.id != 1) list.push(element);
        });
      return list;
    },
  },
};
</script>

<style lang="scss" scoped>
.option {
  display: flex;
  align-items: center;
  .option__image {
    background-color: #465081;
    padding: 4px;
    max-height: 42px;
    max-width: 42px;
  }
}
</style>
