<template>
    <b-modal :visible="show" @hide="close">
    <template #modal-header>
      <h5>{{ $t("EDIT") }} {{ $t("certificat_rge") }}</h5>
      <b-button size="sm" @click="close">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17.028"
          height="17.028"
          viewBox="0 0 17.028 17.028"
        >
          <path
            id="Icon_material-close"
            data-name="Icon material-close"
            d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
            transform="translate(-7.5 -7.5)"
            fill="#393939"
          />
        </svg>
      </b-button>
    </template>
    <form  @submit.prevent="editRGE">
      <div class="center">
        <b-form-group :label="$t('NUM_RGE') + '*'" label-for="num">
          <b-form-input
            id="num"
            v-model="v$.localUpdateData.num.$model"
            :state="validateState('num')"
            :placeholder="$t('NUM_RGE')"
            oninput="this.value = this.value.toUpperCase()"
            aria-describedby="name-feedback"
          ></b-form-input>
          <errorHandle 
            :list="v$.localUpdateData.num.$errors"
            id="name-feedback"
          ></errorHandle >
          <div v-if="erreurlist.num" class="error-message">
            <ul v-if="Array.isArray(erreurlist.num)">
              <span v-for="(e, index) in erreurlist.num" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.num }}</span>
          </div>
        </b-form-group>

        <b-form-group :label="$t('date_attribution') + '*'" label-for="date_attribution">
          <b-form-input
            id="date_attribution"
            v-model="v$.localUpdateData.date_attribution.$model"
            :state="validateState('date_attribution')"
            type="date"
            oninput="this.value = this.value.toUpperCase()"
            aria-describedby="date_attribution-feedback"
          ></b-form-input>
          <errorHandle 
            :list="v$.localUpdateData.date_attribution.$errors"
            id="date_attribution-feedback"
          ></errorHandle >
          <div v-if="erreurlist.date_attribution" class="error-message">
            <ul v-if="Array.isArray(erreurlist.date_attribution)">
              <span v-for="(e, index) in erreurlist.date_attribution" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.date_attribution }}</span>
          </div>
        </b-form-group>

        <b-form-group :label="$t('date_expiration') + '*'" label-for="date_expiration">
          <b-form-input
            id="date_expiration"
            v-model="v$.localUpdateData.date_expiration.$model"
            :state="validateState('date_expiration')"
            oninput="this.value = this.value.toUpperCase()"
            type="date"
            aria-describedby="num_rge-feedback"
          ></b-form-input>
          <errorHandle 
            :list="v$.localUpdateData.date_expiration.$errors"
            id="num_rge-feedback"
          ></errorHandle >
          <div v-if="erreurlist.date_expiration" class="error-message">
            <ul v-if="Array.isArray(erreurlist.date_expiration)">
              <span v-for="(e, index) in erreurlist.date_expiration" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.date_expiration }}</span>
          </div>
        </b-form-group>
        
        <b-form-group :label="$t('SOUS-TRAITANT')"  v-if="selecSousTraitant?.id">
          <multiselect
            v-model="selecSousTraitant"
            :searchable="true"
            :close-on-select="true"
            :multiple="false"
            :options="getAllsousTraitant"
            label="name"
            track-by="id"
          >
            <template slot="noResult">
              {{ $t("NO_DATA_FOUND") }}
            </template>
          </multiselect>
         
        </b-form-group> 
        <!-- <b-form-group :label="$t('COMPANY_ID')" >
          <multiselect
            v-model="selectedEntreprise"
            :searchable="true"
            :close-on-select="true"
            :multiple="false"
            :options="getAllentreprises"
            label="lib"
            track-by="id"
          >
            <template slot="noResult">
              {{ $t("NO_DATA_FOUND") }}
            </template>
          </multiselect>
         
        </b-form-group> -->
      
      </div>


    
    </form>
    <template #modal-footer>
        <div class="double">
            <b-button variant="danger" @click="close">{{ $t("CANCEL") }}</b-button>
            <b-button variant="success" @click="editRGE">
              <div class="block-spinner">
                {{ $t("SAVE") }}
                <div v-if="getLoadingCertificatRge" class="loading ml-2">
                  <div class="spinner-border" role="status"></div>
                </div>
              </div>
            </b-button>
          </div>
        </template>
  </b-modal>
</template>  
    <script>
        import { mapActions, mapGetters } from "vuex";
        import errorHandle from "../ui/errorHandle.vue";
        import { useVuelidate } from "@vuelidate/core";
        import { required } from "@vuelidate/validators";
        
        export default {
          props: {
            updateData: Object,
            show: Boolean,
          },
          components: {
            errorHandle,
          },
          setup() {
            const v$ = useVuelidate();
            return { v$ };
          },
          data() {
            return {
              errorS: null,
              erreurlist: {
                num: null,
                date_attribution: null,
                date_expiration: null,
                entreprise: null,
              },
              localUpdateData: {
                num: { $model: this.updateData.num, $errors: [] },
                date_attribution: { $model: this.updateData.date_attribution, $errors: [] },
                date_expiration: { $model: this.updateData.date_expiration, $errors: [] },
                entreprise_id: { $model: this.updateData.entreprise_id, $errors: [] },
                sous_traitant_id: { $model: this.updateData.sous_traitant_id, $errors: [] },
                entreprise: null,
                sousTraitant: null,
       
              },
           
              // selectedEntreprise: null,
              selecSousTraitant: null,
              optionsType: [
                { value: "mandataire", text: this.$t("mandataire") },
                { value: "partenaire", text: this.$t("partenaire") },
              ],
            };
          },
          validations() {
            return {
              localUpdateData: {
                num: { required },
                date_attribution: { required },
                date_expiration: { required },
              },
            };
          },
          computed: {
            ...mapGetters([
              "getAllentreprises",
              "getOnlineUser",
              "getLoadingsousTraitant",
              "getAllCertificatRges",
              "getAllsousTraitant",
              "getLoadingCertificatRge"
            ]),
            isSuperAdmin() {
              return this.getOnlineUser.role === "user.super-admin";
            },
          
            
            // entrepriseSelectionnee() {
            //   return (
            //     this.getAllentreprises.find(
            //       (entreprise) => entreprise.id === this.localUpdateData.entreprise_id
            //     ) || null
            //   );
            // },
            sousTraitantSelectione() {
              return (
               
                this.getAllsousTraitant.find(
                  (sousTraitant) =>
                    sousTraitant.id === this.localUpdateData.sous_traitant.id
                ) || null
              );
            },
          },
          watch: {
            updateData: {
              handler(newVal) {
                this.localUpdateData = { ...newVal };
        
                // this.selectedEntreprise = this.entrepriseSelectionnee;
                this.selecSousTraitant = this.sousTraitantSelectione;
              },
              immediate: true,
            },
          },
                  

          methods: {
            ...mapActions(["updateCertificatRge", "all_entreprises","allsousTraitant"]),
            updateNomToUpperCase(value) {
              this.localUpdateData.nom = value.toUpperCase();
            },
            validateState(name) {
              const { $dirty, $error } = this.v$.localUpdateData[name];
              return $dirty ? !$error : null;
            },
            async editRGE() {
              const isFormCorrect = await this.v$.$validate();
              if (!isFormCorrect) return;
              this.errorS = null;
              this.erreurlist = {
                num: null,
                date_attribution: null,
                date_expiration: null,
                
              };
              const data = {
                ...this.localUpdateData,
                id: this.localUpdateData.id,
                num: this.localUpdateData.num,
                date_attribution: this.localUpdateData.date_attribution,
                date_expiration: this.localUpdateData.date_expiration,
              
                // entreprise_id: this.selectedEntreprise
                //   ? this.selectedEntreprise.id
                //   : null,
                sous_traitant_id: this.selecSousTraitant
                  ? this.selecSousTraitant.id
                  : null,
              };
        
              // if (this.isSuperAdmin) {
              //   data.entreprise_id = this.localUpdateData.entreprise
              //     ? this.localUpdateData.entreprise.id
              //     : this.getOnlineUser.entreprise.id;
              // } else {
              //   data.entreprise_id = this.getOnlineUser.entreprise.id;
              // }
        
              await this.updateCertificatRge(data)
                .then(() => {
                  this.resetModal();
                  this.$emit("updateList");
                  this.close();
                })
                .catch((err) => {
                  throw err
                });
            },
            close() {
              this.$emit("close");
            },
            resetModal() {
              this.localUpdateData = {
                num: null,
                date_attribution: null,
                date_expiration: null,
                entreprise_id: null,
                sous_traitant_id:null,
              };
             
              this.selectedEntreprise = null;
              this.selecSousTraitant = null;
            },
          },
        }

        </script>
        
        <style lang="scss" scoped>
          
          .option {
            display: flex;
            align-items: center;
            .option__image {
              background-color: #465081;
              padding: 4px;
              max-height: 42px;
              max-width: 42px;
            }
          }
          </style>
        
      