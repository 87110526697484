<template>
  <div>
    <div v-for="(error, i) in list" :key="i" class="error-message">
      <span
        v-if="
          error.$validator == 'required' || error.$validator == 'requiredIf'
        "
      >
        {{ $t("REQUIRED_FIELD") }}
      </span>
      <span v-if="error.$validator == 'maxLength'">
        {{ $t("MAXZISECHAMP") }} : {{ error.$params.max }}
      </span>
      <span v-if="error.$validator == 'minLength'">
        {{ $t("MINZISECHAMP") }} : {{ error.$params.min }}
      </span>
      <span v-if="error.$validator == 'maxValue'">
        {{ $t("MAX") }} : {{ error.$params.max }}
      </span>
      <span v-if="error.$validator == 'minValue'">
        {{ $t("MIN") }} : {{ error.$params.min }}
      </span>
      <span v-if="error.$validator == 'numeric'">
        {{ $t("NUMERIC_FIELD") }}
      </span>
      <span v-if="error.$validator == 'email'">
        {{ $t("REQUIRED_FIELD_EMAIL") }}
      </span>
      <span v-if="error.$validator == 'sameAs'">
        {{ $t("PASSWORD_MATCHES") }}
      </span>
      <span v-if="error.$validator == 'alphaNum'">
        {{ $t("ALPHA_NUMERIC_FIELD") }}
      </span>
      <span v-if="error.$validator == 'alpha'">
        {{ $t("ALPHA_NUMERIC_FIELD") }}
      </span>
      <span v-if="error.$validator == 'requiredUnless'">
        {{ $t("REQUIRED_FIELD") }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    list: Array,
  },
};
</script>
