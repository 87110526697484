<template>
  <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <div class="page-header">
      <h1>{{ $t("certificat_rge") }}</h1>
      <b-button
        size="sm"
        variant="success"
        class="button-succes-style ml-2 float-right mb-2"
        @click="handleStore"
        ><font-awesome-icon icon="plus-circle" /> {{ $t("NEW") }}
      </b-button>
    </div>

    <certificatRGEmodal @updateList="setup(true)"></certificatRGEmodal>

    <div class="content">
      <div class="content-header">
        <searchBar
          :searchText="$t('NUM_RGE')"
          :searchFunc="allCertificatRge"
          :showFiltre="false"
          @returnValue="updateSearch($event)"
        ></searchBar>
      </div>
      <div class="filtre">
        <p class="table-count">
          {{ getTotalRowCertificatRge }} {{ $t("certificat_rge") }}
        </p>
        <div class="productTrie" v-if="currententreprise.lib != null">
          {{ currententreprise.lib }}
          <font-awesome-icon
            icon="times-circle"
            class="icons"
            @click="selectentreprise()"
          />
        </div>
      </div>
      <div class="table-rapport-style w-100">
        <table>
          <thead>
            <tr>
              <th style="border-radius: 0px 5px 0px 0px">
                {{ $t("NUM_RGE") }}
              </th>
              <th>
                {{ $t("date_attribution") }}
              </th>
              <th>
                {{ $t("date_expiration") }}
              </th>
              <th style="border-radius: 0px 5px 0px 0px">
                {{ $t("SOCIETY") }}
              </th>
              <th class="action_head"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              role="row"
              class="ligneNormale"
              v-for="(data, i) in getAllCertificatRges"
              :key="i"
              :class="{ default_element: data.is_default }"
            >
              <td aria-colindex="2" role="cell">
                {{ data.num }}
              </td>
              <td aria-colindex="3" role="cell">
                {{ formatDate(data.date_attribution) }}
              </td>
              <td aria-colindex="4" role="cell">
                {{ formatDate(data.date_expiration) }}
              </td>
              <td v-if="data.sous_traitant" aria-colindex="2" role="cell">
                {{ data.sous_traitant.name }}
              </td>
              <td v-else aria-colindex="2" role="cell">
                {{ data.entreprise?.lib }}
              </td>
              <td aria-colindex="5" role="cell" class="action_col">
                <b-button
                  class="button-succes-style"
                  size="sm"
                  variant="danger"
                  @click.prevent.stop="handleDelete(data)"
                >
                  <font-awesome-icon icon="trash" />
                </b-button>
                <b-button
                  class="button-succes-style"
                  size="sm"
                  variant="success"
                  @click.prevent.stop="handleUpdate(data)"
                >
                  <font-awesome-icon icon="pencil-alt" />
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <updateRGE
        v-if="showModal"
        @close="closeModal"
        :show="showModal"
        :updateData="certificatRGEToConfig"
      ></updateRGE>
      <deleteModalVue
        :loader="getLoadingCertificatRge"
        :elemToDelete="certificatRGEToDelete"
        :elemDelete="deleteCertificatRge"
        @updateList="setup(false)"
      ></deleteModalVue>

      <b-pagination
        v-if="search == ''"
        v-model="page"
        :total-rows="getTotalRowCertificatRge"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        last-number
        first-number
        align="center"
        size="sm"
        @change="pagination"
        class=""
      ></b-pagination>
      <b-pagination
        v-else
        v-model="page"
        :total-rows="getTotalRowCertificatRge"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        last-number
        first-number
        align="center"
        size="sm"
        @change="pagination"
        class=""
      ></b-pagination>
    </div>

    <div class="text-center center-spin">
      <b-spinner
        v-if="getLoadingCertificatRge"
        variant="primary"
        label="Spinning"
        class="big-spin"
      ></b-spinner>
    </div>
    <CommentMarche to="/how-it-work/sousTraitant"></CommentMarche>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import deleteModalVue from "../components/ui/deleteModal.vue";
import certificatRGEmodal from "../components/Ajoutmodel/certificatRGEmodal.vue";
import searchBar from "../components/ui/searchBar.vue";
import CommentMarche from "../components/commentMarche.vue";
import updateRGE from "../components/Ajoutmodel/updateRGE.vue";
export default {
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  components: {
    searchBar,
    deleteModalVue,
    certificatRGEmodal,
    CommentMarche,
    updateRGE,
  },
  data() {
    return {
      showModal: false,
      search: "",
      certificatRGEToConfig: null,
      certificatRGEToDelete: null,
      page: 1,
      perPage: 10,
      errorU: false,
      currententreprise: {
        id: null,
      },
      erreurlist: {
        num: null,
        date_attribution: null,
        date_expiration: null,
      },
      filtre_entreprise: {
        id: null,
      },
    };
  },
  validations() {
    return {
      certificatRGEToConfig: {
        num: { required },
        date_attribution: {
          required,
        },
        date_expiration: { required },
      },
    };
  },
  methods: {
    ...mapActions([
      "allCertificatRge",
      "updateCertificatRge",
      "deleteCertificatRge",
      "all_entreprises",
      "allsousTraitant",
    ]),
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    closeModal() {
      this.showModal = false;
      this.setup(true);
    },
    validateState(name) {
      const { $dirty, $error } = this.v$.certificatRGEToConfig[name];
      return $dirty ? !$error : null;
    },

    updateSearch(s) {
      this.page = 1;
      this.search = s;
      // this.search = s;
    },

    debounceInput2: _.debounce(function (e) {
      this.all_entreprises({
        search: e,
      });
    }, 500),

    selectentreprise(query) {
      if (query) {
        this.filtre_entreprise = query;
      } else {
        this.filtre_entreprise = {
          lib: null,
        };
      }
      this.setup(true);
    },

    async searchcertificatRGE() {
      await this.allCertificatRge({
        // search: this.search,
        page: this.page,
        per_page: this.perPage,
        num: this.num,
        sous_traitant_id: this.sous_traitant_id,
      });
    },
    hideModal(ref) {
      this.$refs[ref].hide();
    },

    handleUpdate(CAT) {
      this.certificatRGEToConfig = { ...CAT };
      this.showModal = true;
    },
    handleDelete(CAT) {
      this.certificatRGEToDelete = { ...CAT };
      this.$root.$emit("bv::show::modal", "ModelDelete");
    },
    resetModal() {
      this.erreurlist = {
        num: null,
        date_attribution: null,
        date_expiration: null,
      };
      this.errorU = null;
      this.hideModal("ModelConfigSousTraitant");
    },

    handleStore() {
      this.$root.$emit("bv::show::modal", "certificatRGEmodal");
    },

    pagination(paginate) {
      this.page = paginate;
      this.searchcertificatRGE();
    },

    setup(refresh = false) {
      if (refresh) this.page = 1;
      this.search = null;
      this.currententreprise = this.filtre_entreprise;

      if (this.getOnlineUser) {
        if (this.isSuperAdmin) {
          this.allCertificatRge({
            page: this.page,
            per_page: this.perPage,
          });
        } else {
          this.allCertificatRge({
            page: this.page,
            per_page: this.perPage,
            created_by: this.getOnlineUser.entreprise.id,
          });
        }
      }
    },
  },

  mounted() {
    this.setup(true);
    if (this.isSuperAdmin) {
      this.allsousTraitant({
        page: this.page,
        per_page: 1000,
        search: "",
      });
    } else {
      this.allsousTraitant({
        page: this.page,
        per_page: 1000,
        search: "",
        entreprise: this.getOnlineUser.entreprise.id,
      });
    }
    if (this.isSuperAdmin)
      this.all_entreprises({
        page: this.page,
        per_page: 1000,
        search: this.search,
      });
  },

  computed: {
    ...mapGetters([
      "getAllCertificatRges",
      "getLoadingCertificatRge",
      "getTotalRowCertificatRge",
      "getTabs",
      "getOnlineUser",
      "getAllentreprises",
      "getentrepriseLoading",
    ]),
    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },
    isCommercialBatigo() {
      return this.getOnlineUser.role == "user.commercial-batigo";
    },
    isAssistant() {
      return this.getOnlineUser.role == "user.assistant";
    },
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
  },
};
</script>

<style lang="scss" scoped>
.input-group .icon-balance {
  top: 8px;
}

.prodimg {
  padding: 8px;
  background: #465081;
  max-width: 64px;
  max-height: 64px;
}

.option {
  display: flex;
  align-items: center;

  .option__image {
    background-color: #465081;
    padding: 4px;
    max-height: 42px;
    max-width: 42px;
  }
}

.content p {
  font-size: 12px;
  font-weight: 500;
  padding: 3px 0;
  margin: 0;
}

.w-100 {
  background-color: #f8f8f8;
  padding: 4px;
  overflow-x: auto;
}

body {
  background: #fafdff;
}

.actionModel {
  position: relative;
  padding: 15px 0 0;
  display: flex; /* Ajouté pour préciser le type de disposition */
  flex-flow: column;
  align-items: center;

  .messageError {
    color: #e4261b;
    font-size: 16px;
    background-color: #fff;
    border-color: #fff;
  }

  .block-spinner {
    display: flex;
    justify-content: center;
  }

  .spinner-border {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.detailClient {
  background-color: #fff;
  box-shadow: 1px 1px 24px #00000019;

  .clientTop {
    padding: 16px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-bottom: 1px solid #707070;

    .prodimg {
      max-width: 185px;
      max-height: 231px;
    }
  }

  h4 {
    color: #28367a;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }

  p {
    color: #515151;
    font-size: 16px;
    font-weight: 500;
  }
}

.filtre {
  display: flex;
  align-items: center;

  .table-count,
  .productTrie {
    margin-right: 15px;
  }

  .productTrie {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    overflow: hidden;
    padding: 4px 8px;
    border-radius: 32px;
    background-color: #34c38f;
    color: #fff;

    .icons {
      cursor: pointer;
      width: 18px;
      height: 18px;
      margin-left: 4px;
    }
  }
}

.image-conteneur {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .block {
    cursor: pointer;
    width: 25%;
    margin-bottom: 16px;
    padding: 15px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1); /* Correction ici */
    }

    .image-content img {
      width: 100%;
    }

    p {
      margin: 0;
      text-align: center;
      color: #303030;
    }
  }
}

.galerieImage {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 20%;
    max-height: 100px;
  }
}
</style>
