import { render, staticRenderFns } from "./mandataireEtpartenairemodale.vue?vue&type=template&id=772e526b&scoped=true"
import script from "./mandataireEtpartenairemodale.vue?vue&type=script&lang=js"
export * from "./mandataireEtpartenairemodale.vue?vue&type=script&lang=js"
import style0 from "./mandataireEtpartenairemodale.vue?vue&type=style&index=0&id=772e526b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "772e526b",
  null
  
)

export default component.exports