<template>
  <div class="inner-container">
    <section class="banner-container-nd">
      <div class="banner"></div>
      <div class="banner-content">
        <h1 class="title">
          <div>{{ $t("ESTIMATE") }}</div>
        </h1>
      </div>
    </section>
    <section class="banner-bottom">
      <hr class="red" />
      <div class="grid-1">
        <img src="@/assets/element-1.png" />
      </div>
 <div
  class="groupe d-md-none   drop"
      v-if="(isentrepriseAdmin || isSuperAdmin || isAssistant || isCommercial)"
>
   
    <b-dropdown  class="fiche"
      variant="link"
      right
      no-caret
      toggle-class="dropdown-btn"
    >
      <template #button-content>
        <img src="@/assets/devis-plus.png" alt="devis-plus" class="dropdown-image"/>
      </template>
      
      <!-- Dropdown menu options -->
      <b-dropdown-item @click="goToRoute('/devis/devisTypesBAR-TH')">
       BAR-TH
      </b-dropdown-item>
      <b-dropdown-item @click="goToRoute('/devis')">
        BAR-EN
      </b-dropdown-item>
      <b-dropdown-item @click="goToRoute('/devis/devisTypesLibre')">
        Libre
      </b-dropdown-item>
    </b-dropdown>

    <h5>{{ $t("NEW") }} {{ $t("ESTIMATE") }}</h5>
</div>
      <hr />
      <div class="groupe">
        <router-link to="/devis/list" class="fiche">
          <img src="@/assets/devis-encours.png" />
        </router-link>
        <h5>{{ $t("Liste_devis") }}</h5>
      </div>
      <div class="grid-2">
        <img src="@/assets/element-1.png" />
      </div>
    </section>
    <CommentMarche to="/how-it-work/devis"></CommentMarche>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CommentMarche from "../components/commentMarche.vue";

export default {
  data() {
    return {};
  },
  components: {
    CommentMarche,
  },
  methods: {
    goToRoute(route) {
      this.$router.push(route);
    }
  },
  computed: {
    ...mapGetters(["getTabs", "getOnlineUser"]),
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },
    isCommercial() {
      return this.getOnlineUser.role == "user.commercial-entreprise";
    },
    isAssistant() {
      return this.getOnlineUser.role == "user.assistant";
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
 .flex-container {
  display: flex;
  align-items: center; /* Center items vertically */
  gap: 10px; /* Space between items */
}
.dropdown-wrapper {
  position: relative;
  display: inline-block;
}

.dropdown-btn {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.dropdown-btn img {
  width: 50px; 
  height: auto;
}


.dropdown-select {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0; 
  cursor: pointer;
} 


select {
  padding: 15px 20px; 
}

option {
  padding: 10px 200px; 
  text-indent: 20px; 
}



.inner-container {
  padding: 0 !important;
  width: 100%;
}

.banner-container-nd {
  position: relative;
  background: url(../assets/business-finance-man.png) top right/cover no-repeat;
  height: calc(100vh - 150px);
  display: flex !important;
  align-items: center;
  justify-content: center;
  z-index: 0;

  .banner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url(../assets/banner.png);
    height: calc(100vh - 150px);
    z-index: -1;
  }

  .banner-content {
    @media only screen and (max-width: 900px) {
      padding: 8px;
      width: 100%;
    }
    min-width: 50%;
    text-align: center;

    .title {
      @media only screen and (max-width: 900px) {
        font-size: 3.5rem;
        line-height: 0.8;
      }
      margin-bottom: 32px;
      color: #fff;
      font-weight: 800;
      font-size: 5rem;
      line-height: 0.6;
      & span {
        @media only screen and (max-width: 900px) {
          font-size: 2rem;
        }
        font-size: 3.5rem;
        font-weight: 400;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 899px){
    .drop {
        display: flex; 
    }}
.banner-bottom {
  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
  position: relative;
  background-color: #28367a;
  min-height: 150px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  .red {
    @media only screen and (max-width: 900px) {
      border-top: 3px solid #e4261b;
      top: -3px;
    }
    position: absolute;
    width: 25%;
    height: 0px;
    border: none;
    border-top: 6px solid #e4261b;
    bottom: 0;
    left: 0;
    margin: 0;
  }
.drop{
  display: none;
}
  .groupe {

    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    text-align: center;

    .fiche {
      padding: 8px;
      background-color: #24316d;
      border-radius: 50%;
      height: 72px;
      width: 72px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 40px;
      }

      &:hover {
        background-color: transparent;
        transform: scale(1.2);
      }
    }

    & h5 {
      font-size: 20px;
      font-weight: 600;
      margin: 4px 0;

      & span {
        color: #e4261b;
      }
    }

    & h6 {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      max-width: 260px;
    }
  }

  & hr {
    @media only screen and (max-width: 900px) {
      height: 2px;
      width: 50%;
      border: 0;
      border-top: 1px solid #fff;
    }
    border-left: 1px solid #fff;
    height: 118px;
  }

  .grid-1,
  .grid-2 {
    @media only screen and (max-width: 900px) {
      display: none;
    }
    img {
      width: 45px;
      height: 40px;
    }

    position: absolute;
  }

  .grid-1 {
    top: 12px;
    left: 12px;
  }

  .grid-2 {
    bottom: 12px;
    right: 12px;
  }
}
</style>
