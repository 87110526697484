<template>
  <b-modal v-model="isOpen" :show="isOpen" @hide="close">
    <template #modal-header>
      <slot name="header">
        <h5>{{ modalTitle }}</h5>
        <b-button size="sm" @click="close">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17.028"
            height="17.028"
            viewBox="0 0 17.028 17.028"
          >
            <path
              id="Icon_material-close"
              d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
              transform="translate(-7.5 -7.5)"
              fill="#393939"
            />
          </svg>
        </b-button>
      </slot>
    </template>
    <form>
      <div class="center">
        <b-form-group :label="$t('CUSTOMER')" class="style">
          <multiselect
            v-model="selectedClient"
            :searchable="true"
            :close-on-select="true"
            :multiple="false"
            :options="getAllclients"
            label="nom"
            track-by="id"
            :placeholder="$t('Select_agent')"
            class="form-input custom-placeholder custom-input"
          />
        </b-form-group>

        <b-form-group :label="$t('Organisme habital') + '*'">
          <b-form-select
            v-model="selectedType"
            :options="optionsType"
            aria-describedby="organisme-feedback"
          ></b-form-select>
        </b-form-group>

        <b-form-group :label="$t('Couleur') + '*'">
          <b-form-select
            v-model="localUpdateData.couleur.$model"
            :options="filteredCouleurOptions"
            aria-describedby="couleur-feedback"
          ></b-form-select>
        </b-form-group>

        <b-form-group :label="$t('Precarite') + '*'">
          <b-form-select
            v-model="localUpdateData.precarite.$model"
            :options="filteredPrecariteOptions"
            aria-describedby="precarite-feedback"
          ></b-form-select>
        </b-form-group>

        <b-form-group :label="$t('region') + '*'" label-for="region">
          <b-form-input
            class="form-control"
            aria-required="true"
            :placeholder="$t('region')"
            id="region"
            required
            :class="{
              'form-control is-invalid': !isNameValid,
              'form-control has-validation': isNameValid,
            }"
            v-model="localUpdateData.region.$model"
            oninput="this.value = this.value.toUpperCase()"
            aria-describedby="region-feedback"
          ></b-form-input>

          <div v-if="erreurlist.region" class="error-message">
            <ul v-if="Array.isArray(erreurlist.region)">
              <span v-for="(e, index) in erreurlist.region" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.region }}</span>
          </div>
        </b-form-group>

        <b-form-group :label="$t('COMPANY_ID')" v-if="isSuperAdmin">
          <multiselect
            v-model="selectedEntreprise"
            :searchable="true"
            :close-on-select="true"
            :multiple="false"
            :options="getAllentreprises"
            label="lib"
            track-by="id"
            @select="updateEntrepriseId"
          >
            <div v-if="erreurlist.entreprise" class="error-message">
              <ul v-if="Array.isArray(erreurlist.entreprise)">
                <span v-for="(e, index) in erreurlist.entreprise" :key="index">
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.entreprise }}</span>
            </div>
            <template slot="noResult">
              {{ $t("NO_DATA_FOUND") }}
            </template>
          </multiselect>
        </b-form-group>
      </div>
    </form>

    <template #modal-footer>
      <slot name="footer">
        <div class="double">
          <b-button variant="danger" @click="close">{{
            $t("CANCEL")
          }}</b-button>
          <b-button variant="success" @click="confirm">{{
            $t("SAVE")
          }}</b-button>
        </div>
      </slot>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { handlePaste, preventExceedingLengthFloat } from "@/utils/inputUtils";
export default {
  data() {
    return {
      isNameValid: true,
      issiretValid: true,
      erreurlist: {
        region: [],
        couleur: [],
        client: [],
        entreprise: [],
        type: [],
        precarite: [],
      },
      selectedEntreprise: null,
      selectedClient: null,
      localUpdateData: {},
      optionsType: [
        { value: "ANAH", text: this.$t("ANAH") },
        { value: "MPR", text: this.$t("MPR") },
        { value: "Aide Departement", text: this.$t("Aide Departement") },
        { value: "Autre", text: this.$t("Autre") },
      ],
      couleurByType: {
        ANAH: [
          { value: "bleu", text: this.$t("Bleu") },
          { value: "jaune", text: this.$t("Jaune") },
        ],
        MPR: [
          { value: "viole", text: this.$t("Viole") },
          { value: "rouge", text: this.$t("Rouge") },
        ],
        "Aide Departement": [
          { value: "jaune", text: this.$t("Jaune") },
          { value: "bleu", text: this.$t("Bleu") },
          { value: "viole", text: this.$t("Viole") },
          { value: "rouge", text: this.$t("Rouge") },
        ],
        Autre: [
          { value: "jaune", text: this.$t("Jaune") },
          { value: "bleu", text: this.$t("Bleu") },
          { value: "viole", text: this.$t("Viole") },
          { value: "rouge", text: this.$t("Rouge") },
        ],
      },
      precariteByType: {
        ANAH: [
          { value: "Intermédiaires", text: this.$t("Intermédiaires") },
          { value: "Supérieurs", text: this.$t("Supérieurs") },
        ],
        MPR: [
          { value: "Supérieurs", text: this.$t("Supérieurs") },
          { value: "Tres modeste", text: this.$t("Tres modeste") },
        ],
        "Aide Departement": [
          { value: "Modeste", text: this.$t("Modeste") },
          { value: "Intermédiaires", text: this.$t("Intermédiaires") },
          { value: "Tres modeste", text: this.$t("Tres modeste") },
          { value: "Supérieurs", text: this.$t("Supérieurs") },
        ],
        Autre: [
          { value: "Modeste", text: this.$t("Modeste") },
          { value: "Intermédiaires", text: this.$t("Intermédiaires") },
          { value: "Tres modeste", text: this.$t("Tres modeste") },
          { value: "Supérieurs", text: this.$t("Supérieurs") },
        ],
      },
      selectedType: null,
    };
  },

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    updateData: {
      type: Object,
      default: () => null,
    },
  },

  created() {
    this.initializeLocalUpdateData();
    this.all_clients({
      search: "",
      entreprise_id: this.getOnlineUser.entreprise.id,
    });
  },

  watch: {
    updateData: {
      immediate: true,
      handler() {
        this.initializeLocalUpdateData();
      },
      "localUpdateData.region.$model"(newValue) {
        this.validateName(newValue);
      },
    },
  },

  methods: {
    ...mapActions(["all_clients"]),
    validateName(newValue) {
      if (!newValue || newValue.length < 1) {
        this.isNameValid = false;
        this.erreurlist.region = ["Ce champ est obligatoire"];
      } else {
        this.isNameValid = true;
        this.erreurlist.region = [];
      }
    },
    onKeyDown(event) {
      preventExceedingLengthFloat(event);
    },

    onPaste(event, fieldName) {
      handlePaste.call(this, event, fieldName);
    },

    initializeLocalUpdateData() {
      this.localUpdateData = {
        organisme: {
          $model: (this.updateData && this.updateData.organisme) || "ANAH",
          $errors: [],
        },
        couleur: {
          $model: (this.updateData && this.updateData.couleur) || "",
          $errors: [],
        },
        precarite: {
          $model: (this.updateData && this.updateData.precarite) || "",
          $errors: [],
        },
        region: {
          $model: (this.updateData && this.updateData.region) || "",
          $errors: [],
        },
        client_id: {
          $model: (this.updateData && this.updateData.client_id) || "",
          $errors: [],
        },
        entreprise_id: {
          $model: (this.updateData && this.updateData.entreprise_id) || "",
          $errors: [],
        },
      };
    },

    close() {
      this.$emit("close");
    },

    confirm() {
      this.validateName(this.localUpdateData.region.$model);
      if (this.selectedEntreprise) {
        this.localUpdateData.entreprise_id.$model = this.selectedEntreprise.id;
      } else {
        this.localUpdateData.entreprise_id.$model =
          this.getOnlineUser.entreprise.id;
      }
      if (this.selectedClient) {
        this.localUpdateData.client_id.$model = this.selectedClient.id;
      }

      this.$emit("confirm", {
        organisme: this.localUpdateData.organisme.$model,
        couleur: this.localUpdateData.couleur.$model,
        precarite: this.localUpdateData.precarite.$model,
        region: this.localUpdateData.region.$model,
        entreprise_id: this.localUpdateData.entreprise_id.$model,
        client_id: this.localUpdateData.client_id.$model,
      });

      this.close();
    },
  },

  computed: {
    ...mapGetters(["getAllentreprises", "getOnlineUser", "getAllclients"]),
    filteredCouleurOptions() {
      return this.selectedType
        ? this.couleurByType[this.selectedType] || []
        : [];
    },
    filteredPrecariteOptions() {
      return this.selectedType
        ? this.precariteByType[this.selectedType] || []
        : [];
    },
    modalTitle() {
      return this.updateData && Object.keys(this.updateData).length > 0
        ? this.$t("UPDATE_EXTERNAL_ENTITY")
        : this.$t("ADD_EXTERNAL_ENTITY");
    },
    isSuperAdmin() {
      return this.getOnlineUser.role === "user.super-admin";
    },
  },
};
</script>

<style lang="scss" scoped>
.option {
  display: flex;
  align-items: center;
  .option__image {
    background-color: #465081;
    padding: 4px;
    max-height: 42px;
    max-width: 42px;
  }
}
</style>
