<template>
    <div class="inner-container" :class="{ 'min-container': !getTabs }">
      <div class="page-header">
        <h1>{{ $t("organisme") }}</h1>
        <b-button
          size="sm"
          variant="success"
          class="button-succes-style ml-2 float-right mb-2"
          @click="openModal('add')"
          ><font-awesome-icon icon="plus-circle" /> {{ $t("NEW") }}
        </b-button>
      </div>
      <div class="content">
        <div class="content-header">
          <searchBar
            :searchText="$t('SEARCH') + ' ' + $t('NAME')"
            :searchFunc="searchorganisme"
            :showFiltre="true"
            :filtre="{
              entreprise_id: getOnlineUser.entreprise.id,
              type: this.currenttype,
              couleur:this.currentcouleur
            }"
            @returnValue="updateSearch($event)"
          ></searchBar>
          <filterOrganisme
            :optionsType="optionsType"
            :filtreType="filtre_type"
            :optionsCouleur="couleurType"
            :filtre_class="filtre_couleur"
            @apply="handleApply"
            @close="handleClose"
          ></filterOrganisme>
        </div>
        <div class="filtre">
          <p class="table-count">
            {{ getTotalOrganismRows }} {{ $t("organisme") }}
          </p>
          <div class="productTrie" v-if="currenttype != null">
            {{ currenttype }}
            <font-awesome-icon
              icon="times-circle"
              class="icons"
              @click="deleteCurrentType()"
            />
          </div>
          <div class="productTrie" v-if="currentcouleur != null">
            {{ currentcouleur }}
            <font-awesome-icon
              icon="times-circle"
              class="icons"
              @click="deleteCurrentCouleur()"
            />
          </div>
          <div class="productTrie" v-if="currententreprise.lib != null">
            {{ currententreprise.lib }}
            <font-awesome-icon
              icon="times-circle"
              class="icons"
              @click="selectentreprise()"
            />
          </div>
        </div>
        <div class="table-rapport-style w-100">
          <table>
            <thead>
              <tr>
                <th style="border-radius: 0px 5px 0px 0px">
                  {{ $t("NAME") }}
                </th>
                <th>
                  {{ $t("organisme") }}
                </th>
                <th>
                  {{ $t("classe") }}
                </th>
  
                <th class="action_head"></th>
              </tr>
            </thead>
  
            <tbody>
              <tr
                role="row"
                class="ligneNormale"
                v-for="(data, i) in getAllOrganisms"
                :key="i"
                :class="{ default_element: data.is_default }"
              >
                <td aria-colindex="2" role="cell">
                  {{ data.nom }}
                </td>
                <td aria-colindex="3" role="cell">
                  {{ data.organisme }}
                </td>
                <td aria-colindex="4" role="cell">
                  {{ data.couleur }}
                </td>
                <td aria-colindex="5" role="cell" class="action_col">
                  <b-button
                    class="button-succes-style"
                    size="sm"
                    variant="danger"
                    @click.prevent.stop="handleDelete(data)"
                  >
                    <font-awesome-icon icon="trash" />
                  </b-button>
                  <b-button
                    class="button-succes-style"
                    size="sm"
                    variant="success"
                    @click.prevent.stop="handleUpdate(data)"
                  >
                    <font-awesome-icon icon="pencil-alt" />
                  </b-button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
  
        <organismemodale
          v-if="isModalOpen"
          :isOpen="isModalOpen"
          @close="closeModal"
          @confirm="handleConfirm"
          :updateData="update_organisme"
        ></organismemodale>
  
        <deleteModalVue
          :loader="isOrganismsLoading"
          :elemToDelete="delete_organisme"
          :elemDelete="deleteOrganism"
          @updateList="setup(false)"
        ></deleteModalVue>
  
        <b-pagination
          v-if="nom == ''"
          v-model="page"
          :total-rows="getTotalOrganismRows"
          :per-page="perPage"
          aria-controls="my-table"
          pills
          last-number
          first-number
          align="center"
          size="sm"
          @change="pagination"
          class=""
        ></b-pagination>
        <b-pagination
          v-else
          v-model="page"
          :total-rows="getTotalOrganismRows"
          :per-page="perPage"
          aria-controls="my-table"
          pills
          last-number
          first-number
          align="center"
          size="sm"
          @change="pagination"
          class=""
        ></b-pagination>
      </div>
  
      <div class="text-center center-spin">
        <b-spinner
          v-if="isOrganismsLoading"
          variant="primary"
          label="Spinning"
          class="big-spin"
        ></b-spinner>
      </div>
      <CommentMarche to="/how-it-work/sousTraitant"></CommentMarche>
    </div>
  </template>
  <script>
  import { mapActions, mapGetters } from "vuex";
  
  import deleteModalVue from "../components/ui/deleteModal.vue";
  import organismemodale from "../components/Ajoutmodel/organismemodale.vue";
  import searchBar from "../components/ui/searchBar.vue";
  import CommentMarche from "../components/commentMarche.vue";
  import filterOrganisme from "@/components/Filter/filterOrganisme.vue";
  export default {
    components: {
      searchBar,
      deleteModalVue,
      organismemodale,
      CommentMarche,
      filterOrganisme,
    },
    data() {
      return {
        
        isModalOpen: false,
        filtre_type: null,
        filtre_couleur: null,
        nom: "",
        currenttype: null,
        currentcouleur: null,
        delete_organisme: null,
        update_organisme: null,
        page: 1,
        perPage: 10,
        errorU: false,
        currententreprise: {
          id: null,
        },
        optionsType: [
          { value: "ANAH", text: this.$t("ANAH") },
          { value: "MPR", text: this.$t("MPR") },
          { value: "Aide Departement", text: this.$t("Aide Departement") },
          { value: "Autre", text: this.$t("Autre") },
        ],
        couleurType: [
          { value: "jaune", text: this.$t("Jaune") },
          { value: "bleu", text: this.$t("Bleu") },
          { value: "viole", text: this.$t("Viole") },
          { value: "rouge", text: this.$t("Rouge") },
        ],
        filtre_entreprise: {
          id: null,
        },
      };
    },
    methods: {
      ...mapActions([
        "allOrganisms",
        "deleteOrganism",
        "all_entreprises",
        "updateOrganism",
        "createOrganism",
      ]),
  
      handleApply(selectedType,selectedCouleur) {
        this.filtre_type = selectedType;
        this.filtre_couleur = selectedCouleur;
        this.setup(true);
      },
      handleClose() {},
      updateSearch(s) {
        this.page = 1;
        this.search = s;
      },
      deleteCurrentType() {
        this.filtre_type = null;
        
        this.setup();
      },
      deleteCurrentCouleur() {
    
        this.filtre_couleur = null;
        this.setup();
      },
      selectentreprise(query) {
        if (query) {
          this.filtre_entreprise = query;
        } else {
          this.filtre_entreprise = {
            lib: null,
          };
        }
        this.setup(true);
      },
  
      async searchorganisme() {
        await this.allOrganisms({
          ENTITE_EXTERNE: this.search,
          page: this.page,
          per_page: this.perPage,
          search: "",
          entreprise_id: this.getOnlineUser.entreprise.id,
        });
      },
      hideModal(ref) {
        this.$refs[ref].hide();
      },
  
      handleDelete(CAT) {
        this.delete_organisme = { ...CAT };
        this.$root.$emit("bv::show::modal", "ModelDelete");
      },
  
      openModal(action, updateData = null) {
        this.update_organisme = updateData;
        this.isModalOpen = true;
      },
  
      handleUpdate(organisme) {
        this.openModal("update", organisme);
      },
      closeModal() {
        this.isModalOpen = false;
        this.update_organisme = null;
      },
      async handleConfirm(organismeData) {
        if (this.update_organisme) {
          const update = {
            ...organismeData,
            id: this.update_organisme.id,
          };
          this.updateOrganism(update);
        } else {
          this.createOrganism(organismeData)
            .then(() => {
              this.closeModal();
              this.setup(true);
            })
            .catch((err) => {
              if (this.isObject(err)) {
                for (const [key, value] of Object.entries(err)) {
                  if (!this.erreurlist[key]) {
                    this.erreurlist[key] = value;
                  }
                }
              } else {
                this.errorS = err;
              }
            });
        }
      },
      isObject(obj) {
        return Object.prototype.toString.call(obj) === "[object Object]";
      },
  
      pagination(paginate) {
        this.page = paginate;
        this.searchorganisme();
      },
  
      setup(refresh = false) {
        if (refresh) this.page = 1;
        this.currenttype = this.filtre_type;
        this.currentcouleur = this.filtre_couleur;
        this.nom = null;
        this.currententreprise = this.filtre_entreprise;
  
        if (this.getOnlineUser) {
          if (this.isSuperAdmin) {
            this.allOrganisms({
              page: this.page,
              per_page: this.perPage,
              nom: this.nom,
              type: this.currenttype,
              couleur:this.currentcouleur,
              region:this.region
            });
          } else {
            this.allOrganisms({
              page: this.page,
              per_page: this.perPage,
              nom: this.nom,
              type: this.currenttype,
              couleur:this.currentcouleur,
              region:this.region,
              entreprise_id: this.getOnlineUser.entreprise.id,
            });
          }
        }
      },
    },
  
    mounted() {
      this.setup(true);
      if (this.isSuperAdmin) {
        this.all_entreprises({
          page: this.page,
          per_page: 10,
          entreprise_id: this.getOnlineUser.entreprise_id,
        });
      }
    },
  
    computed: {
      ...mapGetters([
        "getAllOrganisms",
        "isOrganismsLoading",
        "getTotalOrganismRows",
        "getTabs",
        "getOnlineUser",
        "getAllentreprises",
        "getentrepriseLoading",
      ]),
      isentrepriseAdmin() {
        return this.getOnlineUser.role == "user.entreprise-admin";
      },
      isCommercialBatigo() {
        return this.getOnlineUser.role == "user.commercial-batigo";
      },
      isAssistant() {
        return this.getOnlineUser.role == "user.assistant";
      },
      isSuperAdmin() {
        return this.getOnlineUser.role == "user.super-admin";
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .input-group .icon-balance {
    top: 8px;
  }
  
  .prodimg {
    padding: 8px;
    background: #465081;
    max-width: 64px;
    max-height: 64px;
  }
  
  .option {
    display: flex;
    align-items: center;
  
    .option__image {
      background-color: #465081;
      padding: 4px;
      max-height: 42px;
      max-width: 42px;
    }
  }
  
  .content p {
    font-size: 12px;
    font-weight: 500;
    padding: 3px 0;
    margin: 0;
  }
  
  .w-100 {
    background-color: #f8f8f8;
    padding: 4px;
    overflow-x: auto;
  }
  
  body {
    background: #fafdff;
  }
  
  .actionModel {
    position: relative;
    padding: 15px 0 0;
    display: flex;
    flex-flow: column;
    align-items: center;
  
    .messageError {
      color: #e4261b;
      font-size: 16px;
      background-color: #fff;
      border-color: #fff;
    }
  
    .block-spinner {
      display: flex;
      justify-content: center;
    }
  
    .spinner-border {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  
  .detailClient {
    background-color: #fff;
    box-shadow: 1px 1px 24px #00000019;
  
    .clientTop {
      padding: 16px 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border-bottom: 1px solid #707070;
  
      .prodimg {
        max-width: 185px;
        max-height: 231px;
      }
    }
  
    h4 {
      color: #28367a;
      font-size: 18px;
      font-weight: 600;
      margin: 0;
    }
  
    p {
      color: #515151;
      font-size: 16px;
      font-weight: 500;
    }
  }
  
  .filtre {
    display: flex;
    align-items: center;
  
    .table-count,
    .productTrie {
      margin-right: 15px;
    }
  
    .productTrie {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      overflow: hidden;
      padding: 4px 8px;
      border-radius: 32px;
      background-color: #34c38f;
      color: #fff;
  
      .icons {
        cursor: pointer;
        width: 18px;
        height: 18px;
        margin-left: 4px;
      }
    }
  }
  
  .image-conteneur {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  
    .block {
      cursor: pointer;
      width: 25%;
      margin-bottom: 16px;
      padding: 15px;
  
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
  
      .image-content img {
        width: 100%;
      }
  
      p {
        margin: 0;
        text-align: center;
        color: #303030;
      }
    }
  }
  
  .galerieImage {
    display: flex;
    justify-content: center;
    align-items: center;
  
    img {
      width: 20%;
      max-height: 100px;
    }
  }
  </style>
  