<template>
  <nav class="topbar" v-if="getOnlineUser">
    <div class="brand">
      <a href="/" class="brand-icon">
        <img src="@/assets/Logo@2x.png" class="brand-logo" />
        <!-- <img src="@/assets/Asset_6.png" class="brand-logo-min" /> -->
      </a>

      <!-- <div class="input-group">
        <b-form-input
          @keyup="debounceInput"
          @click="debounceInput"
          v-model="search"
          :placeholder="$t('SEARCH') + ' ...'"
        ></b-form-input>
        <font-awesome-icon icon="search" class="icon-search" v-if="!search" />
      </div> -->

      <div class="searchBlock" v-if="!getLoadingsearch && rows" ref="result">
        <b-tabs content-class="mt-2">
          <b-tab :title="$t('CUSTOMER')" active>
            <p
              v-for="(client, i) in clientSearch"
              :key="i"
              @click="chooseClient(client)"
            >
              {{ client.data.nom }} {{ client.data.prenom }}
            </p>
          </b-tab>
          <b-tab :title="$t('ESTIMATE')">
            <p
              v-for="(devis, i) in devisSearch"
              :key="i"
              @click="chooseDevis(devis)"
            >
              {{ devis.data.id }}-{{ devis.data.client.email }}
            </p>
          </b-tab>
          <b-tab :title="$t('INVOICE')">
            <p
              v-for="(facture, i) in factureSearch"
              :key="i"
              @click="chooseFacture(facture)"
            >
              {{ facture.data.id }}-{{ facture.data.client.email }}
            </p>
          </b-tab>
        </b-tabs>
      </div>
      <div class="searchBlock exp" v-else-if="getLoadingsearch && rows >= 1">
        <b-spinner
          style="width: 24px; height: 24px"
          label="Large Spinner"
        ></b-spinner>
      </div>
    </div>
    <ul class="links">
      <li
        v-if="
          isentrepriseAdmin ||
          isSuperAdmin ||
          isAssistant ||
          isTechnicien ||
          isCommercial ||
          isUserCLient
        "
      >
        <router-link to="/client">
          <span v-if="!isUserCLient">{{ $t("CUSTOMER") }}</span>
          <!-- <span v-else>{{ $t("PERSONAL_SPACE") }}</span> -->
        </router-link>
      </li>
     <!-- devis-->
       <li
        v-if="isentrepriseAdmin || isSuperAdmin || isAssistant || isCommercial"
      >
        <router-link to="/devis/list">{{ $t("ESTIMATE") }}</router-link>
      </li>  
      <li v-if="isUserCLient || isTechnicien">
        <router-link to="/devis/list">{{ $t("ESTIMATE") }}</router-link>
      </li>
      <li
        v-if="isentrepriseAdmin || isSuperAdmin || isAssistant || isCommercial"
      >
        <router-link to="/soon">
          {{ $t("INVOICE") }}
        </router-link>
      </li>
      <li v-if="isUserCLient || isComptable">
        <router-link to="/soon">
          {{ $t("INVOICE") }}
        </router-link>
      </li>
      <li
        v-if="
          isentrepriseAdmin ||
          isSuperAdmin ||
          isAssistant ||
          isTechnicien ||
          isCommercial ||
          isUserCLient ||
          !isComptable
        "
      >
        <router-link to="/agenda">
          {{ $t("AGENDA") }}
        </router-link>
      </li>
      <!-- <li v-if="isentrepriseAdmin || isAssistant">
        <router-link to="/dossiers">{{ $t("Dossiers") }}</router-link>
      </li> -->
    </ul>
    <div class="right" @click="dropdown = true">
      <b-dropdown id="dropdown-right" right class="m-md-2 " no-caret>
        <template #button-content>
          <div class="info">
            <h1>{{ getOnlineUser.nom }}</h1>
            <!-- <h1>{{getOnlineUser}}</h1> -->
            <h2>
              <span v-if="isentrepriseAdmin && getOnlineUser.entreprise"
                >{{ $t("Admin") }} {{ getOnlineUser.entreprise.lib }}</span
              >
              <span v-else-if="isSuperAdmin">{{ $t("SuperAdmin") }}</span>
              <span v-else-if="isAssistant">{{ $t("Assistant") }}</span>
              <span v-else-if="isTechnicien">{{ $t("Technicien") }}</span>
              <span v-else-if="isComptable">{{ $t("Comptable") }}</span>
              <span v-else-if="isCommercial">{{ $t("Commercial") }}</span>
              <span v-else>{{ $t("CUSTOMER") }} </span>
            </h2>
          </div>
          <div class="profil-img">
            <div class="profil">
              <span v-if="getOnlineUser.prenom">{{
                getOnlineUser.prenom[0]
              }}</span>
              <span v-if="getOnlineUser.nom">{{ getOnlineUser.nom[0] }}</span>
            </div>
            <div class="settings">
              <font-awesome-icon
                class="icons"
                :icon="['fas', 'chevron-down']"
              />
            </div>
          </div>
        </template>
        <div v-if="dropdown" class="scrollable-dropdown">
          <b-dropdown-item active class="main-color">{{
            $t("GENERAL")
          }}</b-dropdown-item>

          <li role="presentation" @click="dropdown = false">
            <router-link
              to="/Personal_information"
              class="dropdown-item"
              role="menuitem"
              >{{ $t("PERSONAL_INFO") }}</router-link
            >
          </li>
          <li
            role="presentation"
            v-if="!isSuperAdmin && !isUserCLient"
            @click="dropdown = false"
          >
            <router-link
              to="/Entreprise_information"
              class="dropdown-item"
              role="menuitem"
              >{{ $t("COMPANY_INFO") }}</router-link
            >
          </li>
          <li role="presentation" v-if="isSuperAdmin" @click="dropdown = false">
            <router-link
              to="/Entreprise_list"
              class="dropdown-item"
              role="menuitem"
              >{{ $t("CUSTOMERS_BATIGO") }}</router-link
            >
          </li>
          <li role="presentation" @click="dropdown = false">
            <router-link
              to="/how-it-work"
              class="dropdown-item"
              role="menuitem"
              >{{ $t("HELP") }}</router-link
            >
          </li>
          <li @click="showdropdown">
            <p style="margin: 0" class="dropdown-item">
              {{ $t("TRADUCTION") }}
            </p>
          </li>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item
            active
            class="main-color"
            v-if="isentrepriseAdmin || isSuperAdmin || isAssistant"
          >
            {{ $t("ENTITY") }}
          </b-dropdown-item>

          <li
            role="presentation"
            v-if="isentrepriseAdmin || isSuperAdmin || isAssistant"
            @click="dropdown = false"
          >
            <router-link to="/fourniture" class="dropdown-item" role="menuitem">
              {{ $t("Product management") }}
            </router-link>
          </li>

          <li
            role="presentation"
            v-if="isentrepriseAdmin || isSuperAdmin || isAssistant"
            @click="dropdown = false"
          >
            <router-link to="/forfait" class="dropdown-item" role="menuitem">
              {{ $t("FORFAIT") }}
            </router-link>
          </li>

          <li
            role="presentation"
            v-if="isentrepriseAdmin || isSuperAdmin || isAssistant"
            @click="dropdown = false"
          >
            <router-link to="/mainoeuvre" class="dropdown-item" role="menuitem">
              {{ $t("MAN") }}
            </router-link>
          </li>
          <li
            role="presentation"
            v-if="isentrepriseAdmin || isSuperAdmin || isAssistant"
            @click="dropdown = false"
          >
            <router-link to="/pieces" class="dropdown-item" role="menuitem">{{
              $t("ROOM")
            }}</router-link>
          </li>
          <li
            role="presentation"
            v-if="isentrepriseAdmin || isSuperAdmin || isAssistant"
            @click="dropdown = false"
          >
            <router-link to="/travaux" class="dropdown-item" role="menuitem">{{
              $t("WORKS")
            }}</router-link>
          </li>

          <li role="presentation" v-if="isSuperAdmin" @click="dropdown = false">
            <router-link
              to="/gallery"
              class="dropdown-item"
              role="menuitem"
              @click="dropdown = false"
              >{{ $t("GALLERY") }}</router-link
            >
          </li>
          <li role="presentation" v-if="isSuperAdmin" @click="dropdown = false">
            <router-link
              to="/fichier"
              class="dropdown-item"
              role="menuitem"
              @click="dropdown = false"
              >{{ $t("CATEGORY_FILE") }}</router-link
            >
          </li>
          <li
            role="presentation"
            v-if="isentrepriseAdmin"
            @click="dropdown = false"
          >
            <router-link
              to="/visibilite-categories"
              class="dropdown-item"
              role="menuitem"
              @click="dropdown = false"
              >{{ $t("CATVISIB") }}</router-link
            >
          </li>
          <li
            role="presentation"
            v-if="isentrepriseAdmin"
            @click="dropdown = false"
          >
            <router-link
              to="/visibilite-metier"
              class="dropdown-item"
              role="menuitem"
              @click="dropdown = false"
              >{{ $t("VISIBILITE-METIER") }}</router-link
            >
          </li>
         <li
            role="presentation"
            v-if="isentrepriseAdmin || isSuperAdmin || isAssistant"
            @click="dropdown = false"
          >
            <router-link
              to="/sous-traitant"
              class="dropdown-item"
              role="menuitem"
              @click="dropdown = false"
              >{{ $t("SOUS-TRAITANT") }}</router-link
            >
          </li>
          <li
          role="presentation"
          v-if="isentrepriseAdmin || isSuperAdmin || isAssistant"
          @click="dropdown = false"
        >
          <router-link
            to="/Certificat-RGE"
            class="dropdown-item"
            role="menuitem"
            @click="dropdown = false"
            >{{ $t("certificat_rge") }}</router-link
          >
        </li>
          <li
          role="presentation"
          v-if="isentrepriseAdmin || isSuperAdmin || isAssistant"
          @click="dropdown = false"
        >
          <router-link
            to="/Entites-Externes"
            class="dropdown-item"
            role="menuitem"
            @click="dropdown = false"
            >{{ $t("ENTITE_EXTERNE") }}</router-link
          >
        </li>
        <li
          role="presentation"
          v-if="isentrepriseAdmin || isSuperAdmin || isAssistant"
          @click="dropdown = false"
        >
          <router-link
            to="/soon"
            class="dropdown-item"
            role="menuitem"
            @click="dropdown = false"
            >{{ $t("Organisme") }}</router-link
          >
        </li>
          <!-- <li
            role="presentation"
            v-if="isentrepriseAdmin || isSuperAdmin || isAssistant"
            @click="dropdown = false"
          >
            <router-link
              to="/delegataire"
              class="dropdown-item"
              role="menuitem"
              @click="dropdown = false"
              >{{ $t("DELEGATAIRE") }}</router-link
            >
          </li> -->
          <li role="presentation" v-if="isSuperAdmin" @click="dropdown = false">
            <router-link
              to="/categorie-article"
              class="dropdown-item"
              role="menuitem"
              @click="dropdown = false"
              >{{ $t("Catégories produits") }}</router-link
            >
          </li>
          <li role="presentation" v-if="isSuperAdmin" @click="dropdown = false">
            <router-link
              to="/categorie-metier"
              class="dropdown-item"
              role="menuitem"
              @click="dropdown = false"
              >{{ $t("CATMETIER") }}</router-link
            >
          </li>
          <b-dropdown-divider v-if="isentrepriseAdmin"></b-dropdown-divider>

          <b-dropdown-item active class="main-color" v-if="isentrepriseAdmin">
            {{ $t("ACCESS") }}
          </b-dropdown-item>
          <li
            role="presentation"
            v-if="isentrepriseAdmin"
            @click="dropdown = false"
          >
            <router-link
              to="/users"
              class="dropdown-item"
              role="menuitem"
              @click="dropdown = false"
              >{{ $t("USER") }}</router-link
            >
          </li>
          <!-- <li
            role="presentation"
            v-if="isFirstAdmin"
            @click="dropdown = false"
          >
            <router-link
              to="/abonnements"
              class="dropdown-item"
              role="menuitem"
              @click="dropdown = false"
              >{{ $t("SUBSC_MANAGEMENT") }}</router-link
            >
          </li> -->

          <b-dropdown-divider></b-dropdown-divider>

          <b-dropdown-item
            active
            class="main-color"
            v-if="isSuperAdmin"
            @click="dropdown = false"
          >
            {{ $t("TRADUCTION") }}
          </b-dropdown-item>

          <li role="presentation" v-if="isSuperAdmin" @click="dropdown = false">
            <router-link to="/langue" class="dropdown-item" role="menuitem">{{
              $t("LANGUAGES")
            }}</router-link>
          </li>

          <li role="presentation" v-if="isSuperAdmin" @click="dropdown = false">
            <router-link
              to="/dictionnaire-vitrine"
              class="dropdown-item"
              role="menuitem"
              >{{ $t("DIC_VITRINE") }}</router-link
            >
          </li>

          <li role="presentation" v-if="isSuperAdmin" @click="dropdown = false">
            <router-link
              to="/dictionnaire"
              class="dropdown-item"
              role="menuitem"
              >{{ $t("DICTIONARY") }}</router-link
            >
          </li>
          <li role="presentation" @click="dropdown = false">
            <a
              href="#"
              @click="loggingout()"
              class="dropdown-item"
              role="menuitem"
            >
              <strong class="red">{{ $t("LOGOUT") }}</strong>
            </a>
          </li>
        </div>
      </b-dropdown>
      <b-dropdown
        id="dropdown-info"
        right
        no-caret
        v-if="this.utilsRouterLinkActive != 'Notifications'"
      >
        <template #button-content>
          <div class="notification" :class="{ blue: !getNotifRows }">
            <font-awesome-icon icon="fa-solid fa-bell" class="bell" />
            <!-- <font-awesome-icon icon="fa-solid fa-bell-slash" v-else /> -->
            <p class="numb" v-if="getNotifRows">{{ getNotifRows }}</p>
          </div>
        </template>
        <div>
          <div class="head">
            <h1>{{ $t("NOTIFICATION") }}</h1>
            <font-awesome-icon
              :title="$t('DELETE')"
              icon="trash"
              style="font-size: 30px"
              class="icons"
              @click="delete_notifs"
            />
          </div>
          <div class="filtre">
            <div class="d-flex">
              <div
                class="tags mr-2"
                :class="{ select: filtreOn }"
                @click="getNotif"
              >
                {{ $t("ALL") }}
              </div>
              <div
                class="tags"
                :class="{ select: !filtreOn }"
                @click="getNotif(true)"
              >
                {{ $t("UNREAD") }}
              </div>
            </div>
            <router-link to="/notifications" class="tags">
              {{ $t("SEE_ALL") }}
            </router-link>
          </div>
          <div class="block" v-if="getNotifs.length > 0">
            <div
              class="notif"
              :class="{ notseen: !notif.seen }"
              v-for="(notif, i) in getNotifs"
              :key="i"
              @click="chooseActivity(notif)"
            >
              <div class="icone">
                <svg
                  v-if="notif.message == 'Rendez vous'"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 20 21.688"
                >
                  <g
                    id="Icon_feather-calendar"
                    data-name="Icon feather-calendar"
                    transform="translate(0.75 0.75)"
                  >
                    <path
                      id="Tracé_78085"
                      data-name="Tracé 78085"
                      d="M6.519,6H20.65a2.019,2.019,0,0,1,2.019,2.019V22.15a2.019,2.019,0,0,1-2.019,2.019H6.519A2.019,2.019,0,0,1,4.5,22.15V8.019A2.019,2.019,0,0,1,6.519,6Z"
                      transform="translate(-4.5 -3.981)"
                      fill="none"
                      stroke="#28367a"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Tracé_78086"
                      data-name="Tracé 78086"
                      d="M24,3V7.037"
                      transform="translate(-10.878 -3)"
                      fill="none"
                      stroke="#28367a"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Tracé_78087"
                      data-name="Tracé 78087"
                      d="M12,3V7.037"
                      transform="translate(-6.953 -3)"
                      fill="none"
                      stroke="#28367a"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Tracé_78088"
                      data-name="Tracé 78088"
                      d="M4.5,15H22.669"
                      transform="translate(-4.5 -6.925)"
                      fill="none"
                      stroke="#28367a"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </g>
                </svg>
                <svg
                  v-else-if="notif.message == 'Appel'"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18.002"
                  height="18.001"
                  viewBox="0 0 18.002 18.001"
                >
                  <path
                    id="ios-call"
                    d="M21.055,17.857a14.34,14.34,0,0,0-2.993-2c-.9-.431-1.226-.422-1.86.035-.529.382-.87.737-1.478.6a8.821,8.821,0,0,1-2.971-2.2,8.749,8.749,0,0,1-2.2-2.971c-.129-.613.227-.95.6-1.478.457-.635.47-.964.035-1.86a14.049,14.049,0,0,0-2-2.993c-.653-.653-.8-.511-1.159-.382a6.547,6.547,0,0,0-1.061.564A3.2,3.2,0,0,0,4.7,6.52c-.253.546-.546,1.563.946,4.218a23.533,23.533,0,0,0,4.138,5.519l0,0,0,0a23.624,23.624,0,0,0,5.519,4.138c2.655,1.492,3.672,1.2,4.218.946a3.145,3.145,0,0,0,1.345-1.274,6.637,6.637,0,0,0,.564-1.061c.129-.36.275-.506-.382-1.159Z"
                    transform="translate(-4.015 -4.032)"
                    fill="none"
                    stroke="#28367a"
                    stroke-width="1"
                  />
                </svg>
                <svg
                  v-else-if="notif.message == 'Tache'"
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.058"
                  height="17.058"
                  viewBox="0 0 17.058 17.058"
                >
                  <g id="request-quote" transform="translate(-4.5 -2.25)">
                    <path
                      id="Tracé_2346"
                      data-name="Tracé 2346"
                      d="M15.466,14.434V18.09H5.718V3.468h6.092V2.25H5.718A1.218,1.218,0,0,0,4.5,3.468V18.09a1.218,1.218,0,0,0,1.218,1.218h9.747a1.218,1.218,0,0,0,1.218-1.218V14.434Z"
                    />
                    <path
                      id="Tracé_2347"
                      data-name="Tracé 2347"
                      d="M23.155,4.542l-2.01-2.01a.975.975,0,0,0-1.365,0l-8.529,8.529v3.375H14.62l8.529-8.529a.975.975,0,0,0,0-1.365Zm-9.041,8.675H12.469V11.572L18.22,5.815l1.651,1.651Zm6.616-6.61L19.079,4.956l1.383-1.383,1.651,1.651Z"
                      transform="translate(-3.095 -0.002)"
                    />
                  </g>
                </svg>
                <svg
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  width="16.194"
                  height="16.889"
                  viewBox="0 0 16.194 16.889"
                >
                  <path
                    id="notification"
                    d="M19.379,13.144,17.7,11.463V8.6a6.271,6.271,0,0,0-5.6-6.228,2.626,2.626,0,0,0-1.246,0A6.321,6.321,0,0,0,5.242,8.6v2.865L3.561,13.144a.567.567,0,0,0-.187.436v1.868A.585.585,0,0,0,4,16.071H8.356a3.114,3.114,0,1,0,6.228,0h4.359a.585.585,0,0,0,.623-.623V13.58A.567.567,0,0,0,19.379,13.144ZM11.47,17.939A1.868,1.868,0,0,1,9.6,16.071h3.737a1.868,1.868,0,0,1-1.868,1.868Zm6.85-3.114H4.619v-1L6.3,12.148a.567.567,0,0,0,.187-.436V8.6a4.982,4.982,0,0,1,9.964,0v3.114a.567.567,0,0,0,.187.436l1.682,1.682Z"
                    transform="translate(-3.373 -2.296)"
                  />
                </svg>
              </div>
              <div class="infos">
                <h3>{{ notif.message }}</h3>
                <h4>{{ moment(notif.date).format("dddd Do MMMM, hh:mm") }}</h4>
                <p>{{ moment(notif.created_at).startOf("day").fromNow() }}</p>
              </div>
              <div class="seen">
                <div class="round" v-if="!notif.seen"></div>
              </div>
            </div>
          </div>
          <div class="blockvide" v-else>
            <div class="bell">
              <p>{{ $t("NO_NOTIF") }}</p>
            </div>
          </div>
        </div>
      </b-dropdown>
    </div>

    <div class="toggle-button">
      <div>
        <b-button v-b-toggle.sidebar-backdrop
          ><font-awesome-icon class="icon" :icon="['fas', 'bars']"
        /></b-button>

        <b-sidebar id="sidebar-backdrop" backdrop shadow>
          <template #header="{ hide }">
            <div class="sidebar-header">
              <div class="user">
                <div class="profil">
                  <span v-if="getOnlineUser.prenom">{{
                    getOnlineUser.prenom[0]
                  }}</span>
                  <span v-if="getOnlineUser.nom">{{
                    getOnlineUser.nom[0]
                  }}</span>
                </div>
                <div class="info">
                  <h1>{{ getOnlineUser.prenom }} {{ getOnlineUser.nom }}</h1>
                  <h2>{{ getOnlineUser.role.slice(5) }}</h2>
                </div>
              </div>
              <b-button size="sm" @click="hide" id="hide">
                <img src="@/assets/Icon material-close.png" />
              </b-button>
            </div>
          </template>
          <nav class="mb-3">
            <b-nav vertical>
              <li
                class="nav-item"
                v-if="
                  isentrepriseAdmin ||
                  isSuperAdmin ||
                  isAssistant ||
                  isUserCLient
                "
              >
                <router-link to="/client" aria-current="page" class="nav-link">
                  <span v-if="!isUserCLient">{{ $t("CUSTOMER") }}</span>
                  <span v-else>{{ $t("PERSONAL_SPACE") }}</span>
                </router-link>
              </li>
              <li
                class="nav-item"
                v-if="
                  isentrepriseAdmin ||
                  isSuperAdmin ||
                  isAssistant ||
                  isCommercial
                "
              >
                <router-link to="/devis/list" aria-current="page" class="nav-link">{{
                  $t("ESTIMATE")
                }}</router-link>
              </li>
              <li
                class="nav-item"
                v-if="
                  isentrepriseAdmin ||
                  isSuperAdmin ||
                  isAssistant ||
                  isComptable ||
                  isCommercial
                "
              >
              <router-link
                  to="/soon"
                  aria-current="page"
                  class="nav-link"
                  >{{ $t("INVOICE") }}</router-link
                > 
                <!-- <router-link
                  to="/facture"
                  aria-current="page"
                  class="nav-link"
                  >{{ $t("INVOICE") }}</router-link
                > -->
              </li>
              <li
                class="nav-item"
                v-if="
                  isentrepriseAdmin ||
                  isSuperAdmin ||
                  isAssistant ||
                  isTechnicien ||
                  isCommercial ||
                  isUserCLient ||
                  !isComptable
                "
              >
                <router-link
                  to="/agenda"
                  aria-current="page"
                  class="nav-link"
                  >{{ $t("AGENDA") }}</router-link
                >
              </li>

              <!-- <li v-if="isentrepriseAdmin || isAssistant">
                <router-link to="/dossiers" class="nav-link">
                  {{ $t("Dossiers") }}
                </router-link>
              </li> -->
            </b-nav>
          </nav>
        </b-sidebar>
      </div>
    </div>
    <b-modal
      id="languageModal"
      ref="languageModal"
      modal-class="cutsom-modal-bootstrap"
      hide-footer
    >
      <template #modal-header="{ close }">
        <h5>{{ $t("LANGUAGES") }}</h5>
        <b-button size="sm" @click="close()">
          <font-awesome-icon icon="times" style="font-size: 30px" />
        </b-button>
      </template>
      <div class="langues">
        <div
          class="langue"
          v-for="(lang, i) in getAllLangue"
          :key="i"
          @click="changeLang(lang)"
        >
          {{ lang.lib }}
        </div>
      </div>
    </b-modal>
    <b-modal id="langModal" ref="langModal" hide-footer>
      <template #modal-header="{ close }">
        <h5>{{ $t("LANGUAGES") }}</h5>
        <b-button size="sm" @click="close()">
          <font-awesome-icon icon="times" style="font-size: 30px" />
        </b-button>
      </template>
      <div class="langues">
        <div
          class="lang"
          v-for="(lang, i) in getActiveLangue"
          :key="i"
          @click="changeLangtra(lang)"
        >
          <font-awesome-icon
            icon="check"
            v-if="getSelectedLang.id == lang.id"
          />
          {{ lang.lib }}
        </div>
      </div>
    </b-modal>
  </nav>
  <nav class="topbar" v-else>
    <div class="brand">
      <router-link to="/" class="brand-icon">
        <img src="@/assets/Logo@2x.png" class="brand-logo" />
        <!-- <img src="@/assets/Asset_6.png" class="brand-logo-min" /> -->
      </router-link>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash";

export default {
  name: "batigoNavBar",
  data() {
    return {
  
      items: [
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me 2' },
      ],
 
      isDropdownVisible: false,
      newDIC: {
        lang: null,
      },
      selectedlangs: {
        iso_code: "fr",
        lib: "francais",
      },
      search: null,
      per_page: 10,
      page: 1,
      rows: null,
      dropdown: true,
      filtreOn: true,
    };
  },
  methods: {
    ...mapActions([
      "allActiveLangues",
      "onlineUser",
      "logout",
      "allLangue",
      "saveLang",
      "searches",
      "clearSearches",
      "dictionnaire",
      "pays",
      "all_notifs",
      "edit_notifs",
      "delete_notifs",
    ]),
    openDropdown() {
      this.isDropdownVisible = true;
     
    },
    closeDropdown() {
      this.isDropdownVisible = false;
    },
    goToRoute(route) {
      this.$router.push(route);
    },
    moment: function (date) {
      return moment(new Date(date)).locale(this.getSelectedLang.iso_code);
    },
    changeLangtra(lang) {
      this.saveLang(lang).then(() => {
        this.$refs["langModal"].hide();
      });

      var lang_p;
      switch (this.getSelectedLang.iso_code) {
        case "fr":
          lang_p = "fra";
          break;
        case "pl":
          lang_p = "pol";
          break;
        case "prt":
          lang_p = "por";
          break;
        case "IT":
          lang_p = "ita";
          break;
        case "ar":
          lang_p = "ara";
          break;
        case "Gr":
          lang_p = "deu";
          break;
        default:
          lang_p = "eng";
      }
      this.pays(lang_p);
    },
    debounceInput: _.debounce(function (e) {
      this.rows = 1;
      this.search = e.target.value;
      if (this.search === null || this.search === "") {
        this.clearSearches();
        this.rows = 0;
      } else {
        this.searches({
          page: this.page,
          per_page: this.per_page,
          search: this.search,
        }).then(() => {
          this.rows = this.getSearchRows;
        });
      }
    }, 1000),

    changeLang(lang) {
      this.saveLang(lang).then(() => {
        this.$refs["languageModal"].hide();
      });
    },
    loggingout() {
      this.logout();
    },
    chooseClient(client) {
      this.rows = 0;
      this.search = "";
      if (this.$route.name != "Client")
        this.$router.push("/client?id=" + client.data.id);
    },

    chooseDevis(devis) {
      this.rows = 0;
      if (this.$route.name != "Devis List") {
        this.search = "";
        if (devis.data.devis)
          this.$router.push("/devis/list?id=" + devis.data.id);
      }
    },

    chooseFacture(facture) {
      this.rows = 0;
      this.search = "";
      if (this.$route.name != "Facture List")
        this.$router.push("/facture/list?id=" + facture.data.id);
    },

    chooseActivity(activity) {
      this.edit_notifs(activity);
      if (this.$route.name != "Client" && activity.id_ref)
        this.$router.push("/client?id=" + activity.id_ref);
    },

    getNotif(bool = false) {
      if (bool == true) {
        this.filtreOn = false;
        this.all_notifs({ seen: 0 });
      } else {
        this.filtreOn = true;
        this.all_notifs();
      }
    },

    showdropdown() {
      this.allActiveLangues({
        page: 1,
        per_page: 100,
      }).then(() => {
        this.$refs["langModal"].show();
      });
    },

    close(e) {
      if (this.$refs["result"])
        if (!this.$refs["result"].contains(e.target)) {
          this.rows = 0;
        }
    },
  },

  computed: {
    ...mapGetters([
      "getOnlineUser",
      "getAllLangue",
      "getDomain",
      "getSelectedLang",
      "getLoadingtraduction",
      "getSearches",
      "getSearchRows",
      "getlang",
      "getLoadingsearch",
      "getNotifs",
      "getNotifLoading",
      "getNotifRows",
      "getActiveLangue",
    ]),

    utilsRouterLinkActive: function () {
      return this.$route.name;
    },
    computedImageLang() {
      return function (data) {
        if (data && data.img) {
          return {
            "background-image": "url(" + this.getDomain + `${data.img}` + ")",
          };
        } else {
          return { "background-color": "#dcd9d9" };
        }
      };
    },
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },
    isFirstAdmin() {
      return this.getOnlineUser.first_admin;
    },
    isAssistant() {
      return this.getOnlineUser.role == "user.assistant";
    },
    isTechnicien() {
      return this.getOnlineUser.role == "user.technicien";
    },
    isComptable() {
      return this.getOnlineUser.role == "user.comptable";
    },
    isCommercial() {
      return this.getOnlineUser.role == "user.commercial-entreprise";
    },
    isUserCLient() {
      return this.getOnlineUser.role == "user.client";
    },
    clientSearch() {
      var client = [];
      this.getSearches.forEach((result) => {
        if (result.type == "client") client.push(result);
      });

      return client;
    },

    devisSearch() {
      var devis = [];
      this.getSearches.forEach((result) => {
        if (result.type == "document.devis") devis.push(result);
      });

      return devis;
    },

    factureSearch() {
      var facture = [];
      this.getSearches.forEach((result) => {
        if (result.type == "document.facture") facture.push(result);
      });

      return facture;
    },
  },
  mounted() {
    this.clearSearches();
    document.addEventListener("click", this.close);
    if (this.utilsRouterLinkActive != "Notifications") this.all_notifs();
    // this.allLangue({
    //   page: 1,
    //   per_page: 100,
    // });
    // if (localStorage.getItem("SelectedLang")) {
    //   this.dictionnaire(JSON.parse(localStorage.getItem("SelectedLang")));
    // } else {
    //   this.saveLang(this.getlang);
    // }
  },

  beforeDestroy() {
    document.removeEventListener("click", this.close);
  },
};
</script>

<style lang="scss">
  /* Style du bouton */


.dropdown-btn {
  background-color: #007bff;
  text-align: center; 
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.dropdown-content {
  cursor: pointer;
  display: none;
  position: absolute; 
  top: 100%; /* Juste en dessous du bouton */
  left: 50%; /* Centre horizontalement par rapport au bouton */
  transform: translateX(-50%); /* Centre exactement le menu */
  background-color: #f9f9f9;
  min-width: 200px; /* Largeur minimale du menu */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.dropdown-content a {
  text-align: center; 
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Affiche le menu au survol */
.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

  .v-menu {
  z-index: 1000;
}

  .scrollable-dropdown {
  max-height: 400px; 
  overflow-y: auto;
}
.dropdown.b-dropdown.no-box-shadow.show.btn-group:hover {
  text-decoration: none !important;
}
div .dropdown[show="true"] {
  text-decoration: none !important;
}
.dropdown-item:hover {
    text-decoration: underline !important; 
     cursor: pointer;
}
  .nav-link{
    text-decoration: none !important;
  }
 .dropdown-wrapper {
  position: relative;
  display: inline-block;
}
a:hover {
  text-decoration: none !important;
}

.dropdown-btn {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
    box-shadow: none !important;
}
.topbar .links a.router-link-active::before  ::hover{
  text-decoration: none !important;
}
.topbar .links a.router-link-active ::before, ::after {
  text-decoration: none !important;
    -webkit-text-decorations-in-effect: none !important;
}
.dropdown-select {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0; 
  cursor: pointer;
} 
   .no-box-shadow  .dropdown-menu {
  box-shadow: none !important;
}
.topbar .right .langue .btn-secondary {
  border-radius: 50% !important;
  padding: 6px 10px;
  text-transform: uppercase;
  width: 40px;
  height: 40px;
  border-radius: 5px !important;
}

.topbar {
  @media only screen and (max-width: 900px) {
    padding: 2px 16px;
  }
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1002;
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 32px;
  box-shadow: 0px 2px 2px 0px #00000019;
  background-color: #fff;

  .brand {
    @media only screen and (max-width: 900px) {
      order: 2;
    }
    position: relative;
    display: flex;
    align-items: center;

    .input-group {
      @media only screen and (max-width: 1000px) {
        display: none;
      }
      margin-left: 18px;
      position: relative;

      .icon-search {
        @media only screen and (max-width: 900px) {
          display: none;
        }
        overflow: visible;
        position: absolute;
        width: 15px;
        height: 15px;
        left: 13px;
        top: 9px;
        color: #393939;
      }
    }

    input {
      border: none;
      width: 100%;
      min-width: 220px;
      border-radius: 5px !important;
      background-color: #f5f6f8;
      height: 35px;
      color: #393939;
      font-size: 12px;
      padding-left: 42px;
    }

    .exp {
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .searchBlock {
      box-shadow: 1px 1px 4px #030c1e;
      max-height: 300px;
      width: 320px;
      background: #fff;
      overflow: auto;
      position: fixed;
      top: 50px;
      left: 100px;

      p {
        cursor: pointer;
        padding: 6px;
        background-color: #f8f8f8;
        width: 99%;
        margin: 0 auto 4px;
        font-size: 12px;
      }

      .nav-tabs {
        justify-content: space-between;
        align-items: center;
        .nav-item {
          width: 33%;
        }
        .nav-link {
          background-color: #24316d;
          color: #fff;
          padding: 8px;
          border-radius: 0;
          font-size: 12px;
        }

        .active {
          border: none;
          background-color: #e4261b;
        }
      }
    }
  }

  .brand .brand-icon {
    .brand-logo {
      @media only screen and (max-width: 900px) {
        display: none;
      }
      width: 84px;
      height: 32px;
      @media only screen and (max-width: 900px) {
        display: block;
      }
    }

  }

  .links {
    @media only screen and (max-width: 900px) {
      display: none;
    }
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    text-decoration: none !important;
    & a {
      position: relative;
      padding: 8px 16px;
      text-decoration: none !important;
      color: rgba(#0f0f0f, 0.75);
      transition: all 0.2s ease;
      font-size: 13px;

      &::hover {
        text-decoration: none !important;
      }

      &::after {
        display: none;
        content: "";
        width: 28px;
        border-bottom: 3px solid #e4261b;
        position: absolute;
        right: 30%;
        bottom: 5px;
      }
    }
    a.router-link-active {
      font-size: 16px;
      font-weight: 600;
      color: #28367a;
      font-weight: 600;

      &::after {
        display: block;
      }
    }
  }

  .right {
    @media only screen and (max-width: 900px) {
      order: 3;
    }
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .info {
      @media only screen and (max-width: 900px) {
        display: none;
      }
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-right: 8px;

      h1,
      h2 {
        margin: 0;
        text-align: right;
      }
      h1 {
        font-size: 16px;
        font-weight: 500;
        color: #545454;
      }

      h2 {
        font-size: 12px;
        color: #aaaaaa;
      }
    }

    .profil-img {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 39px;
      border-radius: 50%;
      background-color: #28367a;
      color: #fff;
      font-size: 15px;
    }
    .settings {
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: #e4261b;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .topbar .right .btn-secondary {
        padding: 0;
      }

      .icons {
        font-size: 12px;
      }

      .dropdown-item.active,
      .dropdown-item:active {
        background-color: #e9e9e9;
        color: #28367a;
        font-weight: 600;
      }
    }

    .btn-secondary {
      background-color: transparent !important;
      border: none !important;
      box-shadow: none !important;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .notification {
    @media only screen and (max-width: 900px) {
      padding: 7px 13px;
    }
    margin-left: 4px;
    position: relative;
    cursor: pointer;
    background-color: #e4261b;
    padding: 7px 14px;
    border-radius: 50%;

    .bell {
      color: #fff;
      font-size: 15px;
    }

    .numb {
      position: absolute;
      top: -6px;
      right: -12px;
      background: #000;
      color: #fff;
      border-radius: 50%;
      width: 24px;
      font-size: 12px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .blockvide {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

    .bell {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p {
        @media only screen and (max-width: 900px) {
          margin: 0;
        }
        margin: 4px 0 0;
        font-size: 14px;
        color: #595959;
      }
    }
  }

  .blue {
    background-color: #28367a;
    color: #fff;
  }

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 12px;

    h1 {
      color: #000;
      font-size: 20px;
      font-weight: 800;
      margin-bottom: 0;
    }

    .icons {
      color: #000;
      width: 14px;
      cursor: pointer;

      :hover {
        color: red;
      }
    }

    .del_all {
      cursor: pointer;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-weight: 800;
      padding-bottom: 6px;
      border-radius: 50%;
      letter-spacing: 1.5px;

      &:hover {
        background-color: rgba(#fff, 0.1);
      }
    }
  }

  .filtre {
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tags {
      padding: 6px 16px;
      border-radius: 18px;
      color: #000;
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 600;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        background-color: rgba(#000, 0.1);
      }
    }

    .select {
      background-color: #34c38f;
      color: #fff;
      &:hover {
        background-color: rgba(#34c38f, 0.7);
      }
    }
  }

  #dropdown-info .dropdown-menu.show {
    background: #fff;
    max-width: 400px;
    min-width: 350px;
    padding: 0 0 2px;
  }

  .block {
    max-height: calc(100vh - 290px);
    overflow: auto;

    .notif:last-child {
      border-bottom: 1px solid #d5d5d5;
    }
  }

  .notseen {
    background-color: #f8f8f8 !important;
  }

  .notif {
    display: flex;
    align-items: flex-start;
    padding: 10px;
    background-color: #fff;
    border-top: 1px solid #d5d5d5;
    cursor: pointer;

    &:hover {
      background-color: #fcfcfc;
    }

    .icone {
      display: flex;
      justify-content: flex-start;
      width: 10%;
    }
    .infos {
      width: 80%;

      h3,
      h4,
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      h3 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0;
        color: #595959;
      }

      h4 {
        font-size: 13px;
        color: #28367a;
        margin-bottom: 4px;
      }

      p {
        font-size: 12px;
        margin: 0;
        color: #cccccc;
      }
    }
    .seen {
      width: 10%;
      display: flex;
      justify-content: flex-end;

      .round {
        height: 12px;
        width: 12px;
        background-color: #28367a;
        border-radius: 50%;
      }
    }
  }

  .langue {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    .langue__title {
      margin: 0;
      font-size: 10px;
      margin-right: 8px;
    }

    .globe {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: #eeeeee;
    }

    .languages {
      position: absolute;
      top: 100%;
      right: 0;
      padding: 8px;
      width: 245px;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      background-color: #fff;
      background-clip: padding-box;
      max-height: 30vh;
      overflow-y: auto;
      overflow-x: hidden;

      ul {
        list-style: none;
        margin: 0;

        li {
          padding: 8px 24px;
          background-color: #f8f8f8;
          border-radius: 5px;
          margin-bottom: 4px;
          font-size: 12px;

          &:hover {
            background-color: #e9ecef;
          }
        }

        & .select {
          background-color: #28367a !important;
          color: #fff;
        }
      }
    }
  }

  .toggle-button {
    @media only screen and (max-width: 900px) {
      display: block;
      order: 1;
    }
    display: none;

    .icon {
      font-size: 20px;
      right: 0;
    }

    .btn {
      background-color: #28367a;
      color: #fff;
      display: flex;
    }

    #sidebar-backdrop {
      width: 230px;
    }

    .b-sidebar-header {
      padding: 12px;
      justify-content: center;
      border-bottom: 2px solid #e9ecef;
      position: relative;

      #hide {
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 0;
        background-color: transparent;
        border: none;
      }

      .user {
        display: flex;
        flex-direction: column;
        align-items: center;

        & .profil {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          background-color: #e4261b;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .info {
          display: flex;
          flex-direction: column;
          padding: 8px 0 0;

          h1 {
            font-size: 16px;
            font-weight: 600;
            color: #545454;
          }

          h2 {
            font-size: 14px;
            color: #aaaaaa;
          }
        }
      }
    }

    .b-sidebar-body {
      /* overflow-y: scroll; */

      a.router-link-exact-active {
        margin: 0;
        padding-left: 24px;
        background-color: #e4261b;
        color: #fff;
        transition: all 0.2s ease;

        &:hover {
          background-color: rgba(#28367a, 0.8);
        }
      }
    }

    .nav-link {
      padding: 16px 8px;
      margin-left: 16px;
      border-bottom: 2px solid #e9ecef;
      transition: all 0.2s ease;
      &:hover {
        background-color: #28367a;
        color: #fff;
      }
    }
  }
}
.langues {
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
  .lang {
    padding: 10px 24px;
    color: #62646a;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;

    &:hover {
      background-color: #e9ecef;
    }
  }
}
.logo {
  width: 100px;
}

.logo img {
  width: 100px;
}

.infoBox {
  background-color: #28367a;
}
</style>
