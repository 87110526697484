<template>
  <div class="scroll-container ">
    <!-- Tabs -->

    <v-tabs
      v-if="visibleTabs.length > 1"
      v-model="step"
      bg-color="deep-purple-darken-4"
      class="justify-center"
      center-active
    >
      <v-tab v-for="(tab, index) in visibleTabs" :key="index" class="mb-3">
        {{ tab }}
      </v-tab>
    </v-tabs>
    <!-- Dynamic Content -->
    <div v-for="(tab, index) in visibleTabs" :key="index" class="mt-5">
      <div
        v-if="step === index"
        class="d-flex justify-content-center align-items-center flex-column "
      >
        <div class="col-lg-12 col-sm-12 col-md-10 borderPointier mb-2 ">
          <!-- Form -->
          <form class="my-4 w-100 ">
            <div class=" ">
              <div class="center">
           
<div class="container-wrappe">
  <div class="d-flex justify-content-end mt-4 px-0">
                  <b-dropdown
                    :text="$t('ADD')"
                    class="button-pre-style mb-2"
                    variant="primary"
                    right
                  >
                    <b-dropdown-item
                      @click="addRow('Product', step)"
                      :disabled="disabledGroups[index]"
                    >
                      {{ $t("PRODUIT") }}</b-dropdown-item
                    >
                    <b-dropdown-item @click="addRow('Forfait', step)">{{
                      $t("FORFAIT")
                    }}</b-dropdown-item>
                    <b-dropdown-item @click="addRow('Main d\'oeuvre', step)">{{
                      $t("MAIN_D_OEUVRE")
                    }}</b-dropdown-item>
                  </b-dropdown>
                </div> 
              </div>
                <div class="d-flex justify-content-center ">
                  <v-container v-if="progress" type="image" class="loding">
                    <v-skeleton-loader type="image"></v-skeleton-loader>
                  </v-container>
                  <div v-else class="borders ">
                    <div class=" colorred">
                      <div class="content-wrapper colorred">
                        <!--header-->

                        <div class="header d-flex  px-3 pt-2">
                          <div class="header-item" style="flex: 2.5">
                            {{ $t("PRODUIT") }}
                          </div>
                          <div class="header-item" style="flex: 1.2">
                            {{ $t("MARQUE") }}
                          </div>
                          <div class="header-item" style="flex: 1">
                            {{ $t("REF") }}
                          </div>
                          <div class="header-item" style="flex: 2">
                            {{ $t("WORDING") }}
                          </div>

                          <div class="header-item" style="flex: 1">
                            {{ $t("QTE") }}
                          </div>
                          <div class="header-item" style="flex: 1.5">
                            {{ $t("type_uniteU") }}
                          </div>
                          <div class="header-item" style="flex: 1.75">
                            {{ $t("PUHT") }}
                          </div>
                          <div class="header-item" style="flex: 1.25">
                            {{ $t("TOTALHT") }}
                          </div>
                          <div
                            class="header-item"
                            style="flex: 0.8; border-radius: 0 5px 0 0"
                          >
                            {{ $t("VAT") }}
                          </div>
                        </div>
                        <!--section vide-->
                        <div
                          v-if="
                            !getdevisMonoFiche.data.groupeLigneDocument[step]
                              ?.products?.length
                          "
                          class="text-center my-4 colorred"
                        >
                          {{ $t("NO_DATA_FOUND") }}
                        </div>

                        <!--section des produits-->
                        <div v-else>
                          <div 
                            v-for="(row, productIndex) in getdevisMonoFiche.data
                              .groupeLigneDocument[step]?.products || []"
                            :key="productIndex"
                            class="row-item d-flex colorred"
                          >
                            <form @submit.prevent="handleSubmit">
                              <div class="row m-2">
                                <div
                                  class="input-wrapper mx-2"
                                  style="flex: 2.5"
                                >
                                  <div v-if="row.id" class="text-container">
                                    {{ row.lib }}
                                  </div>

                                  <searchInput
                                    v-if="row.type === 'Product'"
                                    :list="getAllProducts"
                                    label="lib"
                                    :placeholder="$t('PRODUIT')"
                                    :searchFunc="allproducts"
                                    @searchfilter="
                                      addProduit($event, productIndex, step)
                                    "
                                    :filtre="{ entreprise: form.id }"
                                    :disabled="row.ref !== ''"
                                  ></searchInput>

                                  <searchInput
                                    v-if="row.type === 'Forfait'"
                                    :list="filteredForfaits"
                                    label="lib"
                                    :placeholder="$t('FORFAIT')"
                                    :searchFunc="allForfait"
                                    @searchfilter="
                                      addProduit($event, productIndex, step)
                                    "
                                    :disabled="row.ref !== ''"
                                    :filtre="{ entreprise: form.id }"
                                  />
                                  <searchInput
                                    v-if="row.type === 'Main d\'oeuvre'"
                                    :list="filteredMainOeuvres"
                                    label="lib"
                                    :placeholder="$t('MAIN_D_OEUVRE')"
                                    :searchFunc="allmainoeuvres"
                                    @searchfilter="
                                      addProduit($event, productIndex, step)
                                    "
                                    :disabled="row.ref !== ''"
                                    :filtre="{ entreprise: form.id }"
                                  />
                                </div>
                                <div class="input-wrapper mx-2" style="flex: 1">
                                  <b-form-input
                                    v-model="row.fabricant"
                                   :disabled="row.type === 'Forfait'|| row.type_ligne === 'Forfait'||row.type === 'Main d\'oeuvre'|| row.type_ligne === 'Main d\'oeuvre'"
                                    class="custom-input"
                                  />
                                </div>
                                <div class="input-wrapper mx-2" style="flex: 1">
                                  <b-form-input
                                    v-model="row.ref"
                                    class="custom-input"
                                  />
                                </div>
                                <div class="input-wrapper ms-2" style="flex: 2">
                                  <b-form-input
                                    class="custom-input"
                                    v-model="row.lib"
                                    @input="row.lib = $event.toUpperCase()"
                                    required
                                  />
                                </div>

                                <div class="input-wrapper mx-2" style="flex: 1">
                                  <b-form-input
                                    class="custom-input"
                                    type="number"
                                    @paste="
                                      (event) => onPaste(event, 'row.quantite')
                                    "
                                    v-model.number="row.quantite"
                                    @keydown="(event) => onKeyDown(event)"
                                    min="1"
                                    required
                                  />
                                </div>
                                <div
                                  class="input-wrapper mx-2"
                                  style="flex: 1.5"
                                >
                                  <b-form-select
                                    class="custom-input"
                                    v-model="row.type_unite"
                                    :options="optionstype"
                                    text-field="text"
                                    value-field="value"
                                    required
                                  />
                                </div>
                                <div
                                  class="input-wrapper mx-2"
                                  style="flex: 1.5"
                                >
                                  <b-form-input
                                    class="custom-input"
                                    type="number"
                                    @paste="
                                      (event) =>
                                        onPaste(event, 'row.unit_price')
                                    "
                                    v-model.number="row.unit_price"
                                    @keydown="(event) => onKeyDown(event)"
                                    required
                                    step="1"
                                    min="0"
                                    max="10000000.00"
                                  />
                                </div>
                                <div
                                  class="input-wrapper mx-2"
                                  style="flex: 1.5"
                                >
                                  <b-form-input
                                    class="custom-input"
                                    :value="totalHT(row)"
                                    readonly
                                  />
                                </div>
                                <div class="input-wrapper mx-2" style="flex: 1">
                                  <b-form-select
                                    class="custom-input"
                                    v-model.number="row.tva"
                                    :options="optionsTVA"
                                    required
                                  />
                                </div>
                              </div>

                              <div class="row input-wrapper m-2">
                                <div style="flex: 0.5"></div>
                                <div class="mx-2" style="flex: 0.5">
                                  {{ $t("DESC") }}
                                </div>
                              </div>
                              <div class="row input-wrapper m-2">
                                <div style="flex: 2"></div>
                                <div style="flex: 12">
                                  <vue-editor
                                    v-model="row.desc"
                                    :placeholder="$t('AJUTER_DESCRIPTION')"
                                    class="mt-4"
                                    :editor-toolbar="customToolbar"
                                  />
                                </div>
                              </div>
                              <div class="row m-2">
                                <div style="flex: 13"></div>
                                <div style="flex: 1">
                                  <b-button
                                    @click="removeRow(step, productIndex)"
                                    class="button-pre-style custom-input"
                                  >
                                    <b-icon icon="trash"></b-icon>
                                  </b-button>
                                </div>
                              </div>

                              <hr
                                v-if="
                                  index <
                                  getdevisMonoFiche.data.groupeLigneDocument[
                                    step
                                  ]?.products.length -
                                    1
                                "
                              />
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="container-wrappe">
              <div class="d-flex justify-content-end mt-4 container-wrappe">
                <b-button
                  v-if="visibleTabs.length <= 1"
                  @click="goToPrevStep"
                  class="button-pre-style m-3"
                >
                  {{ $t("PREVIOUS") }}
                </b-button>
                <b-button
                  @click="goToStep(step)"
                  class="button-suivant-style m-3"
                >
                  <template v-if="isLoading">
                    <div class="loading ml-2">
                      <div
                        class="spinner-border spinner-border-sm"
                        role="status"
                      ></div>
                    </div>
                  </template>
                  <!-- <template v-else-if="isCompleted">
                  {{ $t("EDIT") }}
                </template> -->
                  <template>
                    {{ buttonText }}
                  </template>
                </b-button>
              </div></div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="container-wrappe">
    <div v-if="visibleTabs.length > 1" class="d-flex justify-content-end mt-4">
      <b-button @click="goToPrevStep" class="button-pre-style m-3">
        {{ $t("PREVIOUS") }}
      </b-button>
      <b-button
        type="submit"
        @click="goToNextStep"
        class="button-suivant-style m-3"
      >
        {{ $t("NEXT") }}

        <div class="loading ml-2" v-if="isLoadingButton">
          <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
      </b-button>
    </div></div>
    <Snackbar ref="snackbar" class="mt-5" />
  </div>
</template>
<script>
import { handlePaste, preventExceedingLengthFloat } from "@/utils/inputUtils";
import searchInput from "@/components/ui/searchInput";
import { mapGetters, mapActions } from "vuex";
import Snackbar from "@/components/ui/errorSnackbar.vue";

export default {
  components: {
    Snackbar,
    searchInput,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
      required: false,
    },
    isLoadingButton: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Boolean,
      default: false,
    },

    produitIds: {
      type: Array,
    },
  },
  created() {
    this.all_mainoeuvres({ page: this.page, per_page: 1000000000 });
    this.all_products({ page: this.page, per_page: 1000000000 });
    this.all_forfaits({ page: this.page, per_page: 100000000 });
    this.checkProductsInGroups();
  },

  data() {
    return {
      customToolbar: [
        ["bold", "italic", "underline", "strike"],
        [{ header: 1 }, { header: 2 }, { header: 3 }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
        ["clean"],
      ],
      selectedClient: null,
      errorMessage: "",
      disabledGroups: [],
      addedProducts: [],

      stepsData: [],
      isLoading: false,
      step: 0,
      form: {},
      optionstype: [
        { value: "m2", text: this.$t("m2") },
        { value: "m3", text: this.$t("m3") },
        { value: "ml", text: this.$t("ML") },
        { value: "h", text: this.$t("h") },
        { value: "F", text: this.$t("F") },
        { value: "U", text: this.$t("U") },
      ],
      optionsTVA: [
        { value: 0, text: "0%" },
        { value: 1, text: "1%" },
        { value: 5.5, text: "5.5%" },
        { value: 10, text: "10%" },
       
      ],
      tabs: [
        "Isolation de combles ou de toiture",
        "Isolation des murs",
        "Isolation d’un plancher ",
        "Fenêtre ou porte-fenêtre complète avec vitrage isolant",
        "Pompe à chaleur de type air/air ",
        "Ventilation mécanique simple flux hygroréglable",
        "Pompe à chaleur de type air/eau",
        "Chauffe-eau thermodynamique à accumulation",
        "Système solaire combiné",
        "Système de régulation par programmation horaire pièce par pièce",
        "Chaudière biomasse individuelle",
        "Raccordement d’un bâtiment résidentiel à un réseau de chaleur",
        "Appareil indépendant de chauffage au bois",
        "Chauffe-eau solaire individuel",
      ],
      stepActiv: [
        "Isolation des murs",
        "Pompe à chaleur de type air/eau",
        "Isolation de combles ou de toiture",
      ],
    };
  },
  watch: {
    getdevisMonoFiche: {
      handler: "checkProductsInGroups",
      immediate: true,
    },
  },

  computed: {
    ...mapGetters([
      "getAllforfaits",
      "getAllProducts",
      "getAllmainoeuvres",
      "getOnlineUser",
      "getdevisMonoFiche",
    ]),
    buttonText() {
      return this.visibleTabs.length <= 1
        ? this.$t("NEXT")
        : this.$t("CONFIRMER");
    },
    filteredProducts() {
      const selectedIds = this.getdevisMonoFiche.data.groupeLigneDocument
        .flatMap((step) => step.products)
        .filter((product) => product.type === "Product")
        .map((product) => product.produit_id);

      return this.getAllProducts.filter(
        (product) => !selectedIds.includes(product.id)
      );
    },
    filteredForfaits() {
      const selectedIds = this.getdevisMonoFiche.data.groupeLigneDocument
        .flatMap((step) => step.products)
        .filter((product) => product.type === "Forfait")
        .map((product) => product.produit_id);

      return this.getAllforfaits.filter(
        (forfait) => !selectedIds.includes(forfait.id)
      );
    },
    filteredMainOeuvres() {
      const selectedIds = this.getdevisMonoFiche.data.groupeLigneDocument
        .flatMap((step) => step.products)
        .filter((product) => product.type === "Main d'oeuvre")
        .map((product) => product.produit_id);

      return this.getAllmainoeuvres.filter(
        (mainoeuvre) => !selectedIds.includes(mainoeuvre.id)
      );
    },
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },
    visibleTabs() {
      const groupeLigneDocument =
        this.getdevisMonoFiche?.data?.groupeLigneDocument || [];

      const typeGroups = groupeLigneDocument.map((item) => item.type_group);

      return typeGroups;
    },
  },
  methods: {
    ...mapActions([
      "deleteProduitOfGroup",
      "createoneProductOfGroup",
      "store_forfaitjson",
      "updateProduct",
      "setDevisMonoFiche",
      "update_devis_group_BAR",
      "all_products",
      "all_mainoeuvres",
      "all_forfaits",
      "store_mainoeuvreJSON",
      "store_forfaitjson",
      "store_productJSON",
      "fetchOneGroup",
    ]),
    allForfait(search) {
      this.all_forfaits({ page: this.page, per_page: 100000000,search:search.search  });
    },
    allproducts(search) {
      this.all_products({ page: this.page, per_page: 100000000,search:search.search });
    },
    allmainoeuvres(search) {
      this.all_mainoeuvres({ page: this.page, per_page: 100000000,search:search.search });
    },

    checkProductsInGroups() {
      this.disabledGroups = this.getdevisMonoFiche.data.groupeLigneDocument.map(
        (group) => {
          const products = group.products || [];

          return products.some(
            (product) =>
              product.type === "Product" || product.type_ligne === "Product"
          );
        }
      );
    },

    async removeRow(step, index) {
      const rowToRemove =
        this.getdevisMonoFiche.data.groupeLigneDocument[step]?.products[index]
          ?.id;

      if (!rowToRemove) {
        this.getdevisMonoFiche.data.groupeLigneDocument[step]?.products.splice(
          index,
          1
        );
      }

      if (rowToRemove) {
        await this.deleteProduitOfGroup(rowToRemove);
        this.getdevisMonoFiche.data.groupeLigneDocument[step]?.products.splice(
          index,
          1
        );
      }

      this.addedProducts = this.addedProducts.filter(
        (item) => !(item.step === step && item.index === index)
      );

      this.addedProducts = this.addedProducts.map((item) => {
        if (item.step === step && item.index > index) {
          return { step: item.step, index: item.index - 1 };
        }
        return item;
      });
      this.checkProductsInGroups();
      this.setDevisMonoFiche(this.getdevisMonoFiche);
    },
    addProduit(selectedItem, index, step) {
      const productType =
        this.getdevisMonoFiche.data.groupeLigneDocument[step]?.products[index]
          .type;

      let updatedProduct = null;

      if (productType === "Product") {
        const product = this.getAllProducts.find(
          (p) => p.id === selectedItem.id
        );
        updatedProduct = {
          ...this.getdevisMonoFiche.data.groupeLigneDocument[step]?.products[
            index
          ],
          fabricant: product.fabricant,
          ref: product.ref,
          lib: product.lib,
          type_ligne: product.type_ligne,
          unit_price: product.unit_price,
          type_unite: product.type_unite,
          tva: product.tva,
          desc: product.desc,
          searchInputValue: product,
          produit_id: product.id,
          totalHT: product.unit_price * product.quantite,
        };
      } else if (productType === "Forfait") {
        const forfait = this.getAllforfaits.find(
          (f) => f.id === selectedItem.id
        );
        updatedProduct = {
          ...this.getdevisMonoFiche.data.groupeLigneDocument[step]?.products[
            index
          ],
          ref: forfait.ref,
          lib: forfait.lib,
          unit_price: forfait.unit_price,
          type_unite: forfait.type_unite,
          tva: forfait.tva,
          type_ligne: forfait.type_ligne,
          desc: forfait.desc,
          searchInputValue: forfait,
          produit_id: forfait.id,
          totalHT: forfait.unit_price * forfait.quantite,
        };
      } else if (productType === "Main d'oeuvre") {
        const mainoeuvre = this.getAllmainoeuvres.find(
          (m) => m.id === selectedItem.id
        );
        updatedProduct = {
          ...this.getdevisMonoFiche.data.groupeLigneDocument[step]?.products[
            index
          ],
          ref: mainoeuvre.ref,
          lib: mainoeuvre.lib,
          desc:
            mainoeuvre.desc +
            ` La preuve de la réalisation de l’opération mentionne :
- la mise en place d’une isolation thermique d’un plancher bas ;
- les marque et référence ainsi que l’épaisseur et la surface d’isolant installé ;
- la résistance thermique de l'isolation mise en place évaluée, suivant la nature de l’isolant, selon l’une des normes
susvisées ;
- les aménagements nécessaires à la mise en place de l’isolation (coffrage ou écran de protection autour des conduits
de fumées et des dispositifs d’éclairage encastrés ; rehausse rigide au niveau de la trappe d’accès ; pare-vapeur ou
tout autre dispositif équivalent lorsqu'il est nécessaire de protéger les matériaux d'isolation thermique contre les
transferts d'humidité pour garantir la performance de l'ouvrage) ;
- la date de la visite technique préalable par le professionnel`,
          unit_price: mainoeuvre.unit_price,
          type_ligne: mainoeuvre.type_ligne,
          type_unite: mainoeuvre.type_unite,
          tva: mainoeuvre.tva,
          searchInputValue: mainoeuvre,
          produit_id: mainoeuvre.id,
          totalHT: mainoeuvre.unit_price * mainoeuvre.quantite,
        };
      }

      if (updatedProduct) {
        this.$set(
          this.getdevisMonoFiche.data.groupeLigneDocument[step]?.products,
          index,
          updatedProduct
        );
        this.selected = true;
        this.setDevisMonoFiche(this.getdevisMonoFiche);

        this.addedProducts.push({ step, index });
      }
    },

    onKeyDown(event) {
      preventExceedingLengthFloat(event);
    },

    onPaste(event, fieldName) {
      handlePaste.call(this, event, fieldName);
    },

    isProductAdded(step) {
      const produit = this.getdevisMonoFiche.data.groupeLigneDocument[
        step
      ].products.some((product) => product.type === "Product");
      return produit;
    },
    addRow(type, step) {
      const groupe = this.getdevisMonoFiche.data.groupeLigneDocument[step];

      if (
        type === "Product" &&
        groupe.products.some((p) => p.type === "Product")
      ) {
        return;
      }
      if (type === "Product") {
        this.getdevisMonoFiche.data.groupeLigneDocument[step].products.push({
          groupelignedocument_id:
            this.getdevisMonoFiche.data.groupeLigneDocument[step].id,
          type: "Main d'oeuvre",
          ref: "",
          lib: "",
          quantite: 1,
          type_unite: null,
          unit_price: 1,
          tva: 0,
          desc: "",
          searchInputValue: null,
        });
      }
      this.getdevisMonoFiche.data.groupeLigneDocument[step].products.push({
        groupelignedocument_id:
          this.getdevisMonoFiche.data.groupeLigneDocument[step].id,
        fabricant: "",
        type: type,
        ref: "",
        lib: "",
        quantite: 1,
        type_unite: null,
        unit_price: 1,
        tva: 0,
        desc: "",
        searchInputValue: null,
      });
      this.checkProductsInGroups();
    },
    async goToStep(step) {
      this.isLoading = true;
      let allValid = true;
      const products =
        this.getdevisMonoFiche.data.groupeLigneDocument[step]?.products || [];

      products.forEach((product) => {
        if(product.unit_price==0 ||product.unit_price==""){
          this.$refs.snackbar.showSnackbar("l'unite price ne doit etre un 0");
          return;
        }
       
        if (
          !product.lib ||
          !product.ref ||
          !product.type_unite ||
          !product.unit_price
        ) {
          allValid = false;
          return;
        }
      });
      if (allValid) {
        let filteredProducts = [];

        const products =
          this.getdevisMonoFiche.data.groupeLigneDocument[step]?.products || [];

        products.forEach((product) => {
          if (product.searchInputValue === null || !product.produit_id) {
            filteredProducts.push(product);
          }
        });

        const addedProductsForStep = this.addedProducts.filter(
          (item) => item.step === step
        );

        filteredProducts = [...filteredProducts, ...addedProductsForStep];
        this.filteredProducts = filteredProducts;

        if (this.filteredProducts.length > 0) {
          for (let i = 0; i < this.filteredProducts.length; i++) {
            const item = this.filteredProducts[i];
            const product =
              this.getdevisMonoFiche.data.groupeLigneDocument[step]?.products[
                i
              ];
            if (product) {
              const groupelignedocumentId = String(
                product.groupelignedocument_id
              );

              if (product.id) {
                await this.updateProduct(product);
              } else {
                if (
                  product &&
                  !product.searchInputValue &&
                  !product.produit_id
                ) {
                  if (product.type === "Forfait") {
                    const res = await this.store_forfaitjson(product);
                    if (typeof res === "object" && res !== null) {

    if (res.message && typeof res.message === "object") {

  const allMessages = Object.entries(res.message)
    .map(([, messages]) => {
      if (Array.isArray(messages)) {
        return ` ${messages.join(", ")}`;
      }
      return ` ${messages} `;
    })
    .join(" , ");


  this.$refs.snackbar.showSnackbar(
    `${allMessages} pour le forfait ${i + 1} du groupe ${
      step + 1
    }`
  );
}
else {
   
      const genericMessage = res.message || "Une erreur est survenue";

      this.$refs.snackbar.showSnackbar(
        `${genericMessage} pour le forfait ${i + 1} du groupe ${
          step + 1
        }`
      );
    }
    this.isLoading=false;
    return;
  }
                    product.produit_id = res;
                  }
                  if (product.type === "Main d'oeuvre") {
                    const res = await this.store_mainoeuvreJSON(product);
                    if (typeof res === "object" && res !== null) {

    if (res.message && typeof res.message === "object") {

  const allMessages = Object.entries(res.message)
    .map(([, messages]) => {
      if (Array.isArray(messages)) {
        return ` ${messages.join(", ")}`;
      }
      return ` ${messages} `;
    })
    .join(" , ");


  this.$refs.snackbar.showSnackbar(
    `${allMessages} pour le main d'oeuvre ${i + 1} du groupe ${
      step + 1
    }`
  );
}
else {
   
      const genericMessage = res.message || "Une erreur est survenue";

      this.$refs.snackbar.showSnackbar(
        `${genericMessage} pour le main d'oeuvre ${i + 1} du groupe ${
          step + 1
        }`
      );
    }
    this.isLoading=false;
    return;
  }
                    product.produit_id = res;
                  }
                  if (product.type === "Product") {
                    const res = await this.store_productJSON(product);
                    if (typeof res === "object" && res !== null) {

    if (res.message && typeof res.message === "object") {

  const allMessages = Object.entries(res.message)
    .map(([, messages]) => {
      if (Array.isArray(messages)) {
        return ` ${messages.join(", ")}`;
      }
      return ` ${messages} `;
    })
    .join(" , ");


  this.$refs.snackbar.showSnackbar(
    `${allMessages} pour le produit ${i + 1} du groupe ${
      step + 1
    }`
  );
}
else {
   
      const genericMessage = res.message || "Une erreur est survenue";

      this.$refs.snackbar.showSnackbar(
        `${genericMessage} pour le produit ${i + 1} du groupe ${
          step + 1
        }`
      );
    }
    this.isLoading=false;
    return;
  }
                    product.produit_id = res;
                  }
                }

                const produitId = String(product.produit_id);
               await this.createoneProductOfGroup({
                  ...product,
                  groupelignedocument_id: groupelignedocumentId,
                  produit_id: produitId,
                  type_ligne: product.type,
                });
              }

              const indexInAddedProducts = this.addedProducts.findIndex(
                (addedProduct) =>
                  addedProduct.step === item.step &&
                  addedProduct.index === item.index
              );

              if (indexInAddedProducts !== -1) {
                this.addedProducts.splice(indexInAddedProducts, 1);
              }
            }
          }
        } else if (this.filteredProducts.length === 0) {
          await this.processProducts(step);
          if (this.visibleTabs.length <= 1) {
            this.goToNextStep();
          }
        }
        const group = await this.fetchOneGroup(
          this.getdevisMonoFiche.data.groupeLigneDocument[step].id
        );
        this.getdevisMonoFiche.data.groupeLigneDocument[step].products =
          group.products;
        this.setDevisMonoFiche(this.getdevisMonoFiche);
        this.isLoading = false;
      } else {
        this.$refs.snackbar.showSnackbar("Tous les champs sont obligatoires");
    
        this.isLoading = false;
        return;
      }
    },

    async processProducts(step) {
      if (
        this.getdevisMonoFiche?.data?.groupeLigneDocument[step]?.products &&
        Array.isArray(
          this.getdevisMonoFiche.data.groupeLigneDocument[step].products
        )
      ) {
        const products =
          this.getdevisMonoFiche.data.groupeLigneDocument[step].products;

        for (let i = 0; i < products.length; i++) {
          const product = products[i];

          await this.updateProduct(product);
        }
      }
    },
    valideDevis() {
      const groupeLigneDocuments =
        this.getdevisMonoFiche.data.groupeLigneDocument || [];

      for (const ligne of groupeLigneDocuments) {
        const typeGroup = ligne.type_group;
        const typeValid =
          (typeGroup === "BAR-EN-101" && ligne.products.length > 0) ||
          (typeGroup === "BAR-EN-102" && ligne.products.length > 0) ||
          (typeGroup === "BAR-EN-103" && ligne.products.length > 0) ||
          (typeGroup === "BAR-TH-171" && ligne.products.length > 0) ||
          (typeGroup === "BAR-TH-143" && ligne.products.length > 0) ||
          (typeGroup === "BAR-TH-101" && ligne.products.length > 0) ||
          (typeGroup === "BAR-TH-113" && ligne.products.length > 0) ||
          (typeGroup === "BAR-TH-112" && ligne.products.length > 0) ||
          (typeGroup === "BAR-TH-127" && ligne.products.length > 0) ||
          (typeGroup === "BAR-TH-129" && ligne.products.length > 0) ||
          (typeGroup === "BAR-TH-148" && ligne.products.length > 0) ||
          (typeGroup === "BAR-TH-137" && ligne.products.length > 0) ||
          (typeGroup === "BAR-EN-104" && ligne.products.length > 0) ||
          (typeGroup === "BAR-TH-175" && ligne.products.length > 0) ||
          (typeGroup === "BAR-EN-174" && ligne.products.length > 0) ||
          (typeGroup === "BAR-TH-173" && ligne.products.length > 0);

        if (!typeValid) {
          this.errorMessage = "erreur_chantier";
          break;
        }
      }
    },
    async goToNextStep() {
      this.errorMessage = "";
      this.valideDevis();
      if (this.errorMessage !== "") {
        this.$refs.snackbar.showSnackbar(this.errorMessage);
        this.isLoading = false;
        return;
      }
      this.$emit("changeStep", 7, true);
    },
    totalHT(row) {
      if (row.unit_price && row.quantite) {
        return (row.unit_price * row.quantite).toFixed(2);
      }
      return 0;
    },
    goToPrevStep() {
      this.$emit("changeStep", 5);
    },
  },
};
</script>

<style>

.container-wrappe {
  min-width: 1000px; /* Définit une largeur minimale pour le conteneur */
  display: flex; /* Utilise flex pour aligner correctement les enfants */
  justify-content: flex-end; /* Aligne les enfants à droite */
}
.d-flex {
  width: 100%; /* Assure que l'élément prend toute la largeur du conteneur */
}

.quillWrapper {
    background-color: white !important;
}

.colorred{
  background-color: #f8f9fa;
}
.text-container {
  max-width: 16ch; /* Limite la largeur à 16 caractères */
  overflow-wrap: break-word; /* Permet de couper les mots pour qu'ils passent à la ligne */
  word-wrap: break-word; /* Compatibilité ancienne */
  white-space: normal; /* Active le retour à la ligne */
}
.borders {
  display: block;
  width: 100%;

  margin-top: 20px;
 
  box-sizing: border-box;
}

/* Personnalisation de la scrollbar */
.scroll-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 4px;
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #555; 
}
.scroll-container::-webkit-scrollbar {
  width: 8px;
  height: 8px; 
  background-color: transparent;
}
.scroll-container::-webkit-scrollbar-thumb {
  background-color: #888; 
  border-radius: 4px;
}
.scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #555; 
}
.scroll-container::-webkit-scrollbar {
  display: none; 
}
.scroll-container {
  width: 100%;

  overflow-x: auto; 
  overflow-y: hidden; 
  position: relative;

}

.text-container {
  max-width: 16ch; /* Limite la largeur à 16 caractères */
  overflow-wrap: break-word; /* Permet de couper les mots pour qu'ils passent à la ligne */
  word-wrap: break-word; /* Compatibilité ancienne */
  white-space: normal; /* Active le retour à la ligne */
}

.border_pointe {
  margin: 10px;
  border: 2px dashed #24316d;
  position: relative;
  padding: 10px;
}
.new {
  height: 40px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #24316d;
  color: white;
}

.tabs-container {
  display: flex;
  justify-content: center; /* Centre le composant horizontalement */
}
.newstore {
  background: #24316d;
  padding: 8px 12px;
  color: #fff;
  cursor: pointer;
  width: 30px;
  height: 30px;
  &:hover {
    background-color: #24316d !important;
  }
}
.truncated-label .label-class {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 10px;
}
b-dropdown {
  background-color: #24316d !important;
}
.btn-secondary {
  color: #fff;
  background-color: #24316d !important;
  border-color: #24316d !important;
}
.header-item {
  flex: 1;
}
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 2px solid #ddd;
}

.btn-primary.dropdown-toggle {
  font-size: 14px;
}
.custom-placeholder::placeholder {
  font-size: 13px;
}
.input-wrapper:not(:last-child) {
  margin-right: 10px;
}

.form-input {
  font-size: 13px;
  height: 41.1px !important;
  width: 100%;
}
.button-pre-style {
  background-color: #24316d !important;
  box-shadow: none;
  border-radius: 5px 5px 5px 5px;
}
.border {
  border: 1px solid #24316d !important;
}
.custom-input {
  box-shadow: none !important;
  width: 100%;
  height: 38px;
}

.row-item {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 2px solid #ddd;
  background-color: #24316d;
  color: white;
}

/* .border {
  display: block;
  width: 100%;
  border: 1px solid #24316d;
  margin-top: 20px;


/* .scroll-container {
  overflow-x: auto;
} */
.small-spinner .spinner-border {
  width: 1rem;
  height: 1rem;
}
.content-wrapper {
  min-width: 1000px;
}

.header,
.row-item {
  display: flex;
  min-width: 100px;
}

.input-wrapper {
  display: flex;
  align-items: center;
  margin: 5px;
}
</style>
