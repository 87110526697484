import { render, staticRenderFns } from "./certificatRGEmodal.vue?vue&type=template&id=68f28940&scoped=true"
import script from "./certificatRGEmodal.vue?vue&type=script&lang=js"
export * from "./certificatRGEmodal.vue?vue&type=script&lang=js"
import style0 from "./certificatRGEmodal.vue?vue&type=style&index=0&id=68f28940&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68f28940",
  null
  
)

export default component.exports