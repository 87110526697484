<template>
    
    <div class="inner-container" :class="{ 'min-container': !getTabs }">
        <div class="">
            <div class="page-header mb-0 ">
                <h1 class="w-100">
                    {{ $t("EDIT") }} {{ $t("CUSTOMER") }}
                </h1></div>
                <div class="content mt-0">
                    <div v-if="clientToConfig">
                    
                      
    <form @submit.prevent="clientupdate">
       <div class="center">
        <div  >
      <div class="row">
        <div class="col-lg-9 col-md-12 col-sm-12">
          <b-form-group label="Type" class="mt-2">
            <b-form-select
              v-model="v$.clientToConfig.type.$model"
              :options="options"
              :state="validateState('type')"
              aria-describedby="type-feedback"
              @change="v$.$reset()"
            ></b-form-select>
            <error-handle
              :list="v$.clientToConfig.type.$errors"
              id="type-feedback"
            ></error-handle>
            <div v-if="erreurlist.type" class="error-message">
              <ul v-if="Array.isArray(erreurlist.type)">
                <span v-for="(e, index) in erreurlist.type" :key="index">
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.type }}</span>
            </div>
          </b-form-group>
        </div>

      </div>
        </div>
        <div
          v-if="clientToConfig.type == 'type.professionnel'"
          
        >
          <h5 class="titresociete mt-2">
            {{ $t("CONTACT_S") }}
          </h5>
       <div class="row">
       <div class="col-lg-6 col-md-6 col-sm-12">
        <b-form-group :label="$t('SOCIETY')">
          <b-form-input
            v-model="v$.clientToConfig.company.$model"
            oninput="this.value = this.value.toUpperCase()"
            :state="validateState('company')"
            aria-describedby="company-feedback"
            @input="erreurlist.company = null"
          ></b-form-input>
          <error-handle
            :list="v$.clientToConfig.company.$errors"
            id="company-feedback"
          ></error-handle>
          <div v-if="erreurlist.company" class="error-message">
            <ul v-if="Array.isArray(erreurlist.company)">
              <span v-for="(e, index) in erreurlist.company" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.company }}</span>
          </div>
        </b-form-group>
       </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <b-form-group :label="$t('COMPANY_EMAIL')" label-for="email">
            <b-form-input
              v-model="v$.clientToConfig.company_email.$model"
              required
              type="email"
              :state="validateState('company_email')"
              aria-describedby="company_email-feedback"
              @input="erreurlist.company_email = null"
            ></b-form-input>
            <error-handle
              :list="v$.clientToConfig.company_email.$errors"
              id="company_email-feedback"
            ></error-handle>
            <div v-if="erreurlist.company_email" class="error-message">
              <ul v-if="Array.isArray(erreurlist.company_email)">
                <span
                  v-for="(e, index) in erreurlist.company_email"
                  :key="index"
                >
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.company_email }}</span>
            </div>
          </b-form-group>
        </div>
       </div>

       <div class="row">
        
       <div class="col-lg-4 col-md-12 col-sm-12">
        <b-form-group :label="$t('STREET')" label-for="rue">
          <b-form-input
            v-model="v$.clientToConfig.rue.$model"
            :state="validateState('rue')"
            aria-describedby="rue-feedback"
          ></b-form-input>
          <error-handle
            :list="v$.clientToConfig.rue.$errors"
            id="rue-feedback"
          ></error-handle>
          <div v-if="erreurlist.rue" class="error-message">
            <ul v-if="Array.isArray(erreurlist.rue)">
              <span v-for="(e, index) in erreurlist.rue" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.rue }}</span>
          </div>
        </b-form-group>
       </div>
       <div class="col-lg-4 col-md-12 col-sm-12">
        <b-form-group :label="$t('POSTAL_CODE')" label-for="cp">
          <b-form-input
            v-model="v$.clientToConfig.cp.$model"
            :state="validateState('cp')"
            aria-describedby="cp-feedback"
          ></b-form-input>
          <error-handle
            :list="v$.clientToConfig.cp.$errors"
            id="cp-feedback"
          ></error-handle>
          <div v-if="erreurlist.cp" class="error-message">
            <ul v-if="Array.isArray(erreurlist.cp)">
              <span v-for="(e, index) in erreurlist.cp" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.cp }}</span>
          </div>
        </b-form-group>
       </div>
      <div class="col-lg-4 col-md-12 col-sm-12">
        <b-form-group :label="$t('CITY')" label-for="ville">
          <b-form-input
            v-model="v$.clientToConfig.ville.$model"
            :state="validateState('ville')"
            aria-describedby="ville-feedback"
          ></b-form-input>
          <error-handle
            :list="v$.clientToConfig.ville.$errors"
            id="ville-feedback"
          ></error-handle>
          <div v-if="erreurlist.ville" class="error-message">
            <ul v-if="Array.isArray(erreurlist.ville)">
              <span v-for="(e, index) in erreurlist.ville" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.ville }}</span>
          </div>
        </b-form-group>
      </div>
       </div>

        <div class="row">
         <div class="col-lg-4 col-md-12 col-sm-12">
          <b-form-group :label="$t('COUNTRY')">
            <multiselect
              v-model="clientToConfig.pays"
              :searchable="true"
              :close-on-select="true"
              :multiple="false"
              :options="getPays"
              label="name"
              track-by="id"
            >
              <template slot="noResult">
                {{ $t("NO_DATA_FOUND") }}
              </template>
            </multiselect>
            <div v-if="erreurlist.pays" class="error-message">
              <ul v-if="Array.isArray(erreurlist.pays)">
                <span v-for="(e, index) in erreurlist.pays" :key="index">
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.pays }}</span>
            </div>
            <div v-if="v$.clientToConfig.pays.$error" class="error-message">
              {{ $t("REQUIRED_FIELD") }}
            </div>
          </b-form-group>
         </div>
         <div class="col-lg-4 col-sm-12 col-md-12">
          <b-form-group :label="$t('ZONE')">
            <b-form-select
              v-model="clientToConfig.zone"
              :options="optionsZone"
              text-field="text"
              value-field="value"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- {{ $t("ZONE") }} --</b-form-select-option
                >
              </template>
            </b-form-select>
            <div v-if="erreurlist.zone" class="error-message">
              <ul v-if="Array.isArray(erreurlist.zone)">
                <span v-for="(e, index) in erreurlist.zone" :key="index">
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.zone }}</span>
            </div>
          </b-form-group>
         </div>
         <div class="col-lg-4 com-md-12 col-sm-12  py-0">
          <b-form-group :label="$t('COMPANY_PHONE')">
            <template>
              <phone
              
                :tel="clientToConfig.company_phone"
                @phoneNumber="formatNumberE($event)"
              ></phone>
            </template>
            <div
              v-if="v$.clientToConfig.company_phone.$error"
              class="error-message"
            >
              {{ $t("REQUIRED_FIELD") }}
            </div>
            <div
              v-if="clientToConfig.company_phone && phoneValid == false"
              class="error-message"
            >
              {{ $t("ERROR_PHONE") }}
            </div>
            <div v-if="erreurlist.company_phone" class="error-message">
              <ul v-if="Array.isArray(erreurlist.company_phone)">
                <span
                  v-for="(e, index) in erreurlist.company_phone"
                  :key="index"
                >
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.company_phone }}</span>
            </div>
          </b-form-group>
         </div>
        </div>

          <h5 class="titresociete mb-3">{{ $t("COMPANY_INTER") }}</h5>

         <div class="row">
        <div class="col-lg-4 col-md-12 col-sm-12">
          <b-form-group :label="$t('SURNAME')" label-for="lastname">
            <b-form-input
              v-model="v$.clientToConfig.prenom.$model"
              oninput="this.value = this.value.toUpperCase()"
              :state="validateState('prenom')"
              aria-describedby="prenom-feedback"
            ></b-form-input>
            <error-handle
              :list="v$.clientToConfig.prenom.$errors"
              id="prenom-feedback"
            ></error-handle>
            <div v-if="erreurlist.prenom" class="error-message">
              <ul v-if="Array.isArray(erreurlist.prenom)">
                <span v-for="(e, index) in erreurlist.prenom" :key="index">
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.prenom }}</span>
            </div>
          </b-form-group>

        </div>
      <div class="col-lg-4 col-md-12 col-sm-12">
        <b-form-group :label="$t('NAME')">
          <b-form-input
            v-model="v$.clientToConfig.nom.$model"
            oninput="this.value = this.value.toUpperCase()"
            :state="validateState('nom')"
            aria-describedby="nom-feedback"
          ></b-form-input>
          <error-handle
            :list="v$.clientToConfig.nom.$errors"
            id="nom-feedback"
          ></error-handle>
          <div v-if="erreurlist.nom" class="error-message">
            <ul v-if="Array.isArray(erreurlist.nom)">
              <span v-for="(e, index) in erreurlist.nom" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.nom }}</span>
          </div>
        </b-form-group>

      </div>
        <div class="col-lg-4 col-md-12 col-sm-12">
          <b-form-group :label="$t('Poste')">
            <b-form-input
              v-model="v$.clientToConfig.poste.$model"
              oninput="this.value = this.value.toUpperCase()"
              :state="validateState('poste')"
              aria-describedby="poste-feedback"
              @input="erreurlist.poste = null"
            ></b-form-input>
            <error-handle
              :list="v$.clientToConfig.poste.$errors"
              id="poste-feedback"
            ></error-handle>
            <div v-if="erreurlist.poste" class="error-message">
              <ul v-if="Array.isArray(erreurlist.poste)">
                <span v-for="(e, index) in erreurlist.poste" :key="index">
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.poste }}</span>
            </div>
          </b-form-group>
        </div>
         </div>

          <b-form-group :label="$t('PHONE')" label-for="telephone">
            <template>
              <phone
                :tel="clientToConfig.phone"
                @phoneNumber="formatNumber($event)"
              ></phone>
            </template>
           
            <div
              v-if="v$.clientToConfig.phone.$error"
              class="error-message"
            >
              {{ $t("REQUIRED_FIELD") }}
            </div>
            <div
              v-if="clientToConfig.phone && resultValid == false"
              class="error-message"
            >
              {{ $t("ERROR_PHONE") }}
            </div>
          </b-form-group>
          <b-form-group :label="$t('EMAIL')" label-for="email">
          <b-form-input
            v-model="v$.clientToConfig.email.$model"
            :state="validateState('email')"
            aria-describedby="email-feedback"
            type="email"
            readonly
          ></b-form-input>
          <error-handle
            :list="v$.clientToConfig.email.$errors"
            id="email-feedback"
          ></error-handle>
          <div v-if="erreurlist.email" class="error-message">
            <ul v-if="Array.isArray(erreurlist.email)">
              <span v-for="(e, index) in erreurlist.email" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.email }}</span>
          </div>
        </b-form-group>
          <h5 class="titresociete mb-3">{{ $t("COMPANY_BANK") }}</h5>

         <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12">
          <b-form-group :label="$t('ACCOUNT_OWNER')">
            <b-form-input
              v-model="v$.clientToConfig.titulaire_compte.$model"
              :state="validateState('titulaire_compte')"
              aria-describedby="titulaire_compte-feedback"
            ></b-form-input>
            <error-handle
              :list="v$.clientToConfig.titulaire_compte.$errors"
              id="titulaire_compte-feedback"
            ></error-handle>
            <div v-if="erreurlist.titulaire_compte" class="error-message">
              <ul v-if="Array.isArray(erreurlist.titulaire_compte)">
                <span
                  v-for="(e, index) in erreurlist.titulaire_compte"
                  :key="index"
                >
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.titulaire_compte }}</span>
            </div>
          </b-form-group>
        </div>
         <div class="col-lg-6 col-md-12 col-sm-12">
          <b-form-group label="IBAN" label-for="IBAN">
            <b-form-input
              v-model="clientToConfig.IBAN.$model"
              :state="validateState('IBAN')"
              aria-describedby="IBAN-feedback"
            ></b-form-input>
            <error-handle
              :list="clientToConfig.IBAN.$errors"
              id="IBAN-feedback"
            ></error-handle>
            <div v-if="erreurlist.IBAN" class="error-message">
              <ul v-if="Array.isArray(erreurlist.IBAN)">
                <span v-for="(e, index) in erreurlist.IBAN" :key="index">
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.IBAN }}</span>
            </div>
          </b-form-group>
         </div>
         </div>
         <div class="row">
          <div class="col-lg-4 col-md-12 col-sm-12">
            <b-form-group :label="$t('forme_juridique')" label-for="forme_juridique">
              <b-form-input
                v-model="clientToConfig.forme_juridique"
               
                aria-describedby="forme_juridique-feedback"
              ></b-form-input>
              <error-handle
                :list="clientToConfig.forme_juridique.$errors"
                id="forme_juridique-feedback"
              ></error-handle>
              <div v-if="erreurlist.forme_juridique" class="error-message">
                <ul v-if="Array.isArray(erreurlist.forme_juridique)">
                  <span v-for="(e, index) in erreurlist.forme_juridique" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.forme_juridique }}</span>
              </div>
            </b-form-group>
           
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12">
            <b-form-group :label="$t('ape')" label-for="ape">
              <b-form-input
                v-model="clientToConfig.ape"
              
                aria-describedby="ape-feedback"
              ></b-form-input>
              <error-handle
                :list="clientToConfig.ape.$errors"
                id="ape-feedback"
              ></error-handle>
              <div v-if="erreurlist.ape" class="error-message">
                <ul v-if="Array.isArray(erreurlist.ape)">
                  <span v-for="(e, index) in erreurlist.ape" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.ape }}</span>
              </div>
            </b-form-group>
           
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12">
            <b-form-group :label="$t('naf')" label-for="naf">
              <b-form-input
                v-model="clientToConfig.naf"
              
                aria-describedby="naf-feedback"
              ></b-form-input>
              <error-handle
                :list="clientToConfig.naf.$errors"
                id="ape-feedback"
              ></error-handle>
              <div v-if="erreurlist.naf" class="error-message">
                <ul v-if="Array.isArray(erreurlist.naf)">
                  <span v-for="(e, index) in erreurlist.naf" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.naf }}</span>
              </div>
            </b-form-group> 
          </div>
            </div>
      <div class="row">
     <div class="col-lg-6 col-md-12 col-sm-12">
      <b-form-group label="SWIFT_BIC" label-for="SWIFT_BIC">
        <b-form-input
          v-model="v$.clientToConfig.SWIFT_BIC.$model"
          :state="validateState('SWIFT_BIC')"
          aria-describedby="SWIFT_BIC-feedback"
          oninput="this.value = this.value.toUpperCase()"
        ></b-form-input>
        <error-handle
          :list="v$.clientToConfig.SWIFT_BIC.$errors"
          id="SWIFT_BIC-feedback"
        ></error-handle>
        <div v-if="erreurlist.SWIFT_BIC" class="error-message">
          <ul v-if="Array.isArray(erreurlist.SWIFT_BIC)">
            <span
              v-for="(e, index) in erreurlist.SWIFT_BIC"
              :key="index"
            >
              {{ e }}
            </span>
          </ul>
          <span v-else>{{ erreurlist.SWIFT_BIC }}</span>
        </div>
      </b-form-group>
     </div>
      <div class="col-lg-6 col-md-12 col-sm-12">
        <b-form-group :label="$t('revenu_impo')" label-for="revenu_avis_imposition">
          <b-form-input
            v-model="v$.clientToConfig.revenu_avis_imposition"
        
            aria-describedby="revenu_avis_imposition-feedback"
            type="number"
            min="0"
            oninput="this.value = this.value.toUpperCase()"
          ></b-form-input>
      
        
        </b-form-group>
      </div>
      </div>
          <hr />

          <b-form-group :label="$t('COMMENTAIRE')" label-for="commentaire">
            <b-form-textarea
              v-model="clientToConfig.commentaire"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
            <div v-if="erreurlist.commentaire" class="error-message">
              <ul v-if="Array.isArray(erreurlist.commentaire)">
                <span
                  v-for="(e, index) in erreurlist.commentaire"
                  :key="index"
                >
                  {{ e }}
                </span>
              </ul>
              <span v-else>{{ erreurlist.commentaire }}</span>
            </div>
          </b-form-group>
        </div>

        <div
          v-if="clientToConfig.type == 'type.particulier'"
          
        >
        <div class="row">
          <div class="col-lg-4 col-md-12 col-sm-12">
            <b-form-group :label="$t('SURNAME')" label-for="lastname">
              <b-form-input
                v-model="v$.clientToConfig.prenom.$model"
                oninput="this.value = this.value.toUpperCase()"
                :state="validateState('prenom')"
                aria-describedby="prenom-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.clientToConfig.prenom.$errors"
                id="prenom-feedback"
              ></error-handle>
              <div v-if="erreurlist.prenom" class="error-message">
                <ul v-if="Array.isArray(erreurlist.prenom)">
                  <span v-for="(e, index) in erreurlist.prenom" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.prenom }}</span>
              </div>
            </b-form-group>
          </div>

          <div class="col-lg-4 col-sm-12 col-md-12">
            <b-form-group :label="$t('NAME')">
              <b-form-input
                v-model="v$.clientToConfig.nom.$model"
                oninput="this.value = this.value.toUpperCase()"
                :state="validateState('nom')"
                aria-describedby="nom-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.clientToConfig.nom.$errors"
                id="nom-feedback"
              ></error-handle>
              <div v-if="erreurlist.nom" class="error-message">
                <ul v-if="Array.isArray(erreurlist.nom)">
                  <span v-for="(e, index) in erreurlist.nom" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.nom }}</span>
              </div>
            </b-form-group>
          </div>
      <div class="col-lg-4 col-sm-12 col-md-12">
        <b-form-group :label="$t('EMAIL')" label-for="email">
          <b-form-input
            v-model="v$.clientToConfig.email.$model"
            :state="validateState('email')"
            aria-describedby="email-feedback"
            type="email"
            readonly
          ></b-form-input>
          <error-handle
            :list="v$.clientToConfig.email.$errors"
            id="email-feedback"
          ></error-handle>
          <div v-if="erreurlist.email" class="error-message">
            <ul v-if="Array.isArray(erreurlist.email)">
              <span v-for="(e, index) in erreurlist.email" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.email }}</span>
          </div>
        </b-form-group>
      </div>
        </div>

<div class="row">

<div class="col-lg-6 col-sm-12 col-md-12">
  <b-form-group :label="$t('STREET')" label-for="rue">
    <b-form-input
      v-model="v$.clientToConfig.rue.$model"
      :state="validateState('rue')"
      aria-describedby="rue-feedback"
    ></b-form-input>
    <error-handle
      :list="v$.clientToConfig.rue.$errors"
      id="rue-feedback"
    ></error-handle>
    <div v-if="erreurlist.rue" class="error-message">
      <ul v-if="Array.isArray(erreurlist.rue)">
        <span v-for="(e, index) in erreurlist.rue" :key="index">
          {{ e }}
        </span>
      </ul>
      <span v-else>{{ erreurlist.rue }}</span>
    </div>
  </b-form-group>
</div>

<div class="col-lg-6 col-sm-12 col-md-12">
  <b-form-group :label="$t('POSTAL_CODE')" label-for="cp">
    <b-form-input
      v-model="v$.clientToConfig.cp.$model"
      :state="validateState('cp')"
      aria-describedby="cp-feedback"
    ></b-form-input>
    <error-handle
      :list="v$.clientToConfig.cp.$errors"
      id="cp-feedback"
    ></error-handle>
    <div v-if="erreurlist.cp" class="error-message">
      <ul v-if="Array.isArray(erreurlist.cp)">
        <span v-for="(e, index) in erreurlist.cp" :key="index">
          {{ e }}
        </span>
      </ul>
      <span v-else>{{ erreurlist.cp }}</span>
    </div>
  </b-form-group>
</div>
</div>
        <div class="row">
          <div class=" col-lg-4 col-md-12 col-sm-12 ">
            <b-form-group :label="$t('CITY')" label-for="ville">
              <b-form-input
                v-model="v$.clientToConfig.ville.$model"
                :state="validateState('ville')"
                aria-describedby="ville-feedback"
              ></b-form-input>
              <error-handle
                :list="v$.clientToConfig.ville.$errors"
                id="ville-feedback"
              ></error-handle>
              <div v-if="erreurlist.ville" class="error-message">
                <ul v-if="Array.isArray(erreurlist.ville)">
                  <span v-for="(e, index) in erreurlist.ville" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.ville }}</span>
              </div>
            </b-form-group>
           </div>
          <div class="col-lg-4 col-md-12 col-sm-12">
            <b-form-group :label="$t('COUNTRY')">
              <multiselect
                v-model="clientToConfig.pays"
                :searchable="true"
                :close-on-select="true"
                :multiple="false"
                :options="getPays"
                label="name"
                track-by="id"
              >
                <template slot="noResult">
                  {{ $t("NO_DATA_FOUND") }}
                </template>
              </multiselect>
              <div v-if="erreurlist.pays" class="error-message">
                <ul v-if="Array.isArray(erreurlist.pays)">
                  <span v-for="(e, index) in erreurlist.pays" :key="index">
                    {{ e }}
                  </span>
                </ul>
                <span v-else>{{ erreurlist.pays }}</span>
              </div>
              <div v-if="v$.clientToConfig.pays.$error" class="error-message">
                {{ $t("REQUIRED_FIELD") }}
              </div>
            </b-form-group>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12">
            <b-form-group :label="$t('PHONE')" label-for="telephone">
              <template>
                <phone
                  :tel="clientToConfig.phone"
                  @phoneNumber="formatNumber($event)"
                ></phone>
              </template>
              <div
                v-if="v$.clientToConfig.phone.$error"
                class="error-message"
              >
                {{ $t("REQUIRED_FIELD") }}
              </div>
              <div
                v-if="clientToConfig.phone && resultValid == false"
                class="error-message"
              >
                {{ $t("ERROR_PHONE") }}
              </div>
            </b-form-group>
          </div>
        </div>
     <div class="row">
     <div class="col-lg-4 col-sm-12 col-md-12">
      <b-form-group :label="$t('ZONE')">
        <b-form-select
          v-model="clientToConfig.zone"
          :options="optionsZone"
          text-field="text"
          value-field="value"
        >
          <template #first>
            <b-form-select-option :value="null" disabled
              >-- {{ $t("ZONE") }} --</b-form-select-option
            >
          </template>
        </b-form-select>
        <div v-if="erreurlist.zone" class="error-message">
          <ul v-if="Array.isArray(erreurlist.zone)">
            <span v-for="(e, index) in erreurlist.zone" :key="index">
              {{ e }}
            </span>
          </ul>
          <span v-else>{{ erreurlist.zone }}</span>
        </div>
      </b-form-group>
     </div>
     <div class="col-lg-4 col-md-12 col-sm-12 py-0">
      <b-form-group :label="$t('PRECARITE')">
        <b-form-select
          v-model="clientToConfig.precarite"
          :options="optionsprecarite"
          text-field="text"
          value-field="value"
        >
          <template #first>
            <b-form-select-option :value="null" disabled
              >-- {{ $t("PRECARITE") }} --</b-form-select-option
            >
          </template>
        </b-form-select>
        <div v-if="erreurlist.precarite" class="error-message">
          <ul v-if="Array.isArray(erreurlist.precarite)">
            <span v-for="(e, index) in erreurlist.precarite" :key="index">
              {{ e }}
            </span>
          </ul>
          <span v-else>{{ erreurlist.precarite }}</span>
        </div>
      </b-form-group>
     </div>
     <div class="col-lg-4 col-sm-12 col-md-12">
      <b-form-group :label="$t('revenu_impo')" label-for="revenu_avis_imposition">
        <b-form-input 
          v-model="clientToConfig.revenu_avis_imposition"
 
          aria-describedby="revenu_avis_imposition-feedback"
          type="number"
        min="0"
          oninput="this.value = this.value.toUpperCase()"
        ></b-form-input>
   
        </b-form-group>
     </div>
        </div>
      </div>
        <b-form-group :label="$t('COMPANY_ID')" v-if="isSuperAdmin">
          <multiselect
            v-model="clientToConfig.entreprise"
            :searchable="true"
            :close-on-select="true"
            :multiple="false"
            :options="getAllentreprises"
            label="lib"
            track-by="id"
          >
            <template slot="noResult"> {{ $t("NO_DATA_FOUND") }} </template>
          </multiselect>
          <div v-if="erreurlist.entreprise" class="error-message">
            <ul v-if="Array.isArray(erreurlist.entreprise)">
              <span
                v-for="(e, index) in erreurlist.entreprise"
                :key="index"
              >
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.entreprise }}</span>
          </div>
          <div
            v-if="v$.clientToConfig.entreprise.$error"
            class="error-message"
          >
            {{ $t("REQUIRED_FIELD") }}
          </div>
        </b-form-group>
      </div> 
    </form> 
    <div>
      <b-alert variant="warning" show v-if="errorU">
        {{ errorU }}
      </b-alert>
      <div class="double my-4 d-flex justify-content-end">
        <b-button variant="danger" @click="resetModal()" class="mr-2">
          <div class="block-spinner">
            {{ $t("CANCEL") }}
          </div>
        </b-button>
        <b-button
          variant="success"
          @click="clientupdate"
          v-if="
            isSuperAdmin || isentrepriseAdmin || isAssistant || isCommercial
          "
        >
          <div class="block-spinner">
            {{ $t("CONFIRM") }}
            <div v-if="getclientLoading" class="loading ml-2">
              <div class="spinner-border" role="status"></div>
            </div>
          </div>
        </b-button>
      </div>
    </div>
                    </div>
                </div>
    </div>
</div>
</template>
<script>
    import "vue-phone-number-input/dist/vue-phone-number-input.css";
    import { domains } from "@/environment";
    import { mapActions, mapGetters } from "vuex";
    import { useVuelidate } from "@vuelidate/core";
    import {
      required,
      email,
      maxLength,
      minLength,
      requiredIf,
      alphaNum,
      numeric,
    } from "@vuelidate/validators";
    import errorHandle from "../ui/errorHandle.vue";
  import phone from "../ui/phone.vue";
  
    export default {
        components: {
      errorHandle,
      phone,
    },  
    setup() {
      return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
    },
    validations() {
    return {
      clientToConfig: {
        type: { required },
        nom: { required, maxLength: maxLength(100) },
        prenom: { required, maxLength: maxLength(100) },
        rue: { required, maxLength: maxLength(100) },
        cp: {
          required,
          numeric,
          minLength: minLength(4),
          maxLength: maxLength(5),
        },
        ville: { required, maxLength: maxLength(100) },
        phone: {
          phone_number: { required },
        },
        pays: { required },
        entreprise: { requiredIf: requiredIf(this.isSuperAdmin) },
        company: {
          requiredIf: requiredIf(this.isProfessionel),
          maxLength: maxLength(100),
        },
        company_phone: {
          phone_number: { requiredIf: requiredIf(this.isProfessionel) },
        },
        poste: {
          requiredIf: requiredIf(this.isProfessionel),
          maxLength: maxLength(40),
        },
        // revenu_avis_imposition:{
        //   required
        // },
        SWIFT_BIC: {
          minLength: minLength(8),
          maxLength: maxLength(11),
          alphaNum,
        },
        company_email: {
          email,
          requiredIf: requiredIf(this.isProfessionel),
          maxLength: maxLength(50),
        },
        email: { required, email, maxLength: maxLength(50) },
        IBAN: { maxLength: maxLength(34), alphaNum },
        titulaire_compte: { maxLength: maxLength(40) },
      },
    };
  },
  
    data() {
    return {
      statuscheckbox: "Autre adresse",
      downloadUrl: domains.download,
      filetoupdate: {
        lib: null,
        description: null,
        categorie: null,
        client_id: null,
        file: null,
      },
      erreurlist: {
        nom: null,
        prenom: null,
        email: null,
        rue: null,
        cp: null,
        ville: null,
        tel: null,
        type: null,
        entreprise: null,
        pays: null,
        SWIFT_BIC: null,
        // revenu_avis_imposition:null,
        IBAN: null,
        titulaire_compte: null,
        commentaire: null,
        forme_juridique:null,
        ape:null,
        naf:null,
        company: null,
        company_email: null,
        company_phone: null,
        zone: null,
        precarite: null,
        type_chauffage: null,
        type_logement: null,
      },
      optionsprecarite: [
        { value: "Modeste", text: this.$t("Modeste") },
          { value: "Intermédiaires", text: this.$t("Intermédiaires") },
          { value: "tres_modeste", text: this.$t("Tmodeste") },
          { value: "supérieurs", text: this.$t("Supérieurs") },
        ],
      vueTel: {
        preferredCountries: ["FR", "TN"],
        placeholder: "Enter your phone",
        mode: "auto",
        inputOptions: {
          showDialCode: true,
        },
        disabledFormatting: false,
        validCharactersOnly: true,
        wrapperClasses: "country-phone-input",
      },
      document: {
        lib: null,
        description: null,
        categorie: null,
        client_id: null,
        file: null,
      },
      search: "",
      clientToConfig: null,
      clientToConfigRef: null,
      clientToDelete: null,
      clientDetail: {
        type: null,
        email: null,
        tel: null,
        rue: null,
        ville: null,
        revenu_avis_imposition:null,
      },
      clientDetailRef: null,
      page: 1,
      perPage: 10,
   
    optionsZone: [
        { value: "h1", text: "H1" },
        { value: "h2", text: "H2" },
        { value: "h3", text: "H3" },
      ],
      options: [
        { value: "type.particulier", text: this.$t("PARTICULAR") },
        { value: "type.professionnel", text: this.$t("PROFESSIONAL") },
      ],
      current_entreprise: {
        id: null,
      },
      filtre_type: null,
      currenttype: null,
      errorU: null,
      documents: null,
      infoClient: 0,
      loadingInfo: false,
      step: 1,
      resultValid: null,
      phoneValid: null,
    };
  },
  async mounted() {
  const client_id = this.$route.params.id;
  
    const res = await this.client(client_id);
    this.clientToConfig = res.data;
   
  this.pays('fr');
  this.all_countries({ page: this.page, perPage: 100000 });
},
computed: {
    ...mapGetters([
      "getPays",
      "getdatafile",
      "getAllCategorie",
      "getAllclients",
      "getclientLoading",
      "getTotalRowclient",
      "getAllentreprises",
      "getentrepriseLoading",
      "getAllUsers",
      "getTabs",
      "getclient",
      "getOnlineUser",
      "getdevisLoading",
      "getTotalRowdevis",
      "getAlldevis",
      "getFactureLoading",
      "getfactureRows",
      "getAllFacture",
      "getagendaLoading",
      "getAgenda",
      "getdictionnaire",
      "getSelectedLang",
      "getPays",
      "getentreprise",
      "getErreur",
    ]),

    translations() {
      var trans = {
        countrySelectorLabel: "Choisir un pays",
        phoneNumberLabel: "Numéro de téléphone",
      };
      this.getdictionnaire.forEach((element) => {
        if (element.key == "countrySelectorLabel")
          trans.countrySelectorLabel = element.lib;
        if (element.key == "phoneNumberLabel")
          trans.phoneNumberLabel = element.lib;
      });

      return trans;
    },

    query() {
      return this.$route.query.entreprise;
    },

    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },
    isCommercial() {
      return this.getOnlineUser.role == "user.commercial-entreprise";
    },
    isTechnicien() {
      return this.getOnlineUser.role == "user.technicien";
    },
    isAssistant() {
      return this.getOnlineUser.role == "user.assistant";
    },
    isProfessionel() {
    if (this.clientToConfig && this.clientToConfig.type) {
      return this.clientToConfig.type === "type.professionnel";
    }
    return false;
  },
    searchBarText() {
      return `${this.$t("CLIENT_SEARCH")} ( ${this.$t("NAME")}, ${this.$t(
        "SURNAME"
      )}, ${this.$t("EMAIL")} )`;
    },
  },

    methods: {
    ...mapActions([
      "all_countries",
      "all_users",
      "get_data_file",
      "allCategorie",
      "edit_client",
      "all_clients",
      "delete_client",
      "all_entreprises",
      "client",
      "add_to_datafile",
      "delete_from_datafile",
      "update_datafile",
      "devis",
      "factures",
      "all_rendezvous",
      "pays",
      "entreprise",
    ]),

    async clientupdate() {
      const isFormCorrect = await this.v$.clientToConfig.$error;
      if (isFormCorrect) return;
      if (this.resultValid == false) {
        return;
      }

      if (this.clientToConfig.company_phone && this.phoneValid == false) {
        return;
      }
      this.erreurlist = {
        nom: null,
        prenom: null,
        email: null,
        rue: null,
        cp: null,
        ville: null,
        tel: null,
        type: null,
        entreprise: null,
        pays: null,
        SWIFT_BIC: null,
        // revenu_avis_imposition:null,
        IBAN: null,
        titulaire_compte: null,
        commentaire: null,
        forme_juridique:null,
        ape:null,
        naf:null,
        company: null,
        company_email: null,
        company_phone: null,
        zone: null,
        precarite: null,
        type_chauffage: null,
        type_logement: null,
      };

      this.errorU = null;
      const data = {
        id: this.clientToConfig.id,
      };

      // Format numero tel
      var num = this.clientToConfig.phone.phone_number.indexOf(" ");

      data.tel = this.clientToConfig.phone.phone_number
        .slice(num + 1)
        .split(" ")
        .join("");

      data.phone_number = data.tel;
      data.phone_iso_code = this.clientToConfig.phone.phone_iso_code;

      // Format numero tel company
      if (this.clientToConfig.type == "type.professionnel") {
      
        var numE = this.clientToConfig.company_phone.phone_number.indexOf(" ");
        data.company_tel = this.clientToConfig.company_phone.phone_number
          .slice(numE + 1)
          .split(" ")
          .join("");

        data.company_phone_number = data.company_tel;
        data.company_phone_iso_code =
          this.clientToConfig.company_phone.iso_code;
      }

      if (this.clientToConfig.type) {
        data.type = this.clientToConfig.type;
      }
      if (this.clientToConfig.company) {
        data.company = this.clientToConfig.company;
      }
      if (this.clientToConfig.company_email) {
        data.company_email = this.clientToConfig.company_email;
      }
      if (this.clientToConfig.prenom) {
        data.prenom = this.clientToConfig.prenom;
      }
      if (this.clientToConfig.nom) {
        data.nom = this.clientToConfig.nom;
      }
      if (this.clientToConfig.rue) {
        data.rue = this.clientToConfig.rue;
      }
      if (this.clientToConfig.cp) {
        data.cp = this.clientToConfig.cp;
      }
      if (this.clientToConfig.ville) {
        data.ville = this.clientToConfig.ville;
      }
      if (this.clientToConfig.pays) {
        data.pays = this.clientToConfig.pays.id;
      }
      if (this.clientToConfig.zone) {
        data.zone = this.clientToConfig.zone;
      }
      if (this.clientToConfig.precarite) {
        data.precarite = this.clientToConfig.precarite;
      }
      if (this.clientToConfig.type_chauffage) {
        data.type_chauffage = this.clientToConfig.type_chauffage;
      }
      if (this.clientToConfig.type_logement) {
        data.type_logement = this.clientToConfig.type_logement;
      }
      if (this.clientToConfig.entreprise) {
        data.entreprise_id = this.clientToConfig.entreprise.id;
      }
      if (this.clientToConfig.SWIFT_BIC) {
        data.SWIFT_BIC = this.clientToConfig.SWIFT_BIC;
      }
      if (this.clientToConfig.revenu_avis_imposition) {
        data.revenu_avis_imposition = this.clientToConfig.revenu_avis_imposition;
      }
      if (this.clientToConfig.IBAN) {
        data.IBAN = this.clientToConfig.IBAN;
      }
      if (this.clientToConfig.titulaire_compte) {
        data.titulaire_compte = this.clientToConfig.titulaire_compte;
      }
      if (this.clientToConfig.poste) {
        data.poste = this.clientToConfig.poste;
      }
      if (this.clientToConfig.commentaire) {
        data.commentaire = this.clientToConfig.commentaire;
      }
      if (this.clientToConfig.forme_juridique) {
        data.forme_juridique = this.clientToConfig.forme_juridique;
      }
      if (this.clientToConfig.naf) {
        data.naf = this.clientToConfig.naf;
      }
      if (this.clientToConfig.ape) {
        data.ape = this.clientToConfig.ape;
      }
      if (this.clientToConfig.poste) {
        data.poste = this.clientToConfig.poste;
      }

      await this.edit_client({
        refObject: this.clientToConfigRef,
        data: data,
      })
        .then(() => {
          this.setup();
          this.errorU = null;
          this.$router.push("/client");
        //   this.hideModal("ModelConfigclient");
        //   this.hideModal("Modelclient");
        })
        .catch((err) => {
          if (this.isObject(err)) {
            for (const [key, value] of Object.entries(err)) {
              if (!this.erreurlist[key]) {
                this.erreurlist[key] = value;
              }
            }
          } else {
            this.errorU = err;
          }
        });
    },

    resetModal() {
     this.errorS = null;
      this.errorD = null;
      this.errorU = null;

      this.$router.push("/client");
    },

    validateState(name) {
      const { $dirty, $error } = this.v$.clientToConfig[name];
      return $dirty ? !$error : null;
    },
 
    formatNumber(event) {
      this.clientToConfig.phone = {
        phone_number: event.number,
        phone_iso_code: event.country.iso2,
      };
      this.resultValid = event.isValid;
    },

    formatNumberE(event) {
      this.clientToConfig.company_phone = {
        phone_number: event.number,
        iso_code: event.country.iso2,
      };
      this.phoneValid = event.isValid;
      this.erreurlist.company_phone = null;
    },
    isObject(obj) {
      return Object.prototype.toString.call(obj) === "[object Object]";
    },

    selectentreprise(query) {
      this.search = "";
      if (query) {
        this.all_clients({
          page: this.page,
          per_page: this.perPage,
          entreprise_id: query.id,
        });

        this.current_entreprise = query;
      } else {
        this.current_entreprise = {
          lib: null,
        };
        this.all_clients({
          page: this.page,
          per_page: this.perPage,
        });
      }

      this.hideModal("entrepriseModal");
    },

    setup(refresh = false) {
      if (refresh) {
        this.page = 1;
        this.search = null;
      }
      this.currenttype = this.filtre_type;
      if (this.$route.query.entreprise) {
        this.current_entreprise.id = this.$route.query.entreprise;
        this.entreprise({ id: this.$route.query.entreprise });
        this.all_clients({
          page: this.page,
          per_page: this.perPage,
          entreprise_id: this.$route.query.entreprise,
          type: this.currenttype,
        });
      } else {
        if (this.isSuperAdmin) {
          this.all_clients({
            page: this.page,
            per_page: this.perPage,
            entreprise_id: this.current_entreprise.id,
            type: this.currenttype,
          });
        } else {
          this.all_clients({
            page: this.page,
            per_page: this.perPage,
            entreprise_id: this.getOnlineUser.entreprise.id,
            type: this.currenttype,
          });
        }
      }
    },
  },

    }
    </script>