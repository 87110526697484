import domain from "@/environment";
import axios from "axios";
const state = {
    produits: [],
    produit: null,
  produitLoading: false,
};
const getters = {
  getproduitLoading: (state) => state.produitLoading,
};
const mutations = {
    SET_PRODUITS(state, payload) {
        state.produits = payload;
      },
      SET_PRODUIT(state, payload) {
        state.produit = payload;
      },
    
      REMOVE_PRODUIT(state, id) {
        state.produits = state.produits.filter(produit => produit.id !== id);
      },
      SET_PRODUITLOADING(state, payload) {
        if (payload) {
          state.produitLoading = payload;
        } else {
          state.produitLoading = false;
        }
      },
}
const actions = {
    async allGroupProduits({ commit }) {
        commit("SET_PRODUITLOADING", true);
        try {
          const res = await axios.get(domain + `/groupe_ligne_document_produit`);
          commit("SET_PRODUITS", res.data);
          commit("SET_PRODUITLOADING", false);
          return res;
        } catch (error) {
          commit("SET_PRODUITLOADING", false);
          throw error;
        }
      },

      async oneProduitOfGroup({ commit }, id) {
        commit("SET_PRODUITLOADING", true);
        try {
          const res = await axios.get(domain + `/groupe_ligne_document_produit/${id}`);
          commit("SET_PRODUIT", res.data);
          commit("SET_PRODUITLOADING", false);
          return res;
        } catch (error) {
          commit("SET_PRODUITLOADING", false);
          throw error;
        }
      },
    
 
      async createoneProductOfGroup({ commit }, payload) {
        commit("SET_PRODUITLOADING", true);
        try {
          const token_config = {
            headers: {
              Authorization: `Bearer ` + this.state.token,
            },
          };
          const res = await axios.post(
            domain + `/groupe_ligne_document_produit`,
            payload,
            token_config
          );
          commit("SET_PRODUITLOADING", false);
          return res;
        } catch (error) {
          commit("SET_PRODUITLOADING", false);
          throw error;
        }
      },
    
     
      async deleteProduitOfGroup({ commit }, id) {
        commit("SET_PRODUITLOADING", true);
        try {
          const token_config = {
            headers: {
              Authorization: `Bearer ` + this.state.token,
            },
          };
          const res = await axios.delete(
            domain + `/groupe_ligne_document_produit/${id}`,
            token_config
          );
          commit("REMOVE_PRODUIT", id);
          commit("SET_PRODUITLOADING", false);
          return res;
        } catch (error) {
          commit("SET_PRODUITLOADING", false);
          throw error;
        }
      },



    async updateProduct({ commit }, payload) {
        if (payload.id) {
            let produit_id = payload.id.toString();
            payload.produit_id = produit_id.toString();
        }
        if (payload.groupelignedocument_id) {
            let str = payload.groupelignedocument_id.toString();
            payload.groupelignedocument_id = str;
        }
     
        commit("SET_PRODUITLOADING", true);
        const token_config = {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }; 
        const res = await axios
        .put(
          domain + `/groupe_ligne_document_produit/` + payload.id,
          payload,
          token_config
        )
   
       
        return res
      },
}
export default { state, mutations, getters, actions };