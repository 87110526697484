import domain from "@/environment";
import axios from "axios";
const state = {
  devisMono: null,
  devis: [],
  devisObject: {},
  devisRows: 0,
  pdf: null,
  client: null,
  soustraitant: null,
  devisLoading: false,
};
const getters = {
  getdevis: (state) => state.devisMono,
  getAlldevis: (state) => state.devis,
  getTotalRowdevis: (state) => state.devisRows,
  getPDF: (state) => state.pdf,
  getdevisLoading: (state) => state.devisLoading,
  getDevisObject: (state) => state.devisObject,
};
const mutations = {
  SET_CLIENT_DATA(state, clientData) {
    state.client = clientData;
  },
  SET_SOUSTRAITANT_DATA(state, soustraitantData) {
    state.soustraitant = soustraitantData;
  },
  DO_NOTHING() {},
  SET_ONE_DEVIS(state, payload) {
    if (payload) {
      state.devisMono = payload;
    } else {
      state.devisMono = null;
    }
  },
  CLEAR_CLIENT_DATA(state) {
    state.client = null;
  },
  CLEAR_SOUSTRAITANT_DATA(state) {
    state.soustraitant = null;
  },
  SET_DEVIS_OBJECT(state, payload) {
    state.devisObject = { ...state.devisObject, ...payload };
  },
  SET_DEVIS(state, payload) {
    if (payload) {
      state.devis = payload;
    } else {
      state.devis = null;
    }
  },
  PUSH_DEVIS(state, payload) {
    state.devisMono = payload;
  },
  DOWNLOAD_PDF(state, payload) {
    state.pdf = payload;
  },
  DELETE_devis(state, payload) {
    for (let index = 0; index < state.devis.length; index++) {
      if (state.devis[index].devis.id == payload.id) {
        state.devis.splice(index, 1);
        break;
      }
    }
  },
  UPDATE_devis(state, payload) {
    for (let index = 0; index < state.devis.length; index++) {
      if (state.devis[index].id == payload.id) {
        state.devis[index] = payload;
        state.devisMono=payload;
        break;
      }
    }
  },
  UPDATE_devis_group(state, payload) {
    for (let index = 0; index < state.devis.length; index++) {
      if (state.devis.groupeLigneDocument[index].id == payload.id) {
        state.devis.groupeLigneDocument[index] = payload;
        break;
      }
    }
  },
  UPDATE_DEVIS_ROW(state, payload) {
    if (payload) {
      state.devisRows = payload;
    } else {
      state.devisRows = 0;
    }
  },
  SET_DEVISLOADING(state, payload) {
    if (payload) {
      state.devisLoading = payload;
    } else {
      state.devisLoading = false;
    }
  },
};


const actions = {
  async updatesousTraitantData({ commit }, soustraitant) {
    commit("SET_CLIENT_DATA", soustraitant);
  },
  async updateClientData({ commit }, clientData) {
    commit("SET_CLIENT_DATA", clientData);
  },
  
  saveDevis({ commit }, updatedDevisObject) {
    commit("SET_ONE_DEVIS", updatedDevisObject);
  },
  saveDevisObject({ commit }, updatedDevisObject) {
    commit("SET_DEVIS_OBJECT", updatedDevisObject);
  },
  async devis({ commit }, payload) {
    commit("SET_DEVISLOADING", true);
   
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      search: payload.search,
      start_date: payload.start_date,
      end_date: payload.end_date,
      client: payload.client,
      entreprise: payload.entreprise ? payload.entreprise.id : null,
      brouillon: payload.brouillon ? 1 : null,
      type_devis:payload.type_devis?.label,
      total_ttc:null,
      numerodevis:null
    };

    try {
      const responce = await axios.get(domain + `/devis`, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });
      commit("SET_DEVIS", responce.data.data.data);
      commit("UPDATE_DEVIS_ROW", responce.data.data.total);

      commit("SET_DEVISLOADING", false);
  
      return responce.data;
    } catch (error) {
      commit("SET_DEVISLOADING", false);
      throw error.response.data.message;
    }
  },
  async devis_client({ commit }, payload) {
    commit("SET_DEVISLOADING", true);
    try {
      const responce = await axios.get(domain + `/client_devis/` + payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("SET_DEVIS", responce.data.data);
      commit("UPDATE_DEVIS_ROW", responce.data.data.length);
      commit("SET_DEVISLOADING", false);
      return responce.data;
    } catch (error) {
      commit("SET_DEVISLOADING", false);
      throw error.response.data.message;
    }
  },
  async store_devis({ commit }, payload) {
    commit("SET_DEVISLOADING", true);
    try {

      const responce = await axios.post(domain + `/devis`, payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });

      commit("SET_DEVISLOADING", false);
      return responce.data;
    } catch (error) {
      commit("SET_DEVISLOADING", false);
      throw error.response.data.message;
    }
  },
  async pdf({ commit }, payload) {
    commit("SET_DEVISLOADING", true);
    try {
      const responce = await axios.post(
        domain + `/devisPDF/` + payload.pdf + `/` + payload.devis,
        payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );
      commit("DOWNLOAD_PDF", responce.data.data.devis.pdf);
      commit("SET_DEVISLOADING", false);
      return responce.data;
    } catch (error) {
      commit("SET_DEVISLOADING", false);
      throw error.response.data.message;
    }
  },

  async getonedevis({ commit }, payload) {
    commit("SET_DEVISLOADING", true);
    try {
      const responce = await axios.get(domain + `/devis/${payload}`, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("PUSH_DEVIS", responce.data.data);
      commit("SET_DEVISLOADING", false);
      return responce.data;
    } catch (error) {
      commit("SET_DEVISLOADING", false);
      throw error.response.data.message;
    }
  },

  async delete_devis({ commit }, payload) {
    commit("SET_DEVISLOADING", true);
    try {
      await axios.delete(domain + `/delete_devis/` + payload.id, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("DELETE_devis", payload);
      commit("UPDATE_DEVIS_ROW", state.devisRows - 1);
      commit("SET_DEVISLOADING", false);
      return true;
    } catch (error) {
      commit("SET_DEVISLOADING", false);
      throw error.response.data.message;
    }
  },
  async signed_devis({ commit }, payload) {
    commit("SET_DEVISLOADING", true);
    try {
      const responce = await axios.post(
        domain + `/yousign/${payload.doc}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );
      commit("UPDATE_devis", responce.data.data);
      commit("SET_DEVISLOADING", false);
      return responce.data;
    } catch (error) {
      commit("SET_DEVISLOADING", false);
      throw error.response.data.message;
    }
  },

  async duplicateDevis({ commit }, payload) {
    commit("SET_DEVISLOADING", true);
    const params = {
      devis: payload,
    };
    try {
      await axios.post(domain + `/devis/duplicate/` + payload, null, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });
      commit("SET_DEVISLOADING", false);
      return true;
    } catch (error) {
      commit("SET_DEVISLOADING", false);
      throw error.response.data.message;
    }
  },

  async downloadDevis({ commit }, payload) {
    commit("SET_DEVISLOADING", true);
    try {
      const responce = await axios.get(domain + `/devis/download/` + payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("SET_DEVISLOADING", false);
      return responce.data.data;
    } catch (error) {
      commit("SET_DEVISLOADING", false);
      throw error.response.data.message;
    }
  },

  async email_devis({ commit }, payload) {
    commit("SET_DEVISLOADING", true);
    try {
      await axios.get(domain + `/devis/send/` + payload, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("SET_DEVISLOADING", false);
      return true;
    } catch (error) {
      commit("SET_DEVISLOADING", false);
      throw error.response.data.message;
    }
  },

  async updatedevis({ commit }, payload) {
    commit("SET_DEVISLOADING", true);

    try {
  
      const responce = await axios.put(
        domain + `/devis/update/` + payload.id,
        payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );

      commit("UPDATE_devis", responce.data.data);
      commit("SET_DEVISLOADING", false);
      return responce;
    } catch (error) {
      commit("SET_DEVISLOADING", false);
      throw error.response.data.message;
    }
  },
async update_devis_group_BAR({ commit }, payload) {
    commit("SET_DEVISLOADING", true);
    const token_config = {
      headers: {
        Authorization: `Bearer ` + this.state.token,
      },
    }; 
    const res = await axios
    .put(
      domain + `/devis/groups_update/` + payload.result[0].id,
      payload.result[0],
      token_config
    )
   
    return res
  },
  async update_devis_group({ commit }, payload) {
 
    commit("SET_DEVISLOADING", true);
    const token_config = {
      headers: {
        Authorization: `Bearer ` + this.state.token,
      },
    };

    var status = true;
    var error = null;

    for (let i = 0; i < payload.length; i++) {
      if (status)
        await axios
          .put(
            domain + `/devis/groups_update/` + payload[i].id,
            payload[i],
            token_config
          )
          .catch((err) => {
            status = false;
            error = err.response.data.message;
            return;
          });
    }

    if (error) status = error;
    commit("SET_DEVISLOADING", false);
    return status;
  },
  async transform_brouillon({ commit }, payload) {
    commit("SET_DEVISLOADING", true);
    try {
      await axios.put(
        domain + `/devis/transform-brouillon/` + payload.id,
        payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );

      commit("SET_DEVISLOADING", false);
      return true;
    } catch (error) {
      commit("SET_DEVISLOADING", false);
      throw error.response.data.message;
    }
  },
};

export default { state, mutations, getters, actions };
