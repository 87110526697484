import domain from "@/environment";
import Forfait from "../../models/forfait";
import axios from "axios";
//import { mapGetters } from "vuex";
const state = {
  forfait: null,
  forfaits: [],
  forfaitLoading: false,
  forfaitRows: 0,
};
const getters = {
  getforfait: (state) => state.forfait,
  getAllforfaits: (state) => state.forfaits,
  getforfaitLoading: (state) => state.forfaitLoading,
  getTotalRowforfait: (state) => state.forfaitRows,
};
const mutations = {
  DO_NOTHING() {},
  SET_forfait(state, payload) {
    if (payload) {
      state.forfait = Forfait.create(payload);
    } else {
      state.forfait = null;
    }
  },

  SET_forfaitS(state, payload) {
    if (payload) {
      state.forfaits = payload.map((item) => Forfait.create(item));
    } else {
      state.forfaits = [];
    }
  },
  SET_forfaitLOADING(state, payload) {
    if (payload) {
      state.forfaitLoading = payload;
    } else {
      state.forfaitLoading = false;
    }
  },
  PUSH_forfait(state, payload) {
    state.forfaits.push(Forfait.create(payload));
  },
  UPDATE_forfait(state, payload) {
    for (let index = 0; index < state.forfaits.length; index++) {
      if (state.forfaits[index].id == payload.id) {
        state.forfaits[index].update(payload);
        break;
      }
    }
  },
  DELETE_forfait(state, payload) {
    for (let index = 0; index < state.forfaits.length; index++) {
      if (state.forfaits[index].id == payload.id) {
        state.forfaits.splice(index, 1);
        break;
      }
    }
  },
  UPDATE_forfait_ROW(state, payload) {
    if (payload) {
      state.forfaitRows = payload;
    } else {
      state.forfaitRows = 0;
    }
  },
};

const actions = {
  async all_forfaits({ commit }, payload) {
    commit("SET_forfaitS");
    commit("SET_forfaitLOADING", true);
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      entreprise_id: payload.entreprise,
      search: payload.search,
      default: payload.default,
      with_default: payload.with_default,
    };
    try {
      const responce = await axios.get(domain + `/forfait`, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });
      commit("SET_forfaitS", responce.data.data.data);
      commit("UPDATE_forfait_ROW", responce.data.data.total);
      commit("SET_forfaitLOADING", false);
      return responce.data;
    } catch (error) {
      commit("SET_forfaitLOADING", false);
      throw error.response.data.message;
    }
  },
  async store_forfaitjson({ commit }, payload) {
    commit("SET_forfaitLOADING", true);
  
    const params = {
      lib: payload.lib,
      unit_price: payload.unit_price,
      desc: payload.desc,
      favoris: payload.favoris ? "true" : "false",
      quantite: payload.quantite,
      ref: payload.ref,
      tva: payload.tva,
      type_unite: payload.type_unite,
      nom: payload.desc,
      information: payload.information,
      entreprise_id: payload.entreprise_id,
    };
  
    try {
      const response = await axios.post(`${domain}/forfait`, null, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });
  
      commit("PUSH_forfait", response.data.data);
      commit("UPDATE_forfait_ROW", state.forfaitRows + 1);
      commit("SET_forfaitLOADING", false);
      return response.data.data.id;
    } catch (error) {
      commit("SET_forfaitLOADING", false);
  
      console.error("Error occurred:", error);
      const errorResponse = error.response;
      if (errorResponse && errorResponse.data) {
        const structuredError = {
          status: false,
          status_code: errorResponse.status,
          message: errorResponse.data.message || "An error occurred",
        };
  
        console.error("Structured Error:", structuredError);
  
        return structuredError;
      }
  
      return {
        status: false,
        status_code: error.response ? error.response.status : 500,
        message: "An unknown error occurred",
      };
    }
  }
,  


  async store_forfait({ commit }, payload) {
    commit("SET_forfaitLOADING", true);
    const params = {
      lib: payload.lib,
      unit_price: payload.unit_price,
      desc: payload.desc,
      favoris: payload.favoris ? "true" : "false",
      quantite: payload.quantite,
      ref: payload.ref,
      tva: payload.tva,
      type_unite: payload.type_unite,
      nom: payload.nom,
      information: payload.information,
      entreprise_id: payload.entreprise_id
    };

    try {
      const responce = await axios.post(`${domain}/forfait`, null, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
          "Access-Control-Allow-Origin": " *",
        },
        params,
      });

      commit("PUSH_forfait", responce.data.data);
      commit("UPDATE_forfait_ROW", state.forfaitRows + 1);
      commit("SET_forfaitLOADING", false);
      return Forfait.create(responce.data.data);
    } catch (error) {
      commit("SET_forfaitLOADING", false);
      throw error.response.data.message;
    }
  },

  async edit_forfait({ commit }, payload) {
    commit("SET_forfaitLOADING", true);
    var params = {
      lib: payload.lib,
      unit_price: payload.unit_price,
      desc: payload.desc,
      favoris: payload.favoris ? 1 : 0,
      quantite: payload.quantite,
      ref: payload.ref,
      tva: payload.tva,
      type_unite: payload.type_unite,
    };
    try {
      const responce = await axios.put(
        `${domain}/forfait/${payload.id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": " *",
          },
          params,
        }
      );
      commit("UPDATE_forfait", responce.data.data);
      commit("SET_forfaitLOADING", false);
      return responce.data;
    } catch (error) {
      commit("SET_forfaitLOADING", false);
      throw error.response.data.message;
    }
  },
  async delete_forfait({ commit }, payload) {
    commit("SET_forfaitLOADING", true);
    try {
      await axios.delete(domain + `/forfait/` + payload.id, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("DELETE_forfait", payload);
      commit("UPDATE_forfait_ROW", state.forfaitRows - 1);
      commit("SET_forfaitLOADING", false);
      return true;
    } catch (error) {
      commit("SET_forfaitLOADING", false);
      throw error.response.data.message;
    }
  },
};

export default { state, mutations, getters, actions };
