<template>
  <b-modal id="Filtre" ref="Filtre" scrollable>
    <template #modal-header="{ close }">
      <h5>{{ $t("FILTER_PER_DATE") }}</h5>
      <b-button size="sm" @click="close()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17.028"
          height="17.028"
          viewBox="0 0 17.028 17.028"
        >
          <path
            id="Icon_material-close"
            data-name="Icon material-close"
            d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
            transform="translate(-7.5 -7.5)"
            fill="#393939"
          />
        </svg>
      </b-button>
    </template>
    <b-row>
      <b-col md="12" class="mb-4">
        <b-calendar
          v-model="start"
          @context="onContext"
          locale="fr-FR"
          block
        ></b-calendar>
      </b-col>

      <b-col md="12">
        <b-calendar
          v-model="end"
          @context="onContext"
          locale="fr-FR"
          block
        ></b-calendar>
      </b-col>
    </b-row>

    <template #modal-footer>
      <div class="double">
        <b-button variant="danger" @click="resetDate()">
          <div class="block-spinner">{{ $t("CANCEL") }}</div>
        </b-button>

        <b-button variant="success" @click="closeFilter()">
          <div class="block-spinner">
            {{ $t("CONFIRM") }}
          </div>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { useVuelidate } from "@vuelidate/core";


export default {
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  components: {
  },
  data() {
    return {
      current_devis: null,
      current_devis_type: null,

      readActivated: [
        {
          bool: false,
        },
      ],
      showGroup: [
        {
          bool: true,
        },
      ],

      current_brouillon: null,
      filtre_brouillon: null,
      current_entreprise: null,
      filtre_entreprise: null,
      erreur: null,
      start: null,
      end: null,
      context: null,
      search: null,
      devisDetail: null,
      page: 1,
      perPage: 10,
      brouillon: null,
      box: "",
      filtre_client: null,
      filtre_devis: null,
      currentclient: null,
    };
  },

  methods: {
    ...mapActions([
      "all_users",
      "devis",
      "getonedevis",
      "all_entreprises",
      "allDelegataire",
      "all_clients",
    ]),

    onContext(ctx) {
      this.context = ctx;
    },

    fetchSortedData() {
      this.devis({
        page: this.page,
        per_page: this.perPage,
        entreprise: this.getOnlineUser.entreprise,
        brouillon: this.current_brouillon,
        client: this.currentclient ? this.currentclient.id : null,
        type_devis: this.current_devis_type,
      });
    },

    moment: function (date) {
      return moment(new Date(date)).locale(this.getSelectedLang.iso_code);
    },

    hideModal(ref) {
      this.$refs[ref].hide();
    },
    resetDate() {
      this.start = null;
      this.end = null;
      this.devis({
        page: this.page,
        per_page: this.perPage,
        search: this.search,
        brouillon: this.brouillon,
      });

      this.$refs["Filtre"].hide();
    },

    closeFilter() {
      this.devis({
        page: this.page,
        per_page: this.perPage,
        search: this.search,
        start_date: this.start,
        end_date: this.end,
        brouillon: this.brouillon,
      });
      this.$refs["Filtre"].hide();
    },

    updateSearch(s) {
      this.page = 1;
      this.search = s;
    },

    handleDevis(CAT) {
      this.devisDetail = null;
      this.devisDetail = { ...CAT };

      this.devisDetail.devis.fin_devis = moment(
        this.devisDetail.devis.fin_devis
      ).format("YYYY-MM-DD");
      this.creategroup();
      this.allDelegataire({
        entreprise_id: this.devisDetail.entreprise.id,
      });
      if (
        this.isSuperAdmin ||
        this.isentrepriseAdmin ||
        this.isAssistant ||
        this.isCommercial
      )
        this.all_users({
          entreprise_id: this.devisDetail.entreprise.id,
          page: 1,
          per_page: 999,
        });

      if (
        [
          "BAR-EN-101",
          "BAR-EN-102",
          "BAR-EN-104",
          "BAR-EN-103",
          "Type Complexe",
          "BAR-TH-101",
          "BAR-TH-112",
          "BAR-TH-113",
          "BAR-TH-143",
          "BAR-TH-171",
          "BAR-TH-127",
          "BAR-TH-129",
          "BAR-TH-148",
          "BAR-TH-137",
          "BAR-TH-173",
          "Libre",
          "BAR-TH-174",
          "BAR-TH-175",
        ].includes(this.devisDetail.devis.type_devis)
      ) {
        this.$router.push("/devis/view/BAR/" + this.devisDetail.devis.id);
      } else {
        this.$refs["Modeldevis"].show();
      }
    },

    creategroup() {
      for (var i = 0; i < this.devisDetail.groupeLigneDocument.length; i++) {
        this.showGroup.push({
          bool: true,
        });
        this.readActivated.push({
          bool: false,
        });
      }
    },

    close(bv) {
      if (bv.trigger == "backdrop") {
        bv.preventDefault();
        this.box = "";
        this.$bvModal
          .msgBoxConfirm(`${this.$t("CLOSE")}`, {
            title: `${this.$t("CONFIRM")}`,
            size: "sm",
            buttonSize: "sm",
            okVariant: "success",
            cancelVariant: "danger",
            okTitle: `${this.$t("YES")}`,
            cancelTitle: `${this.$t("NO")}`,
            footerClass: "p-2",
            hideHeaderClose: false,
          })
          .then((value) => {
            if (value) {
              this.resetFacture();
            }
          });
      }
    },

    resetFacture() {
      this.fac = {
        type: "facture.fin-travaux",
        total_type: "pourcentage",
        valeur: null,
        restht: null,
        restttc: null,
        restttc_pourcentage: null,
      };
      this.$refs["modalefact"].hide();
    },

    resetDevis() {
      this.devisToConfig = null;
    },

    setup(refresh = false) {
      if (refresh) this.page = 1;
      this.search = null;
      this.resetDevis();
      this.$refs["Modeldevis"].hide();

      this.current_entreprise = this.filtre_entreprise;
      this.current_brouillon = this.filtre_brouillon;
      this.currentclient = this.filtre_client;
      this.current_devis_type = this.filtre_devis;
      this.$refs["entrepriseModal"].hide();

      if (this.isSuperAdmin) {
        this.devis({
          page: this.page,
          per_page: this.perPage,
          entreprise: this.current_entreprise,
          brouillon: this.current_brouillon,
          client: this.currentclient ? this.currentclient.id : null,
          type_devis: this.current_devis_type,
        });
      } else {
        this.devis({
          page: this.page,
          per_page: this.perPage,
          entreprise: this.getOnlineUser.entreprise,
          brouillon: this.current_brouillon,
          client: this.currentclient ? this.currentclient.id : null,
          type_devis: this.current_devis_type,
        });
      }
    },
  },

  mounted() {
    this.setup(true);
    if (this.isSuperAdmin)
      this.all_entreprises({ page: this.page, per_page: this.perPage });

    if (this.$route.query.id) {
      this.getonedevis(this.$route.query.id).then((response) => {
        if (response) this.handleDevis(this.getdevis);
        else alert("Devis Not Found !");
      });
    }
    this.all_clients({ page: 1, per_page: 1000 });
    this.fetchSortedData();
  },

  computed: {
    ...mapGetters(["getOnlineUser", "getdevis", "getSelectedLang"]),
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },
    isAssistant() {
      return this.getOnlineUser.role == "user.assistant";
    },
    isCommercial() {
      return this.getOnlineUser.role == "user.commercial-entreprise";
    },
  },
};
</script>

<style lang="scss" scoped>
.inner-container .page-header button {
  margin-bottom: 0 !important;
  color: #fff;
}

.title {
  font-size: 18px;
  font-weight: 800;
  border: none;
  border-radius: 5px;
  box-shadow: 1px 1px 4px 0px #15223214;
}

.active-sort {
  color: white;
  font-weight: bold;
}

.piece {
  background-color: #28367a;
  color: #fff;
  padding: 0px 15px;
  border-radius: 5px 5px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div:first-child {
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  p {
    margin: 0;
    font-size: 14px;
    margin-right: 8px;
    overflow: hidden;
  }
  .icone-down-up {
    display: flex;
    align-items: center;
    p,
    span {
      @media only screen and (max-width: 1000px) {
        display: none;
      }
    }

    button {
      background-color: transparent !important;
      border: transparent;
      box-shadow: none;
    }

    span {
      font-size: 14px;
      background-color: #6472b3;
      border-radius: 4px;
      padding: 2px 12px;
    }
  }
}

.group_financement {
  width: 100%;
  padding: 8px 12px;
  background-color: #eef1fd;
  border-radius: 5px;
}
.svg-inline--fa {
  margin: inherit;
}
.grey {
  background-color: #f5f6f8;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  margin: 0 auto;
}

form .form-group {
  max-width: none !important;
}

.desclass {
  margin-top: 9px;
}
.cours {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  p {
    margin: 0;
    color: #fff;
    background-color: #d0cccc;
    width: 81px;
    height: 24px;
    font-size: 13px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button {
    font-size: 14px;
  }
}
.content {
  margin-top: 12px;
  .content-header {
    .input-group {
      @media only screen and (max-width: 1000px) {
        width: 59%;
      }
      width: 80%;
      position: relative;
      margin-bottom: 0;
      .icon-search {
        @media only screen and (max-width: 1000px) {
          display: none;
        }
        overflow: visible;
        position: absolute;
        left: 15px;
        top: 10px;
        color: #515151;
      }

      .icon-balance {
        cursor: pointer;
        overflow: visible;
        position: absolute;
        right: 15px;
        top: 10px;
        color: #28367a;
        outline: 0;

        .balance {
          color: #28367a;
        }
      }
      .form-control::placeholder {
        color: #515151;
        font-size: 12px;
      }
    }

    .filtre {
      @media only screen and (max-width: 1000px) {
        width: 39%;
      }
      width: 19%;
      background-color: #28367a;
      color: #fff;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 4px;
      border-radius: 5px;
      font-size: 12px;
    }
  }

  & p {
    font-size: 12px;
    font-weight: 500;
    margin: 8px 15px 0 0;
  }
}

.body {
  padding: 15px;
  box-shadow: 1px 1px 24px #00000019;
  max-height: 60vh;
  overflow: auto;

  ul {
    list-style: none;
    & li {
      padding: 8px 0;
      cursor: pointer;
      background-color: #f8f8f8;
      margin-bottom: 4px;
      color: #000;
      border: none;
      box-shadow: none;
      transition: all 0.2s ease;
      text-align: center;

      &:hover {
        background-color: #28367a;
        color: #fff;
      }
    }
  }
}

.w-100 {
  background-color: #f8f8f8;
  padding: 4px;
  overflow-x: auto;
}

body {
  background: #fafdff;
}

.filtre {
  display: flex;
  align-items: center;
  gap: 6px;

  .productTrie {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    overflow: hidden;
    padding: 4px 8px;
    border-radius: 32px;
    background-color: #34c38f;
    color: #fff;
    margin: 2px 0 !important;

    .icons {
      cursor: pointer;
      width: 18px;
      height: 18px;
      margin-left: 4px;
    }
  }
}

.justify {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.part {
  padding: 8px 0;
  span {
    font-size: 14px;
    font-weight: 500;
    color: #515151;
    word-wrap: break-word;
  }

  h2,
  h3 {
    font-weight: 600;
  }

  h2 {
    font-size: 16px;
  }
  h3 {
    color: #515151;
    font-size: 16px;
    margin: 0;
  }
  .part-title {
    color: #28367a;
    font-weight: 700;
    margin-right: 10px;
  }
}
.group {
  @media only screen and (max-width: 1000px) {
    overflow: hidden;
    padding: 8px;
  }
  box-shadow: 0px 2px 6px #00000019;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
  margin-bottom: 15px;
  .part {
    overflow: auto;
    padding: 15px;
    margin-bottom: 0;
  }
}

.spinner-border {
  width: 1.2rem;
  height: 1.2rem;
}

.input-group {
  margin-bottom: 8px;
  .icon-search {
    position: absolute;
    left: 15px;
    top: 10px;
  }
}

.footer button.modalmodif {
  background-color: #28367a;
  width: 100%;
}
.footerbutton {
  display: flex;
  width: 85%;
  justify-content: space-around;
  margin: auto;
}
.footerbutton button {
  margin-right: 5px;
}
</style>
