<template>
  <div
    class="inner-container"
    :class="{ 'min-container': !getTabs }"
    :hide-footer="
      !isSuperAdmin && !isentrepriseAdmin && !isAssistant && !isCommercial
    "
  >
    <div class="page-header mb-0">
      <h1 class="w-100">
        {{ $t("CLIENT_DETAILS") }}
      </h1>
    </div>

    <div class="content mt-0">
      <div class="corps">
        <header>
          <div class="left">
            <div
              class="circle"
              :class="{ pro: clientDetail.type == 'type.professionnel' }"
              v-if="clientDetail && clientDetail.nom && clientDetail.prenom"
            >
              {{ clientDetail.prenom.slice(0, 1)
              }}{{ clientDetail.nom.slice(0, 1) }}
            </div>
            <div class="info">
              <h2>{{ clientDetail?.prenom }} {{ clientDetail?.nom }}</h2>
              <p>
                <span v-if="clientDetail?.type == 'type.particulier'">{{
                  $t("PARTICULAR")
                }}</span>
                <span v-else>{{ $t("PROFESSIONAL") }}</span>
              </p>
            </div>
          </div>
          <div
            class="right"
            v-if="
              isSuperAdmin || isentrepriseAdmin || isAssistant || isCommercial
            "
          >
            <b-dropdown
              :text="$t('ADD')"
              class="button-pre-style btnColor mb-2"
              variant="primary"
              right
            >
              <b-dropdown-item @click="openModal('add')">
                {{ $t("DOCUMENT") }}
              </b-dropdown-item>
              <b-dropdown-item @click="goToDevis">
                {{ $t("ESTIMATE") }}</b-dropdown-item
              >
              <b-dropdown-item @click="goToFacture">
                {{ $t("INVOICE") }}</b-dropdown-item
              >

              <b-dropdown-item>
                <router-link to="/agenda" class="lang">
                  {{ $t("Activites") }}
                </router-link></b-dropdown-item
              >
            </b-dropdown>
            <documentmodale
              v-if="isModalOpen"
              :isOpen="isModalOpen"
              @close="closeModal"
              @confirm="handleConfirm"
              :document="update_document"
            />
          </div>
        </header>
        <div class="information">
          <div class="info">
            <div class="icone">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.694"
                height="23.092"
                viewBox="0 0 17.694 23.092"
              >
                <path
                  id="Icon_awesome-map-marker-alt"
                  data-name="Icon awesome-map-marker-alt"
                  d="M7.265,21.156C1.137,12.273,0,11.362,0,8.1a8.1,8.1,0,0,1,16.194,0c0,3.265-1.137,4.176-7.265,13.059a1.013,1.013,0,0,1-1.664,0ZM8.1,11.471A3.374,3.374,0,1,0,4.723,8.1,3.374,3.374,0,0,0,8.1,11.471Z"
                  transform="translate(0.75 0.75)"
                  fill="none"
                  stroke="#28367a"
                  stroke-width="1.5"
                />
              </svg>
            </div>
            <p>{{ clientDetail?.rue }}, {{ clientDetail?.ville }}</p>
          </div>
          <div class="hr"></div>
          <div class="info">
            <div class="icone">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="svg"
                style="margin: 8px 0 0"
              >
                <path
                  id="ios-email-outline"
                  d="M4.5,9V22.981H25.283V9Zm10.391,7.641L6.08,9.874H23.7ZM5.366,22.107V10.425L11.6,15.209,7.91,19.431l.109.11,4.27-3.8,2.6,2,2.6-2,4.27,3.8.109-.11L18.188,15.2l6.229-4.778V22.107Z"
                  transform="translate(-4.5 -9)"
                  fill="#28367a"
                />
              </svg>
            </div>
            <p>{{ clientDetail?.email }}</p>
          </div>
          <div class="hr"></div>
          <div class="info">
            <div class="icone">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21.229"
                height="21.411"
                viewBox="0 0 21.229 21.411"
              >
                <path
                  id="ios-call"
                  d="M24.2,20.536a17.051,17.051,0,0,0-3.561-2.4c-1.067-.517-1.458-.506-2.213.042-.629.459-1.035.885-1.759.725a10.5,10.5,0,0,1-3.534-2.638,10.5,10.5,0,0,1-2.615-3.567c-.153-.735.27-1.141.718-1.775.544-.762.56-1.157.042-2.234A16.878,16.878,0,0,0,8.893,5.092c-.777-.784-.951-.613-1.379-.459a7.764,7.764,0,0,0-1.262.677A3.832,3.832,0,0,0,4.736,6.925c-.3.656-.65,1.877,1.126,5.065a28.234,28.234,0,0,0,4.924,6.627l.005.005.005.005A28.13,28.13,0,0,0,17.362,23.6c3.159,1.792,4.369,1.439,5.019,1.136a3.756,3.756,0,0,0,1.6-1.53,8,8,0,0,0,.671-1.274c.153-.432.328-.608-.455-1.391Z"
                  transform="translate(-4.019 -4.039)"
                  fill="none"
                  stroke="#28367a"
                  stroke-width="1"
                />
              </svg>
            </div>
            <p>
              {{
                clientDetail?.phone ? clientDetail.phone.formated_phone : "-"
              }}
            </p>
          </div>
        </div>

        <footer>
          <div class="type">
            <div
              class="type__doc"
              :class="{ active: step == 1 }"
              @click="goDoc"
            >
              {{ $t("DOCUMENT") }}
            </div>
            <div
              v-if="
                isSuperAdmin ||
                isentrepriseAdmin ||
                isAssistant ||
                isTechnicien ||
                isCommercial
              "
              class="type__doc"
              :class="{ active: step == 2 }"
              @click="getDevis"
            >
              {{ $t("ESTIMATE") }}
            </div>
            <div
              v-if="
                isSuperAdmin || isentrepriseAdmin || isAssistant || isCommercial
              "
              class="type__doc"
              :class="{ active: step == 3 }"
              @click="getFacture"
            >
              {{ $t("INVOICE") }}
            </div>
            <div
              class="type__doc"
              :class="{ active: step == 4 }"
              @click="getAct"
            >
              {{ $t("Activites") }}
            </div>
          </div>

          <!-- Document -->
          <div v-if="step == 1">
            <div class="liste" v-if="!loadingInfo && getdatafile.length">
              <div
                class="client_file"
                v-for="(file, i) in getdatafile"
                :key="i"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="29.813"
                  height="33.75"
                  viewBox="0 0 29.813 33.75"
                >
                  <path
                    id="file"
                    d="M23.528,1.125H3.375v33.75H33.188V10.784Zm7.41,10.591v.1H22.5V3.375h.1ZM5.625,32.625V3.375H20.25V14.063H30.938V32.626Z"
                    transform="translate(-3.375 -1.125)"
                    fill="#393939"
                  />
                </svg>

                <div class="hr mx-0"></div>
                <h2>{{ file.lib }}</h2>
                <div class="hr mx-0"></div>
                <p class="description">{{ file.description }}</p>
                <div class="hr mx-0"></div>

                <div
                  class="action"
                  v-if="
                    isSuperAdmin ||
                    isentrepriseAdmin ||
                    isAssistant ||
                    isCommercial
                  "
                >
                  <div @click.prevent.stop="handleUpdate(file)">
                    <font-awesome-icon icon="pencil-alt" />
                  </div>
                  <div @click="download(file)">
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" class="svgtele">
                        <g
                          id="Icon_feather-download"
                          data-name="Icon feather-download"
                          transform="translate(0.75 0.75)"
                        >
                          <path
                            id="Tracé_78100"
                            data-name="Tracé 78100"
                            d="M16.739,22.5v2.72a1.36,1.36,0,0,1-1.36,1.36H5.86A1.36,1.36,0,0,1,4.5,25.22V22.5"
                            transform="translate(-4.5 -14.34)"
                            fill="none"
                            stroke="#fff"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          />
                          <path
                            id="Tracé_78101"
                            data-name="Tracé 78101"
                            d="M10.5,15l3.4,3.4L17.3,15"
                            transform="translate(-7.78 -10.24)"
                            fill="none"
                            stroke="#fff"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          />
                          <path
                            id="Tracé_78102"
                            data-name="Tracé 78102"
                            d="M18,12.66V4.5"
                            transform="translate(-11.88 -4.5)"
                            fill="none"
                            stroke="#fff"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                          />
                        </g>
                      </svg>
                    </div>
                  </div>
                  <div @click="deleteFiche(file)">
                    <font-awesome-icon icon="trash" />
                  </div>
                </div>
              </div>
            </div>
            <div class="liste" v-if="!loadingInfo && !getdatafile.length">
              <p>{{ $t("NO_DATA_FOUND") }}</p>
            </div>
            <div v-if="loadingInfo" class="load_doc">
              <b-spinner label="Spinning"></b-spinner>
            </div>
          </div>

          <!-- Devis -->
          <div v-if="step == 2">
            <div class="liste" v-if="!getdevisLoading && getTotalRowdevis">
              <div
                class="devis_facture"
                v-for="(devis, i) in getAlldevis"
                :key="i"
                @click="chooseDevis(devis)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25.512"
                  height="33.95"
                  viewBox="0 0 25.512 33.95"
                  style="margin-right: 8px"
                >
                  <g id="edition" transform="translate(0.1 0.1)">
                    <path
                      id="Path_2209"
                      data-name="Path 2209"
                      d="M89.312,0H67.428V3.428H64V33.75H85.885V30.322h3.428ZM64.791,32.959V4.219H79.82V9.492h5.273V32.959ZM80.611,4.778,84.534,8.7H80.611Zm7.91,24.753H85.885V8.933L80.38,3.428H68.219V.791h20.3Z"
                      transform="translate(-64)"
                      fill="#393939"
                      stroke="#393939"
                      stroke-width="0.2"
                    />
                    <path
                      id="Path_2210"
                      data-name="Path 2210"
                      d="M118,116h10.547v.791H118Z"
                      transform="translate(-114.44 -108.354)"
                      fill="#393939"
                      stroke="#393939"
                      stroke-width="0.2"
                    />
                    <path
                      id="Path_2211"
                      data-name="Path 2211"
                      d="M118,148h8.437v.791H118Z"
                      transform="translate(-114.44 -138.244)"
                      fill="#393939"
                      stroke="#393939"
                      stroke-width="0.2"
                    />
                    <path
                      id="Path_2212"
                      data-name="Path 2212"
                      d="M206,196h3.428v.791H206Z"
                      transform="translate(-196.639 -183.08)"
                      fill="#393939"
                      stroke="#393939"
                      stroke-width="0.2"
                    />
                    <path
                      id="Path_2213"
                      data-name="Path 2213"
                      d="M206,228h3.428v.791H206Z"
                      transform="translate(-196.639 -212.971)"
                      fill="#393939"
                      stroke="#393939"
                      stroke-width="0.2"
                    />
                    <path
                      id="Path_2214"
                      data-name="Path 2214"
                      d="M118,292h9.228v.791H118Z"
                      transform="translate(-114.44 -272.752)"
                      fill="#393939"
                      stroke="#393939"
                      stroke-width="0.2"
                    />
                    <path
                      id="Path_2215"
                      data-name="Path 2215"
                      d="M118,356h9.228v.791H118Z"
                      transform="translate(-114.44 -332.533)"
                      fill="#393939"
                      stroke="#393939"
                      stroke-width="0.2"
                    />
                    <path
                      id="Path_2216"
                      data-name="Path 2216"
                      d="M206,260h3.428v.791H206Z"
                      transform="translate(-196.639 -242.861)"
                      fill="#393939"
                      stroke="#393939"
                      stroke-width="0.2"
                    />
                    <path
                      id="Path_2217"
                      data-name="Path 2217"
                      d="M118,388h9.228v.791H118Z"
                      transform="translate(-114.44 -362.424)"
                      fill="#393939"
                      stroke="#393939"
                      stroke-width="0.2"
                    />
                    <path
                      id="Path_2218"
                      data-name="Path 2218"
                      d="M118,420h7.383v.791H118Z"
                      transform="translate(-114.44 -392.314)"
                      fill="#393939"
                      stroke="#393939"
                      stroke-width="0.2"
                    />
                    <path
                      id="Path_2219"
                      data-name="Path 2219"
                      d="M118,324h9.228v.791H118Z"
                      transform="translate(-114.44 -302.643)"
                      fill="#393939"
                      stroke="#393939"
                      stroke-width="0.2"
                    />
                    <path
                      id="Path_2220"
                      data-name="Path 2220"
                      d="M275.955,189.582h-.791V188h-2.373v1.582H272v13.689l1.978,3.3,1.978-3.3V191.428h.791v4.878h.791v-5.669h-1.582Zm-2.373-.791h.791v.791h-.791Zm1.582,1.582v1.318h-2.373v-1.318Zm-2.373,12.393V192.482h2.373v10.283Zm.3.791h1.767l-.883,1.472Z"
                      transform="translate(-258.289 -175.607)"
                      fill="#393939"
                      stroke="#393939"
                      stroke-width="0.2"
                    />
                    <path
                      id="Path_2221"
                      data-name="Path 2221"
                      d="M117.01,196H112v5.01h5.01Zm-.791,4.219h-3.428v-3.428h3.428Z"
                      transform="translate(-108.836 -183.08)"
                      fill="#393939"
                      stroke="#393939"
                      stroke-width="0.2"
                    />
                  </g>
                </svg>
                <div class="hr mx-0"></div>
                <h2>{{ devis.client.prenom }} {{ devis.client.nom }}</h2>
                <div class="hr mx-0"></div>
                <p>{{ formatDate(devis.created_at) }}</p>
                <div class="hr mx-0"></div>
                <p v-if="devis.is_signed == 'pending'" class="status">
                  {{ $t("PENDING") }}
                </p>
                <p v-else-if="devis.is_signed == 'stored'" class="stat">
                  {{ $t("VALIDATE") }}
                </p>
                <p
                  v-else-if="devis.brouillon === 0"
                  style="background: #f8f8f8; color: #000"
                  class="stat"
                >
                  {{ $t("SIGNA") }}
                </p>

                <p v-else-if="devis.brouillon === 1" class="none">
                  {{ $t("DRAFT") }}
                </p>
                <font-awesome-icon icon="chevron-right" />
              </div>
            </div>
            <div class="liste" v-if="!getdevisLoading && !getTotalRowdevis">
              <p>{{ $t("NO_DATA_FOUND") }}</p>
            </div>
            <div v-if="getdevisLoading" class="load_doc">
              <b-spinner label="Spinning"></b-spinner>
            </div>
          </div>

          <!-- Facture -->
          <div v-if="step == 3">
            <div class="liste" v-if="!getFactureLoading && getfactureRows">
              <div
                class="devis_facture"
                v-for="(facture, i) in getAllFacture"
                :key="i"
                @click="chooseFacture(facture)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="29.813"
                  height="33.75"
                  viewBox="0 0 29.813 33.75"
                >
                  <path
                    id="file"
                    d="M23.528,1.125H3.375v33.75H33.188V10.784Zm7.41,10.591v.1H22.5V3.375h.1ZM5.625,32.625V3.375H20.25V14.063H30.938V32.626Z"
                    transform="translate(-3.375 -1.125)"
                    fill="#393939"
                  />
                </svg>

                <div class="hr mx-0"></div>
                <h2>{{ facture.client.prenom }} {{ facture.client.nom }}</h2>
                <div class="hr mx-0"></div>
                <p>{{ facture.created_at }}</p>
                <div class="hr mx-0"></div>
                <p v-if="facture.is_signed == 'pending'" class="status">
                  {{ $t("PENDING") }}
                </p>
                <p v-else-if="facture.is_signed == 'stored'" class="stat">
                  {{ $t("VALIDATE") }}
                </p>
                <p v-else class="none">{{ $t("CANCEL") }}</p>
                <font-awesome-icon icon="chevron-right" />
              </div>
            </div>
            <div class="liste" v-if="!getFactureLoading && !getfactureRows">
              <p>{{ $t("NO_DATA_FOUND") }}</p>
            </div>
            <div v-if="getFactureLoading" class="load_doc">
              <b-spinner label="Spinning"></b-spinner>
            </div>
          </div>

          <!-- Activités -->
          <div v-if="step == 4">
            <div class="agenda" v-if="!getagendaLoading && getAgenda.length">
              <h1>{{ $t("PROGRAM") }}</h1>
              <div v-for="(agenda, i) in getAgenda" :key="i">
                <div v-if="!agenda.done" class="event">
                  <h2>{{ agenda.name }}</h2>
                  <p>
                    {{ moment(agenda.start).format("DD-MM-YYYY hh:mm:ss") }}
                  </p>
                </div>
              </div>
              <h1>{{ $t("DONE") }}</h1>
              <div v-for="(agenda, i) in getAgenda" :key="i">
                <div v-if="agenda.done" class="event">
                  <h2>{{ agenda.name }}</h2>
                  <p>
                    {{ moment(agenda.start).format("DD-MM-YYYY hh:mm:ss") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="liste" v-if="!getagendaLoading && !getAgenda.length">
              <p>{{ $t("NO_DATA_FOUND") }}</p>
            </div>
            <div v-if="getagendaLoading" class="load_doc">
              <b-spinner label="Spinning"></b-spinner>
            </div>
          </div>
        </footer>
      </div>
      <div
        class="double d-flex justify-content-end"
        v-if="isSuperAdmin || isentrepriseAdmin || isAssistant || isCommercial"
      >
        <b-button variant="danger" @click.prevent.stop="handleDelete()">
          <div class="block-spinner">
            {{ $t("CANCEL") }}
          </div>
        </b-button>
      </div>
    </div>
    <div></div>
  </div>
</template>
<script>
import moment from "moment";
import { domains } from "@/environment";
import domain from "@/environment";
import axios from "axios";
import documentmodale from "@/components/Ajoutmodel/documentmodale.vue";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  components: {
    documentmodale,
  },
  data() {
    return {
      isModalOpen: false,
      update_document: null,
      downloadUrl: domains.download,
      filetoupdate: {
        lib: null,
        description: null,
        category_file_id: null,
        client_id: null,
        file: null,
        id: null,
      },
      loadingInfo: false,
      step: 1,
      clientDetail: null,
      document: {
        lib: null,
        description: null,
        categorie: null,
        client_id: null,
        file: null,
      },
    };
  },
  computed: {
    ...mapState({
      token: (state) => state.token,
    }),
    ...mapGetters([
      "getFactureLoading",
      "getfactureRows",
      "getdatafile",
      "getTotalRowdevis",
      "getdevisLoading",
      "getAgenda",
      "getagendaLoading",
      "getdevisLoading",
      "getTotalRowdevis",
      "getTabs",
      "getOnlineUser",
      "getclient",
      "getAllCategorie",
      "getAlldevis",
      "getAllFacture",
      "getdatafile",
    ]),
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },
    isAssistant() {
      return this.getOnlineUser.role == "user.assistant";
    },
    isCommercial() {
      return this.getOnlineUser.role == "user.commercial-entreprise";
    },
    isClient() {
      return this.getOnlineUser.role == "user.client";
    },
    onlineUser() {
      return this.getOnlineUser;
    },
  },
  methods: {
    ...mapActions([
      "all_rendezvous",
      "devis",
      "factures",
      "client",
      "delete_from_datafile",
      "allCategorie",
      "add_to_datafile",
      "get_data_file",
      "update_datafile",
    ]),
    formatDate(date) {
      return moment(date, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY");
    },
    handleUpdate(file) {
      this.openModal("update", file);
    },
    async handleConfirm(documentData) {
      
        const res = await this.add_to_datafile(documentData);

        this.get_data_file(this.clientDetail);
        this.filetoupdate = {
          category_file_id: res.data.categorie.id,
          client_id: res.data.client.id,
          file: res.data.file,
          lib: res.data.lib,
          description: res.data.description,
          id: res.data.id,
        };

        this.update_datafile(this.filetoupdate).then(() => {
          this.resetModal();
          this.closeModal();
        });
        this.closeModal();
      
    },

    openModal(action, update_document = null) {
      this.update_document = update_document || {
        lib: "",
        description: "",
        categorie: "",
        file: "",
      }; 

      this.isModalOpen = true;
    },
    handleDelete() {
      this.$router.push("/client");
    },
    closeModal() {
      this.isModalOpen = false;
      this.update_document = null;
    },
    updateFicher(item) {
      this.document = { ...item };
      this.filetoupdate = this.document;
      this.$refs["documentclient"].show(item);
    },
    confirmUPdate() {
      this.filetoupdate.category_file_id = this.filetoupdate.categorie.id;
      this.update_datafile(this.filetoupdate).then(() => {
        this.resetModal();
      });
    },

    deleteFiche(item) {
      this.loadingInfo = true;
      this.delete_from_datafile(item).then(() => {
        this.loadingInfo = false;
      });
    },
    download(item) {
      if (item.file) window.open(this.downloadUrl + item.file, "_blank");
    },
    goDoc() {
      this.step = 1;
      this.loadingInfo = true;
      this.get_data_file(this.clientDetail).then(() => {
        this.infoClient = 1;
        this.loadingInfo = false;
      });
    },

    getDevis() {
      this.step = 2;
      this.devis({
        client: this.clientDetail.id,
      });
    },

    getFacture() {
      this.step = 3;
      this.factures({
        client: this.clientDetail.id,
      });
    },

    getAct() {
      this.step = 4;
      this.all_rendezvous({
        client_id: this.clientDetail.id,
        entreprise_id: this.clientDetail.entreprise.id,
      });
    },

    chooseDevis(devis) {
      this.$router.push("/devis/view/BAR/" + devis.devis.id);
    },

    chooseFacture(facture) {
      this.$router.push(
        "/facture/list?id=" + facture.facture[facture.facture.length - 1].id
      );
    },

    goToDevis() {
      this.$router.push(`/devis/manuel?client=${this.clientDetail.id}`);
    },

    goToFacture() {
      this.$router.push(`/facture/manuelle?client=${this.clientDetail.id}`);
    },
    async fetchClientObject(id) {
      const responce = await axios.get(domain + `/clients/` + id, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      });
      this.clientDetail = responce.data.data;
      this.get_data_file(this.clientDetail);
    },
  },
  mounted() {
    const client_id = this.$route.params.id;
    this.fetchClientObject(client_id);
    this.allCategorie({ page: 1, per_page: 1000 });
  },
};
</script>
<style>
.btnColor {
  color: white;
}
.inner-container .content {
  margin: 10px 15px;
  background-color: #fff;
  box-shadow: 1px 1px 24px #00000019;
  border-radius: 5px;
  padding: 14px;
  min-height: 88vh;
  margin-top: 55px;
}
.country-phone-input .dropdown:focus {
  outline: none;
  color: grey;
}
.titresociete {
  color: #334081;
  width: 100%;
}

.content {
  & p {
    font-size: 12px;
    font-weight: 500;
    padding: 3px 0;
    margin: 0;
  }
}

.w-100 {
  background-color: #f8f8f8;
  padding: 4px;
  overflow-x: auto;
}
body {
  background: #fafdff;
}
.body {
  padding: 4px 0;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
  max-height: 60vh;
  overflow: auto;
}

.body ul {
  list-style: none;
}

.body ul li {
  padding: 8px 0;
  cursor: pointer;
  background-color: #f8f8f8;
  margin-bottom: 4px;
  color: #000;
  border: none;
  box-shadow: none;
  transition: all 0.2s ease;
  text-align: center;
}

.body ul li:hover {
  background-color: #28367a;
  color: #fff;
}

/* .body {
  padding: 4px 0;
  box-shadow: 1px 1px 8px #00000019;
  max-height: 60vh;
  overflow: auto;

  ul {
    list-style: none;
    & li {
      padding: 8px 0;
      cursor: pointer;
      background-color: #f8f8f8;
      margin-bottom: 4px;
      color: #000;
      border: none;
      box-shadow: none;
      transition: all 0.2s ease;
      text-align: center;

      &:hover {
        background-color: #28367a;
        color: #fff;
      }
    }
  }
} */
.filtre {
  display: flex;
  align-items: center;
}

.filtre .table-count,
.filtre .productTrie {
  margin-right: 15px;
}

.filtre .productTrie {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  overflow: hidden;
  padding: 4px 8px;
  border-radius: 32px;
  background-color: #34c38f;
  color: #fff;
}

.filtre .productTrie .icons {
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin-left: 4px;
}

/* .filtre {
  display: flex;
  align-items: center;

  .table-count,
  .productTrie {
    margin-right: 15px;
  }

  .productTrie {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    overflow: hidden;
    padding: 4px 8px;
    border-radius: 32px;
    background-color: #34c38f;
    color: #fff;

    .icons {
      cursor: pointer;
      width: 18px;
      height: 18px;
      margin-left: 4px;
    }
  }
} */
.circle {
  min-width: 52px;
  min-height: 52px;
  border-radius: 50%;
  background-color: #28367a;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 800;
  margin-right: 8px;
  text-transform: uppercase;
}
/* .corps {
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border-bottom: 2px solid #e9ecef;

    .left {
      display: flex;
      align-items: center;

      .pro {
        background-color: #287a6c;
      }

      .info {
        h2,
        p {
          margin: 0;
        }
        h2 {
          font-size: 16px;
          font-weight: 800;
        }
        p {
          font-size: 14px;
          color: #515151;
        }
      }
    }

    .right {
      .action {
        background-color: #28367a;
        border-radius: 4px;
        color: #fff;
        padding: 10px 0;
        font-size: 13px;
        display: flex;
        width: 130px;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        span {
          line-height: 14px;
        }
      }
    }
  }

  hr {
    @media only screen and (max-width: 900px) {
      display: none;
    }
    height: 32px;
    border-left: 2px solid #d8d8d8;
    margin: 12px;
  }

  .hr {
    @media only screen and (max-width: 900px) {
      display: none;
    }
    height: 42px;
    margin-right: 12px;
    border-left: 2px solid #d8d8d8;
  }
  .information {
    @media only screen and (max-width: 900px) {
      flex-wrap: wrap;
    }
    margin: 12px 0;
    padding: 8px 12px;
    background-color: #fff;
    display: flex;
    align-items: center;
    border-radius: 7px;
    box-shadow: 1px 1px 2px 0px #15223214;
    .info {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      width: 33%;

      .icone {
        width: 15%;
        margin-right: 16px;
        font-weight: 400;
        color: #28367a;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      p {
        color: #515151;
        font-size: 14px;
        margin: 0;
        border-radius: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 80%;
      }
    }
  }

  footer {
    @media only screen and (max-width: 900px) {
      padding: 0;
    }
    padding: 24px 12px;
    background-color: #fff;
    border-radius: 7px;
    .type {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .type__doc {
        width: 24.5%;
        padding: 8px 0;
        text-align: center;
        font-size: 12px;
        background-color: #e9ecef;
        color: #000;
        border-radius: 5px 5px 0 0;
        cursor: pointer;
      }

      .active {
        background-color: #f5f6f8;
        font-weight: 600;
        font-size: 14px;
        position: relative;

        &::after {
          @media only screen and (max-width: 900px) {
            left: 25%;
          }
          content: "";
          width: 40px;
          border-top: 2px solid #28367a;
          position: absolute;
          left: 40%;
          bottom: 7px;
        }
      }
    }

    .liste {
      padding: 8px;
      box-shadow: 1px 1px 2px 0px #15223214;
      background-color: #f5f6f8;
      cursor: pointer;
      top: -2px;
      position: relative;

      p {
        margin: 0;
        text-align: center;
      }

      .client_file,
      .devis_facture {
        border-radius: 7px;
        h2,
        p {
          margin: 0;
          display: flex;
          justify-content: center;
          width: 113px;
        }
        h2 {
          @media only screen and (max-width: 900px) {
            font-size: 12px;
          }
          overflow: hidden;
          font-size: 14px;
          color: #000;
          width: 128px;
        }
        p {
          @media only screen and (max-width: 900px) {
            font-size: 10px;
          }
          font-size: 12px;
          color: #515151;
          .status {
            @media only screen and (max-width: 900px) {
              min-width: 64px;
              max-width: 64px;
            }
            background-color: #ffb300;
            color: #fff;
            min-width: 75px;
            max-width: 75px;
            padding: 4px;
            border-radius: 14px;
          }
          .stat {
            @media only screen and (max-width: 900px) {
              min-width: 64px;
              max-width: 64px;
            }
            background-color: #e4261b;
            color: #fff;
            min-width: 75px;
            max-width: 75px;
            padding: 4px;
            border-radius: 14px;
          }
          .none {
            @media only screen and (max-width: 900px) {
              min-width: 64px;
              max-width: 64px;
            }
            background-color: #e4261b;
            color: #fff;
            min-width: 75px;
            max-width: 75px;
            padding: 4px;
            border-radius: 14px;
          }
        }

        .status {
          @media only screen and (max-width: 900px) {
            min-width: 64px;
            max-width: 64px;
          }
          background-color: #ffb300;
          color: #fff;
          min-width: 75px;
          max-width: 75px;
          padding: 4px;
          border-radius: 14px;
        }
        .stat {
          @media only screen and (max-width: 900px) {
            min-width: 64px;
            max-width: 64px;
          }
          background-color: #e4261b;
          color: #fff;
          min-width: 75px;
          max-width: 75px;
          padding: 4px;
          border-radius: 14px;
        }
        .none {
          @media only screen and (max-width: 900px) {
            min-width: 64px;
            max-width: 64px;
          }
          background-color: #e4261b;
          color: #fff;
          min-width: 75px;
          max-width: 75px;
          padding: 4px;
          border-radius: 14px;
        }
        .description {
          width: 30%;
          text-align: left;
        }
      }

      .devis_facture,
      .client_file {
        @media only screen and (max-width: 900px) {
          padding: 8px;
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 16px;
        background-color: #fff;
        color: #28367a;
        margin-bottom: 6px;

        &:hover {
          background-color: #eef1fd;
        }

        .info {
          text-align: center !important;
          h2,
          p {
            margin: 0;
          }
          h2 {
            @media only screen and (max-width: 900px) {
              font-size: 12px;
            }
            font-size: 14px;
            font-weight: 800;
            color: #000;
          }
          p {
            font-size: 12px;
            color: #515151;
          }
        }

        .action {
          @media only screen and (max-width: 900px) {
            width: 25%;
          }
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 15%;
          div {
            @media only screen and (max-width: 900px) {
              width: 20px;
              height: 20px;
            }
            background-color: #28367a;
            border-radius: 4px;
            width: 24px;
            height: 24px;
            font-size: 12px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
      }
    }
    .agenda {
      padding: 8px;
      background-color: #f5f6f8;

      h1 {
        width: 100%;
        padding: 4px 0;
        text-align: center;
        background-color: #28367a;
        border-radius: 7px;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        margin-top: 8px;
      }

      .event {
        padding: 8px 32px;
        background-color: #fff;
        border-radius: 4px;
        margin-bottom: 4px;
        h2,
        p {
          margin: 0;
        }
        h2 {
          font-size: 14px;
          font-weight: 800;
          color: #000;
        }
        p {
          font-size: 12px;
          color: #515151;
        }
      }
    }

    .load_doc {
      width: 100%;
      background-color: #f5f6f8;
      min-height: 75px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
} */
.corps header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-bottom: 2px solid #e9ecef;
}

.corps .left {
  display: flex;
  align-items: center;
}

.corps .left .pro {
  background-color: #287a6c;
}

.corps .left .info h2,
.corps .left .info p {
  margin: 0;
}

.corps .left .info h2 {
  font-size: 16px;
  font-weight: 800;
}

.corps .left .info p {
  font-size: 14px;
  color: #515151;
}

.corps .right .action {
  background-color: #28367a;
  border-radius: 4px;
  color: #fff;
  padding: 10px 0;
  font-size: 13px;
  display: flex;
  width: 130px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.corps .right .action span {
  line-height: 14px;
}

.corps hr {
  @media only screen and (max-width: 900px) {
    display: none;
  }
  height: 32px;
  border-left: 2px solid #d8d8d8;
  margin: 12px;
}

.corps .hr {
  @media only screen and (max-width: 900px) {
    display: none;
  }
  height: 42px;
  margin-right: 12px;
  border-left: 2px solid #d8d8d8;
}

.corps .information {
  @media only screen and (max-width: 900px) {
    flex-wrap: wrap;
  }
  margin: 12px 0;
  padding: 8px 12px;
  background-color: #fff;
  display: flex;
  align-items: center;
  border-radius: 7px;
  box-shadow: 1px 1px 2px 0px #15223214;
}

.corps .information .info {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  width: 33%;
}

.corps .information .info .icone {
  width: 15%;
  margin-right: 16px;
  font-weight: 400;
  color: #28367a;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.corps .information .info p {
  color: #515151;
  font-size: 14px;
  margin: 0;
  border-radius: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
}

.corps footer {
  @media only screen and (max-width: 900px) {
    padding: 0;
  }
  padding: 24px 12px;
  background-color: #fff;
  border-radius: 7px;
}

.corps footer .type {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.corps footer .type .type__doc {
  width: 24.5%;
  padding: 8px 0;
  text-align: center;
  font-size: 12px;
  background-color: #e9ecef;
  color: #000;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
}

.corps footer .type .active {
  background-color: #f5f6f8;
  font-weight: 600;
  font-size: 14px;
  position: relative;
}

.corps footer .type .active::after {
  @media only screen and (max-width: 900px) {
    left: 25%;
  }
  content: "";
  width: 40px;
  border-top: 2px solid #28367a;
  position: absolute;
  left: 40%;
  bottom: 7px;
}

.corps footer .liste {
  padding: 8px;
  box-shadow: 1px 1px 2px 0px #15223214;
  background-color: #f5f6f8;
  cursor: pointer;
  top: -2px;
  position: relative;
}

.corps footer .liste p {
  margin: 0;
  text-align: center;
}

.corps footer .liste .client_file,
.corps footer .liste .devis_facture {
  border-radius: 7px;
}

.corps footer .liste .client_file h2,
.corps footer .liste .devis_facture h2,
.corps footer .liste .client_file p,
.corps footer .liste .devis_facture p {
  margin: 0;
  display: flex;
  justify-content: center;
  width: 113px;
}

.corps footer .liste .client_file h2,
.corps footer .liste .devis_facture h2 {
  @media only screen and (max-width: 900px) {
    font-size: 12px;
  }
  overflow: hidden;
  font-size: 14px;
  color: #000;
  width: 128px;
}

.corps footer .liste .client_file p,
.corps footer .liste .devis_facture p {
  @media only screen and (max-width: 900px) {
    font-size: 10px;
  }
  font-size: 12px;
  color: #515151;
}

.corps footer .liste .client_file .status,
.corps footer .liste .devis_facture .status {
  @media only screen and (max-width: 900px) {
    min-width: 64px;
    max-width: 64px;
  }
  background-color: #ffb300;
  color: #fff;
  min-width: 75px;
  max-width: 75px;
  padding: 4px;
  border-radius: 14px;
}

.corps footer .liste .client_file .stat,
.corps footer .liste .devis_facture .stat {
  @media only screen and (max-width: 900px) {
    min-width: 64px;
    max-width: 64px;
  }
  background-color: #e4261b;
  color: #fff;
  min-width: 75px;
  max-width: 75px;
  padding: 4px;
  border-radius: 14px;
}

.corps footer .liste .client_file .none,
.corps footer .liste .devis_facture .none {
  @media only screen and (max-width: 900px) {
    min-width: 64px;
    max-width: 64px;
  }
  background-color: #e4261b;
  color: #fff;
  min-width: 75px;
  max-width: 75px;
  padding: 4px;
  border-radius: 14px;
}

.corps footer .liste .client_file .description,
.corps footer .liste .devis_facture .description {
  width: 30%;
  text-align: left;
}

.corps footer .liste .devis_facture,
.corps footer .liste .client_file {
  @media only screen and (max-width: 900px) {
    padding: 8px;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  background-color: #fff;
  color: #28367a;
  margin-bottom: 6px;
}

.corps footer .liste .devis_facture:hover,
.corps footer .liste .client_file:hover {
  background-color: #eef1fd;
}

.corps footer .liste .devis_facture .info,
.corps footer .liste .client_file .info {
  text-align: center !important;
}

.corps footer .liste .devis_facture .info h2,
.corps footer .liste .client_file .info h2,
.corps footer .liste .devis_facture .info p,
.corps footer .liste .client_file .info p {
  margin: 0;
}

.corps footer .liste .devis_facture .info h2,
.corps footer .liste .client_file .info h2 {
  @media only screen and (max-width: 900px) {
    font-size: 12px;
  }
  font-size: 14px;
  font-weight: 800;
  color: #000;
}

.corps footer .liste .devis_facture .info p,
.corps footer .liste .client_file .info p {
  font-size: 12px;
  color: #515151;
}

.corps footer .liste .devis_facture .action,
.corps footer .liste .client_file .action {
  @media only screen and (max-width: 900px) {
    width: 25%;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 15%;
}

.corps footer .liste .devis_facture .action div,
.corps footer .liste .client_file .action div {
  @media only screen and (max-width: 900px) {
    font-size: 11px;
  }
  font-size: 12px;
  padding: 6px;
  text-align: center;
  background-color: #f8f9fa;
  border-radius: 5px;
  cursor: pointer;
}
.langues {
  background-color: #fff;
  padding: 8px 0;
}

.langues .lang {
  background-color: #f5f6f8;
  display: block;
  text-decoration: none;
  padding: 10px 24px;
  color: #28367a;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 4px;
}

.langues .lang:hover {
  background-color: #e9ecef;
}

/* .langues {
  background-color: #fff;
  padding: 8px 0;
  .lang {
    background-color: #f5f6f8;
    display: block;
    text-decoration: none;
    padding: 10px 24px;
    color: #28367a;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    margin-bottom: 4px;

    &:hover {
      background-color: #e9ecef;
    }
  }
} */

.svgtele {
  width: 13.5px;
  height: 15px;
}
.svg {
  height: 22px;
  width: 80%;
  margin-left: 3px;
}
.fa-chevron-right {
  display: block;
}

@media only screen and (max-width: 900px) {
  .fa-chevron-right {
    display: none;
  }
}

/* .fa-chevron-right {
  @media only screen and (max-width: 900px) {
    display: none;
  }
} */
.error-message {
  font-size: 16px !important;
}
</style>
